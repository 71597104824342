import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";
import { DatePicker, InfoItemHorizontal, Input } from "components2";
import { rupiahConvert } from "utilities2";
import { DateConvert, RupiahConvert } from "utilities";

export const PostingPettyCashContent = () => {
  const { values } = useFormikContext();

  return (
    <Fragment>
      <Row>
        <Col>
          <InfoItemHorizontal
            label="Tgl. Penerimaan Petty Cash"
            width={190}
            text={
              DateConvert(new Date(values?.tgl_penerimaan_petty_cash)).detail
            }
          />
          <InfoItemHorizontal
            label="No. Penerimaan Petty Cash"
            width={190}
            text={values?.no_penerimaan_petty_cash}
          />
          <InfoItemHorizontal
            label="Nominal Penerimaan Petty Cash"
            width={190}
            text={
              RupiahConvert(
                parseFloat(
                  values?.nominal_penerimaan_petty_cash ?? 0
                ).toString()
              ).getWithComa
            }
          />
          <InfoItemHorizontal
            label="Unit Produksi"
            width={190}
            text={values?.nama_unit_produksi}
          />
        </Col>
        <Col>
          <InfoItemHorizontal
            label="Tgl. Realisasi Petty Cash"
            width={190}
            text={
              DateConvert(new Date(values?.tgl_realisasi_petty_cash)).detail
            }
          />
          <InfoItemHorizontal
            label="No. Realisasi Petty Cash"
            width={190}
            text={values?.no_realisasi_petty_cash}
          />
          <InfoItemHorizontal
            label="Sisa Petty Cash"
            width={190}
            text={
              RupiahConvert(parseFloat(values?.sisa_petty_cash ?? 0).toString())
                .getWithComa
            }
          />
        </Col>
        <Col>
          <InfoItemHorizontal
            label="Pengaju"
            width={190}
            text={values?.nama_karyawan_penerima ?? "-"}
          />
          <InfoItemHorizontal
            label="Jabatan"
            width={190}
            text={values?.nama_jabatan_penerima ?? "-"}
          />
          <InfoItemHorizontal
            label="Unit Organisasi"
            width={190}
            text={values?.nama_unit_organisasi}
          />
        </Col>
      </Row>
    </Fragment>
  );
};
