import { DateConvert } from "utilities";

export const formInitialValues = (data) => ({
  program: {
    idProgram: data?.id_program ?? undefined,
    namaProgram: data?.nama_program ?? "",
    deskripsiProgram: data?.deskripsi_program ?? "",
    tglProgram: data?.tgl_program ?? null,
    idPenanggungJawabProgram: data?.id_penanggung_jawab_program ?? undefined,
    namaPenanggungJawabProgram: data?.nama_penanggung_jawab_program ?? "",
    idUnitOrganisasiProgram: data?.id_unit_organisasi ?? undefined,
    namaUnitOrganisasiProgram: data?.nama_unit_organisasi ?? "",
    periodeMulaiProgram: data?.periode_mulai_program ?? null,
    periodeSelesaiProgram: data?.periode_selesai_program ?? null,
    tglInputProgram: data?.tgl_input ?? DateConvert(new Date()).default,
    noProgram: data?.no_program ?? "",
    lamaPeriodeProgram: data?.lama_periode_program ?? "",
  },
  kegiatan: {
    deskripsiKegiatan: data?.deskripsi_kegiatan ?? "",
    idPenanggungJawabKegiatan: data?.id_penanggung_jawab_kegiatan ?? null,
    namaPenanggungJawabKegiatan: data?.nama_penanggung_jawab_kegiatan ?? "",
    tglKegiatan: data?.tgl_kegiatan ?? null,
    tglInputKegiatan: data?.tgl_input ?? DateConvert(new Date()).default,
    periodeMulaiKegiatan: data?.periode_mulai_kegiatan ?? null,
    periodeSelesaiKegiatan: data?.periode_selesai_kegiatan ?? null,
    idJenisAnggaran: data?.id_jenis_anggaran ?? null,
    namaJenisAnggaran: data?.nama_jenis_anggaran ?? "",
    idKelompokAnggaran: data?.id_kelompok_anggaran ?? null,
    namaKelompokAnggaran: data?.nama_kelompok_anggaran ?? "",
    idSubKelompokAnggaran: data?.id_sub_kelompok_anggaran ?? null,
    namaSubKelompokAnggaran: data?.nama_sub_kelompok_anggaran ?? "",
    namaKegiatan: data?.nama_kegiatan ?? "",
    noKegiatan: data?.no_kegiatan ?? "",
    idKegiatan: data?.id_kegiatan ?? undefined,
    lamaPeriodeKegiatan: data?.lama_periode_kegiatan ?? "",
  },
  PPA: {
    idPPA: data?.id_ppa ?? null,
    tglPPA: data?.tgl_ppa ?? null,
    deskripsiPPA: data?.deskripsi ?? "",
    noPPA: data?.no_ppa ?? "",
  },
  postingRealisasiPPA: {
    tglPostingRealisasiPPA:
      data?.tgl_posting_realisasi_ppa_material ??
      data?.tgl_posting_realisasi_ppa_manusia ??
      data?.tgl_posting_realisasi_ppa_peralatan ??
      data?.tgl_posting_realisasi_ppa_overhead ??
      data?.tgl_posting_realisasi_ppa_lainnya ??
      null,
    noPostingRealisasiPPA:
      data?.no_posting_realisasi_ppa_material ??
      data?.no_posting_realisasi_ppa_manusia ??
      data?.no_posting_realisasi_ppa_peralatan ??
      data?.no_posting_realisasi_ppa_overhead ??
      data?.no_posting_realisasi_ppa_lainnya ??
      null,
    jumlah: data?.jumlah ?? 0,
    coaDebet: data?.coa_debet ?? "",
    coaKredit: data?.coa_kredit ?? "",
    idRealisasiPPA:
      data?.id_realisasi_ppa_material ??
      data?.id_realisasi_ppa_manusia ??
      data?.id_realisasi_ppa_peralatan ??
      data?.id_realisasi_ppa_overhead ??
      data?.id_realisasi_ppa_lainnya ??
      null,
    keteranganJurnal: data?.keterangan_jurnal ?? "",
  },

  realisasiPPA: {
    idRealisasiPPA: data?.id_realisasi_ppa ?? undefined,
    tglRealisasiPPA: data?.tgl_realisasi_ppa ?? null,
    noRealisasiPPA: data?.no_realisasi_ppa ?? "",
  },

  subKegiatan: {
    idSubKegiatan: data?.id_sub_kegiatan ?? undefined,
    tglSubKegiatan: data?.tgl_sub_kegiatan ?? "",
    deskripsiSubKegiatan: data?.deskripsi_sub_kegiatan ?? "",
    noSubKegiatan: data?.no_sub_kegiatan ?? "",
    namaSubKegiatan: data?.nama_sub_kegiatan ?? "",
    tglInputSubKegiatan: data?.tgl_input ?? DateConvert(new Date()).default,
    periodeMulaiSubKegiatan: data?.periode_mulai_sub_kegiatan ?? null,
    periodeSelesaiSubKegiatan: data?.periode_selesai_sub_kegiatan ?? null,
    isAset: data?.is_aset ?? false,
    isWip: data?.is_wip ?? false,
    idPenanggungJawabSubKegiatan:
      data?.id_penanggung_jawab_sub_kegiatan ?? null,
    namaPenanggungJawabSubKegiatan:
      data?.nama_penanggung_jawab_sub_kegiatan ?? "",
    lamaPeriodeSubKegiatan: data?.lama_periode_sub_kegiatan ?? "-",
    detailMaterial: data?.material
      ? data?.material?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_material,
          idItemPPA: val.id_item_ppa_material,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan
            ? val.nama_satuan
            : val.nama_satuan_ppa_material,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_material,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_bahan,
          namaItemSumberDaya: val.nama_item
            ? val.nama_item
            : val.nama_item_ppa_material,
          qtyTerealisasi: val.qty_teralisasi,
          hargaSatuanPPA: val.harga_satuan_ppa_material,
          qtyPPA: val.qty_ppa_material,
          hargaSatuanRealisasi: val.harga_satuan_realisasi ?? 0,
          qtyRealisasi: val.qty_realisasi_ppa ?? 0,
          nominalRealisasi: val.harga_realisasi_ppa ?? 0,
          bukti: val.bukti
            ? val.bukti.map((res) => res.path_bukti_realisasi)
            : [],
          idRealisasiPPAItem: val.id_realisasi_ppa_material ?? null,
          isPosting: val.telah_diposting,
        }))
      : [],
    detailManusia: data?.manusia
      ? data?.manusia?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_manusia,
          idItemPPA: val.id_item_ppa_manusia,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan
            ? val.nama_satuan
            : val.nama_satuan_ppa_manusia,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_manusia,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_upah,
          namaItemSumberDaya: val.nama_item
            ? val.nama_item
            : val.nama_item_ppa_manusia,
          qtyTerealisasi: val.qty_teralisasi,
          hargaSatuanPPA: val.harga_satuan_ppa_manusia,
          qtyPPA: val.qty_ppa_manusia,
          hargaSatuanRealisasi: val.harga_satuan_realisasi ?? 0,
          qtyRealisasi: val.qty_realisasi_ppa ?? 0,
          nominalRealisasi: val.harga_realisasi_ppa ?? 0,
          bukti: [],
          idRealisasiPPAItem: val.id_realisasi_ppa_manusia ?? null,
          isPosting: val.telah_diposting,
        }))
      : [],
    detailPeralatan: data?.peralatan
      ? data?.peralatan?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_peralatan,
          idItemPPA: val.id_item_ppa_peralatan,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan
            ? val.nama_satuan
            : val.nama_satuan_ppa_peralatan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_peralatan,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_aset,
          namaGrup: val.nama_grup_aset,
          namaJenis: val.nama_jenis_aset,
          idItemSumberDaya: val.id_item_alat_mesin,
          namaItemSumberDaya: val.nama_item
            ? val.nama_item
            : val.nama_item_aset_ppa_peralatan,
          qtyTerealisasi: val.qty_teralisasi,
          hargaSatuanPPA: val.harga_satuan_ppa_peralatan,
          qtyPPA: val.qty_ppa_peralatan,
          hargaSatuanRealisasi: val.harga_satuan_realisasi ?? 0,
          qtyRealisasi: val.qty_realisasi_ppa ?? 0,
          nominalRealisasi: val.harga_realisasi_ppa ?? 0,
          bukti: [],
          idRealisasiPPAItem: val.id_realisasi_ppa_peralatan ?? null,
          isPosting: val.telah_diposting,
        }))
      : [],
    detailOverhead: data?.overhead
      ? data?.overhead?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_overhead,
          idItemPPA: val.id_item_ppa_overhead,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan
            ? val.nama_satuan
            : val.nama_satuan_ppa_overhead,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_overhead,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_sumber_daya,
          namaItemSumberDaya: val.nama_item_sumber_daya
            ? val.nama_item_sumber_daya
            : val.nama_item_sumber_daya_ppa_overhead,
          qtyTerealisasi: val.qty_teralisasi,
          hargaSatuanPPA: val.harga_satuan_ppa_overhead,
          qtyPPA: val.qty_ppa_overhead,
          hargaSatuanRealisasi: val.harga_satuan_realisasi ?? 0,
          qtyRealisasi: val.qty_realisasi_ppa ?? 0,
          nominalRealisasi: val.harga_realisasi_ppa ?? 0,
          bukti: [],
          idRealisasiPPAItem: val.id_realisasi_ppa_overhead ?? null,
          isPosting: val.telah_diposting,
        }))
      : [],
    detailLainnya: data?.lainnya
      ? data?.lainnya?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_lainnya,
          idItemPPA: val.id_item_ppa_lainnya,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan
            ? val.nama_satuan
            : val.nama_satuan_ppa_lainnya,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_lainnya,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_sumber_daya,
          namaItemSumberDaya: val.nama_item_sumber_daya
            ? val.nama_item_sumber_daya
            : val.nama_item_sumber_daya_ppa_lainnya,
          qtyTerealisasi: val.qty_teralisasi,
          hargaSatuanPPA: val.harga_satuan_ppa_lainnya,
          qtyPPA: val.qty_ppa_lainnya,
          hargaSatuanRealisasi: val.harga_satuan_realisasi ?? 0,
          qtyRealisasi: val.qty_realisasi_ppa ?? 0,
          nominalRealisasi: val.harga_realisasi_ppa ?? 0,
          bukti: [],
          idRealisasiPPAItem: val.id_realisasi_ppa_lainnya ?? null,
          isPosting: val.telah_diposting,
        }))
      : [],
  },
});
