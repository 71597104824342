import React, { useState, useEffect } from "react";
import { Card, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import {
  ActionButton,
  DataStatus,
  DatePicker,
  InfoItemHorizontal,
  InfoItemVertical,
  Input,
  SelectSearch,
  TextArea,
} from "components";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";
import { InfoPPASection } from "../Section";
import { useFormikContext } from "formik";
import { Formik } from "formik";
import { formInitialValues, formSubmmitMapper } from "../Utils";
import { PostingRealisasiPPAApi } from "api";

export const ModalDetailPostingRealisasiPPA = ({
  modalConfig,
  setModalConfig,
  tabType,
  readOnly,
  dropdownCOA,
}) => {
  const [dataDetail, setDataDetail] = useState({});
  const [isDetailLoading, setIsDetailLoading] = useState(false);

  const getDetailPosting = () => {
    setIsDetailLoading(true);

    PostingRealisasiPPAApi.singlePosting(modalConfig?.tabType, {
      id_posting_realisasi_ppa: modalConfig.id,
    })
      .then(({ data }) => {
        setDataDetail(data?.data);
      })
      .catch(() => {
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsDetailLoading(false);
      });
  };

  useEffect(() => {
    getDetailPosting();

    return () => {
      setIsDetailLoading(false);
    };
  }, []);

  return (
    <Modal
      show={modalConfig?.show}
      onHide={() => setModalConfig({ ...modalConfig, show: false })}
      size="md"
      scrollable
    >
      <Modal.Header closeButton>Detail Realisasi PPA</Modal.Header>
      <Modal.Body>
        {isDetailLoading ? (
          <DataStatus text="Memuat Data..." loading />
        ) : (
          <>
            <InfoItemHorizontal
              label="Tgl. PPA"
              text={DateConvert(new Date(dataDetail.tgl_ppa))?.detail ?? "-"}
            />
            <InfoItemHorizontal
              label="No. PPA"
              text={dataDetail.no_ppa ?? "-"}
            />
            <InfoItemHorizontal
              label="Tgl. Realisasi PPA"
              text={
                DateConvert(new Date(dataDetail.tgl_realisasi_ppa))?.detail ??
                "-"
              }
            />
            <InfoItemHorizontal
              label="No. Realisasi PPA"
              text={dataDetail.no_realisasi_ppa ?? "-"}
            />
            <hr />
            <Row>
              <Col lg="6">
                <InfoItemVertical
                  label="Tgl. Posting Realisasi PPA"
                  text={
                    DateConvert(new Date(dataDetail?.tgl_posting_realsasi_ppa))
                      ?.detail ?? "-"
                  }
                />
              </Col>
              <Col lg="6">
                <InfoItemVertical
                  label="No. Posting Realisasi PPA"
                  text={dataDetail?.no_posting_realisasi_ppa ?? "-"}
                />
              </Col>
              <Col lg="12">
                <InfoItemVertical
                  label="Item Sumber Daya"
                  text={dataDetail.nama_item_sumber_daya ?? "-"}
                />
                <InfoItemVertical
                  label="Jumlah"
                  text={
                    RupiahConvert(
                      parseFloat(
                        dataDetail.jumlah_posting_realisasi_ppa ?? 0
                      ).toString()
                    ).getWithComa
                  }
                />
                <InfoItemVertical
                  label="COA Debet"
                  text={`${dataDetail.coa_debet ?? "-"} - ${
                    dataDetail.nama_akun_debet ?? "-"
                  }`}
                />
                <InfoItemVertical
                  label="COA Kredit"
                  text={`${dataDetail.coa_kredit ?? "-"} - ${
                    dataDetail.nama_akun_kredit ?? "-"
                  }`}
                />
                <InfoItemVertical
                  label="Keterangan"
                  text={dataDetail.keterangan_jurnal ?? ""}
                />
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionButton
          text="Tutup"
          variant="secondary"
          onClick={() => setModalConfig({ ...modalConfig, show: false })}
        />
      </Modal.Footer>
    </Modal>
  );
};
