export const formInitialValues = (data = {}, action = "CREATE") => {
  return Object({
    id_realisasi_petty_cash: data?.id_realisasi_petty_cash,
    tgl_realisasi_petty_cash: data?.tgl_realisasi_petty_cash,
    no_realisasi_petty_cash: data?.no_realisasi_petty_cash,
    detail: data.detail ?? [],

    nama_karyawan_pengaju: data?.nama_karyawan_pengaju,
    nama_jabatan_pengaju: data?.nama_jabatan_pengaju,
    nama_unit_organisasi: data?.nama_unit_organisasi,
    tgl_penerimaan_petty_cash: data?.tgl_penerimaan_petty_cash,
    no_penerimaan_petty_cash: data?.no_penerimaan_petty_cash,
    nominal_penerimaan_petty_cash: data?.nominal_penerimaan_petty_cash,
    nama_proyek: data?.nama_proyek,
    tgl_awal_petty_cash: data?.tgl_awal_petty_cash,
    tgl_akhir_petty_cash: data?.tgl_akhir_petty_cash,
    sisa_masa_berlaku_petty_cash: data?.sisa_masa_berlaku_petty_cash,
    sisa_petty_cash: data?.sisa_petty_cash,
    status_approval: data?.status_approval,
    catatan_revisi: data?.catatan_revisi,
    nama_unit_produksi: data?.nama_unit_produksi,
    tgl_pengajuan_petty_cash: data?.tgl_pengajuan_petty_cash,
    no_pengajuan_petty_cash: data?.no_pengajuan_petty_cash,
    nominal_pengajuan: data?.nominal_pengajuan,
    tgl_mutasi_petty_cash: data?.tgl_mutasi_petty_cash,
    no_bukti_mutasi: data?.no_bukti_mutasi,
    nama_jabatan_penerima: data?.nama_jabatan_penerima,
    nama_karyawan_penerima: data?.nama_karyawan_penerima,
  });
};
