import { InfoItemHorizontal } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { DateConvert } from "utilities";

export const InfoSection = () => {
  const { values } = useFormikContext();

  return (
    <>
      <InfoItemHorizontal
        label="Tgl. Stock Fisik"
        text={
          values?.tglStockFisikMaster &&
          DateConvert(new Date(values?.tglStockFisikMaster)).detail
        }
      />
      <InfoItemHorizontal
        label="No. Stock Fisik"
        text={values?.noStockFisikMaster ?? "-"}
      />
      <InfoItemHorizontal
        label="Unit Produksi"
        text={values?.namaUnitProduksi ?? "-"}
      />
      <InfoItemHorizontal label="Gudang" text={values?.namaGudang ?? "-"} />
      <hr />
      <InfoItemHorizontal
        label="Tgl. Stock Opname"
        text={
          values?.tglStockOpnameMaster &&
          DateConvert(new Date(values?.tglStockOpnameMaster)).detail
        }
      />
      <InfoItemHorizontal
        label="No. Stock Opname"
        text={values?.noStockOpnameMaster ?? "-"}
      />
      <hr />
    </>
  );
};
