import { Table, Th, ThFixed, Td } from "components";
import { RupiahConvert } from "utilities";

const RangkumanBiaya = ({ komponenUtama, komponenTambahan }) => {
  const getTotal = (data) =>
    data?.reduce(
      (total, { qty_produksi, harga_satuan }) =>
        total + parseFloat(qty_produksi ?? 0) * parseFloat(harga_satuan ?? 0),
      0
    );
  const dataSummary = [
    {
      buaso: "Bahan",
      data: [
        { jenis: "Komponen Utama", total: getTotal(komponenUtama?.bahan) },
        { jenis: "Komponen Tambahan", total: getTotal(komponenTambahan.bahan) },
      ],
    },
    {
      buaso: "Upah",
      data: [
        { jenis: "Komponen Utama", total: getTotal(komponenUtama?.upah) },
        { jenis: "Komponen Tambahan", total: getTotal(komponenTambahan.upah) },
      ],
    },
    {
      buaso: "Alat dan Mesin",
      data: [
        { jenis: "Komponen Utama", total: getTotal(komponenUtama?.alat_mesin) },
        {
          jenis: "Komponen Tambahan",
          total: getTotal(komponenTambahan.alat_mesin),
        },
      ],
    },
    {
      buaso: "Subkon",
      data: [
        { jenis: "Komponen Utama", total: getTotal(komponenUtama?.subcont) },
        {
          jenis: "Komponen Tambahan",
          total: getTotal(komponenTambahan.subcont),
        },
      ],
    },
    {
      buaso: "Overhead",
      data: [
        { jenis: "Komponen Utama", total: getTotal(komponenUtama?.overhead) },
        {
          jenis: "Komponen Tambahan",
          total: getTotal(komponenTambahan.overhead),
        },
      ],
    },
  ];
  const getSummaryTotal = () => {
    return dataSummary.reduce((totalSummary, item) => {
      return (
        totalSummary +
        item.data.reduce(
          (totalKomponen, { total }) => totalKomponen + parseFloat(total),
          0
        )
      );
    }, 0);
  };

  return (
    <div className="table-responsive">
      <Table>
        <thead className="text-center bg-light">
          <tr>
            <ThFixed>No.</ThFixed>
            <Th>Grup BDP</Th>
            <Th>Jenis Komponen</Th>
            <Th width={100}> Total </Th>
          </tr>
        </thead>
        <tbody>
          {dataSummary.map((val, index) => {
            const dataKomponen = val.data;
            const rowSpan = dataKomponen.length;
            return (
              <>
                <tr>
                  <Td rowSpan={rowSpan}>{index + 1}</Td>
                  <Td rowSpan={rowSpan}>{val.buaso}</Td>
                  <Td>{dataKomponen[0].jenis}</Td>
                  <Td className="text-right">
                    {
                      RupiahConvert(String(parseFloat(dataKomponen[0].total)))
                        .getWithComa
                    }
                  </Td>
                </tr>
                {dataKomponen?.map(
                  (val, i) =>
                    i !== 0 && (
                      <tr>
                        <Td>{val.jenis}</Td>
                        <Td className="text-right">
                          {
                            RupiahConvert(String(parseFloat(val.total)))
                              .getWithComa
                          }
                        </Td>
                      </tr>
                    )
                )}
              </>
            );
          })}
          <tr>
            <Td colSpan={3} className="font-weight-bold text-right">
              Total
            </Td>
            <Td className="font-weight-bold text-right">
              {RupiahConvert(String(parseFloat(getSummaryTotal()))).getWithComa}
            </Td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default RangkumanBiaya;
