import Services from "services";

class PerimntaanProduksiAsetApi {
  async getList(params) {
    const fetch = await Services.get("/wip_produksi_aset/page", {
      params,
    });
    return fetch.data;
  }

  async getListRealisasiProduksiAvailable(params) {
    const fetch = await Services.get("/wip_produksi_aset/available", {
      params,
    });
    return fetch.data;
  }

  async getSingleRealisasiProduksiAsetAvailable(params) {
    const fetch = await Services.get("/wip_produksi_aset/available_detail", {
      params,
    });
    return fetch.data;
  }

  async getSingleProduksiAset(params) {
    const fetch = await Services.get("/wip_produksi_aset/single", {
      params,
    });
    return fetch.data;
  }

  async getDropdownCOA(params) {
    const fetch = await Services.get("/dropdown/coa", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: `${val.nomor_akun} | ${val.nama_akun}`,
      value: val.nomor_akun,
    }));
  }

  async getDropdownItem(params) {
    const fetch = await Services.get("/dropdown/item_produksi_aset", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item,
      value: val.id_item,
      nama_satuan: val.nama_satuan,
      harga_satuan: val.harga_satuan,
    }));
  }

  async getSalinKomponen(params) {
    const fetch = await Services.get("/wip_produksi_aset/salin_componen", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_aset,
      value: val.id_produksi_aset,
      no_produksi_aset: val.no_produksi_aset,
      tgl_produksi_aset: val.tgl_produksi_aset,
    }));
  }

  async getDropdownItem(params) {
    const fetch = await Services.get("/dropdown/item_produksi_aset", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item,
      value: val.id_item,
    }));
  }

  generateNumber(params) {
    return Services.get("/wip_produksi_aset/no_baru", { params });
  }

  create(data, tab) {
    return Services.post(`/wip_produksi_aset/${tab}`, data);
  }

  update(data) {
    return Services.put("/wip_produksi_aset", data);
  }

  export(params, type) {
    return Services.get(`/wip_produksi_aset/export_${type}`, { params });
  }
}

export default new PerimntaanProduksiAsetApi();
