import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  InfoItemHorizontal,
  InfoItemVertical,
  Input,
  SelectSearch,
  TextArea,
  DatePicker,
  ActionButton,
} from "components";
import { DateConvert, RupiahConvert } from "utilities";
import { Alert } from "react-bootstrap";
import { HPPApi } from "api";

const InfoSection = ({ data }) => {
  return (
    <>
      <InfoItemHorizontal
        label="Tgl. Produksi"
        text={
          data.tgl_jobmix ? DateConvert(new Date(data.tgl_jobmix)).detail : "-"
        }
      />
      <InfoItemHorizontal label="No. Produksi" text={data.no_jobmix ?? "-"} />
      <InfoItemHorizontal
        label="Tgl. Realisasi Produksi"
        text={
          data.tgl_realisasi_produksi
            ? DateConvert(new Date(data.tgl_realisasi_produksi)).detail
            : "-"
        }
      />
      <InfoItemHorizontal
        label="No. Realisasi Produksi"
        text={data.no_realisasi_produksi ?? "-"}
      />
      <InfoItemHorizontal label="Item Produksi" text={data.item_produksi} />
      <InfoItemHorizontal
        label="Qty. Produksi"
        text={
          data.qty_jobmix
            ? `${parseFloat(data.qty_jobmix)} ${data.kode_satuan ?? ""}`
            : "-"
        }
      />
      <InfoItemHorizontal
        label="Qty. Realisasi Produksi"
        text={
          data.qty_realisasi_produksi
            ? `${parseFloat(data.qty_realisasi_produksi)} ${
                data.kode_satuan ?? ""
              }`
            : "-"
        }
      />
      <InfoItemHorizontal
        label="Tgl. Sales Order"
        text={
          data.tgl_sales_order
            ? DateConvert(new Date(data.tgl_sales_order)).detail
            : "-"
        }
      />
      <InfoItemHorizontal label="No. Sales Order" text={data.no_sales_order} />
      <InfoItemHorizontal label="Customer" text={data.nama_customer ?? "-"} />
      <InfoItemHorizontal
        label="Unit Produksi"
        text={data.nama_unit_produksi ?? "-"}
      />
      <hr />
    </>
  );
};

const BodyConfirmation = ({ formik }) => {
  const { values } = formik;
  return (
    <>
      <Row>
        <Col>
          <InfoItemVertical
            label="Tgl. HPP"
            text={
              values?.tgl_hpp
                ? DateConvert(new Date(values.tgl_hpp)).detail
                : "-"
            }
          />
        </Col>
        <Col>
          <InfoItemVertical label="No. HPP" text={values.no_hpp} />
        </Col>
      </Row>
      <InfoItemVertical
        label="Jumlah"
        text={RupiahConvert(String(parseFloat(values.jumlah))).getWithComa}
      />
      <InfoItemVertical label="Coa Debet" text={values.label_coa_debet} />
      <InfoItemVertical label="Coa Kredit" text={values.label_coa_kredit} />
      <InfoItemVertical
        label="Keterangan Jurnal"
        text={values.keterangan_jurnal}
      />
      <Alert variant="danger">
        <small className="font-weight-bold">
          Data HPP yang telah diposting ke SIA, tidak dapat diedit lagi!
        </small>
      </Alert>
    </>
  );
};

const BodyForm = ({ formik, dropdown }) => {
  const TODAY = new Date();

  const { values, setValues, handleChange, errors, touched } = formik;

  const generateNo = (date = TODAY) => {
    HPPApi.no_baru({ tanggal: date })
      .then((res) =>
        setValues({
          ...values,
          tgl_hpp: date,
          no_hpp: res.data.data,
        })
      )
      .catch(({ response }) =>
        window.alert(response.data.message ?? "Tidak dapat memuat nomor baru!")
      );
  };

  useEffect(() => {
    // generateNo();

    return () => {};
  }, []);

  return (
    <>
      <Row>
        <Col md="6">
          <DatePicker
            label="Tgl. HPP"
            placeholderText="Pilih tgl. HPP"
            selected={values?.tgl_hpp ? new Date(values?.tgl_hpp) : undefined}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              const parseDate = DateConvert(new Date(date)).default;
              generateNo(parseDate);
            }}
            error={!!(errors?.tgl_hpp && touched?.tgl_hpp)}
            errorText={
              !!(errors?.tgl_hpp && touched?.tgl_hpp) && errors?.tgl_hpp
            }
          />
        </Col>

        <Col md="6">
          <Input
            label="No. HPP"
            type="text"
            name="no_hpp"
            value={values.no_hpp}
            readOnly={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Jumlah"
            type="text"
            name="jumlah"
            className="text-right"
            value={
              RupiahConvert(String(parseFloat(values.jumlah ?? 0))).getWithComa
            }
            readOnly={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectSearch
            label="COA Debet*"
            placeholder="Pilih COA debet"
            // loading={loadingCOA}
            defaultValue={dropdown.coa?.find(
              (val) => values.id_coa_debet === val.value
            )}
            onChange={(val) =>
              setValues({
                ...values,
                id_coa_debet: val.value,
                label_coa_debet: val.label,
              })
            }
            option={dropdown.coa}
            error={!!(errors.id_coa_debet && touched.id_coa_debet)}
            errorText={
              !!(errors.id_coa_debet && touched.id_coa_debet) &&
              errors.id_coa_debet
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectSearch
            label="COA Kredit*"
            placeholder="Pilih COA Kredit"
            // loading={loadingCOA}
            defaultValue={dropdown.coa?.find(
              (val) => values.id_coa_kredit === val.value
            )}
            onChange={(val) =>
              setValues({
                ...values,
                id_coa_kredit: val.value,
                label_coa_kredit: val.label,
              })
            }
            option={dropdown.coa}
            error={!!(errors.id_coa_kredit && touched.id_coa_kredit)}
            errorText={
              !!(errors.id_coa_kredit && touched.id_coa_kredit) &&
              errors.id_coa_kredit
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea
            label="Keterangan Jurnal*"
            name="keterangan_jurnal"
            placeholder="Masukan keterangan jurnal"
            value={values.keterangan_jurnal}
            onChange={handleChange}
            error={!!(errors.keterangan_jurnal && touched.keterangan_jurnal)}
            errorText={
              !!(errors.keterangan_jurnal && touched.keterangan_jurnal) &&
              errors.keterangan_jurnal
            }
          />
        </Col>
      </Row>
    </>
  );
};

// MAIN
const ModalPostingHPP = ({ modalConfig, setModalConfig, grandTotalBDP }) => {
  const history = useHistory();
  const [confirmBody, setConfirmBody] = useState({
    show: false,
    isConfirm: false,
  });
  const [dropdown, setDropdown] = useState({ coa: [] });

  const initialValues = {
    tgl_hpp: "",
    no_hpp: "",
    id_jobmix: modalConfig?.data?.id_jobmix,
    jumlah: grandTotalBDP?.totalBDP,
    id_realisasi_produksi: modalConfig?.data?.id_realisasi_produksi,
    no_realisasi_produksi: modalConfig?.data?.no_realisasi_produksi,
    id_coa_debet: "",
    id_coa_kredit: "",
    label_coa_debet: "",
    label_coa_kredit: "",
    keterangan_jurnal: "",
  };

  const validationSchema = Yup.object().shape({
    tgl_hpp: Yup.string().required("Tgl. HPP tidak boleh kosong"),
    no_hpp: Yup.string().required("No. HPP tidak boleh kosong"),
    id_coa_debet: Yup.string().required("COA debet tidak boleh kosong"),
    id_coa_kredit: Yup.string().required("COA kredit tidak boleh kosong"),
    keterangan_jurnal: Yup.string().required(
      "Keterangan jurnal tidak boleh kosong"
    ),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    HPPApi.create(values)
      .then((res) => {
        history.push("/transaksi/hpp/list-produksi", {
          alert: {
            show: true,
            text: "Data berhasil diposting!",
            variant: "primary",
          },
        });
      })
      .catch((response) => {});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit,
  });

  const { errors, handleSubmit, isSubmitting, setTouched } = formik;

  useEffect(() => {
    HPPApi.dropdown({ tipe: "coa" }).then((res) =>
      setDropdown({
        coa: res.data.data.map((item) => ({
          ...item,
          value: item.the_real_id_coa,
          label: `${item.nomor_akun} - ${item.nama_akun}`,
        })),
      })
    );

    return () => {};
  }, []);

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ show: false, data: null })}
    >
      <Modal.Header closeButton>
        <b>{confirmBody.show ? "Konfirmasi" : "Posting HPP"}</b>
      </Modal.Header>

      <Modal.Body>
        <InfoSection data={modalConfig.data} />
        {confirmBody.show ? (
          <BodyConfirmation formik={formik} />
        ) : (
          <BodyForm formik={formik} dropdown={dropdown} />
        )}
      </Modal.Body>

      <Modal.Footer>
        <ActionButton
          variant="outline-secondary"
          text="Batal"
          onClick={
            confirmBody.show
              ? () => setConfirmBody({ show: false })
              : () => setModalConfig({ show: false, data: null })
          }
        />
        <ActionButton
          text="Posting HPP"
          type="button"
          onClick={
            confirmBody.show
              ? () => handleSubmit()
              : () => {
                  // show error field if exist
                  setTouched({
                    id_coa_debet: true,
                    id_coa_kredit: true,
                    keterangan_jurnal: true,
                  });

                  //if no errors field, show confirmation body
                  if (Object.keys(errors).length === 0) {
                    setConfirmBody({ show: true });
                  }
                }
          }
          loading={confirmBody.isConfirm && isSubmitting}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPostingHPP;
