import { useContext } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import {
  DatePicker,
  InfoItemVertical,
  Input,
  SelectSearch,
  TextArea,
  ActionButton,
  InputCurrency,
} from 'components';
import { DateConvert, RupiahConvert } from 'utilities';
import { ModalHeadSection } from './ModalHeadSection';
import {
  DropdownContext,
  PostingRealisasiContext,
  withDropdownProvider,
} from '../context';
import { getTotal } from '../utils';
import { PostingRealisasiWorkOrderApi } from 'api';

export const ModalFormBahanAlatOverhead = withDropdownProvider(
  ({ buaso, show, isCreate, onHide, onSubmitSuccess, onSubmitFailed }) => {
    const { modalFormPosting } = useContext(PostingRealisasiContext);
    const { data } = modalFormPosting;

    const initialValues = {
      ...data,
      [`tgl_posting_realisasi_work_order_${buaso}`]:
        data?.[`tgl_posting_realisasi_work_order_${buaso}`] ?? '',
      [`no_posting_realisasi_work_order_${buaso}`]:
        data?.[`no_posting_realisasi_work_order_${buaso}`] ?? '',
      [`harga_satuan_${buaso}`]:
        data?.harga_satuan ?? data?.[`harga_satuan_${buaso}`] ?? '0',
      nomor_akun_debet: data?.nomor_akun_debet ?? '',
      nomor_akun_kredit: data?.nomor_akun_kredit ?? '',
      keterangan_jurnal: data?.keterangan_jurnal ?? '',
    };

    const validationSchema = yup.object().shape({
      [`tgl_posting_realisasi_work_order_${buaso}`]: yup
        .string()
        .required('Tanggal harus diisi'),
      nomor_akun_debet: yup.string().required('Nomor Akun Debet harus diisi'),
      nomor_akun_kredit: yup.string().required('Nomor Akun Kredit harus diisi'),
      keterangan_jurnal: yup.string().required('Keterangan Jurnal harus diisi'),
    });

    const onSubmit = (values) => {
      const finalData = {
        [buaso]: values,
      };

      PostingRealisasiWorkOrderApi.save(finalData)
        .then(() => {
          onHide();
          onSubmitSuccess();
        })
        .catch(() => onSubmitFailed());
    };

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
              {isCreate ? 'Tambah ' : 'Detail '} Data Posting Realisasi Work
              Order
            </Modal.Header>

            <Modal.Body>
              <ModalHeadSection buaso={buaso} data={values} />

              {isCreate ? (
                <FormBody buaso={buaso} />
              ) : (
                <DetailBody buaso={buaso} />
              )}
            </Modal.Body>

            <Modal.Footer>
              <Row>
                <ActionButton variant="light" onClick={onHide}>
                  Kembali
                </ActionButton>

                {isCreate && (
                  <ActionButton
                    className="ml-2"
                    onClick={handleSubmit}
                    loading={isSubmitting}
                  >
                    Simpan
                  </ActionButton>
                )}
              </Row>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    );
  },
);

export const FormBody = ({ buaso }) => {
  const { dropdownCOA } = useContext(DropdownContext);
  const { values, setFieldValue, errors, touched } = useFormikContext();

  const getNomorHandler = (tanggal) => {
    PostingRealisasiWorkOrderApi.getNoBaru({
      tanggal,
      buaso,
    })
      .then((res) => {
        setFieldValue(
          `no_posting_realisasi_work_order_${buaso}`,
          res.data.data,
        );
      })
      .catch(() => {
        window.alert('Gagal memuat nomor baru!');
      });
  };

  return (
    <>
      <Row>
        <Col md={6}>
          <DatePicker
            label="Tgl. Posting Realisasi Work Order"
            selected={
              values?.[`tgl_posting_realisasi_work_order_${buaso}`] &&
              new Date(values?.[`tgl_posting_realisasi_work_order_${buaso}`])
            }
            onChange={(date) => {
              const parseDate = DateConvert(date).default;

              setFieldValue(
                `tgl_posting_realisasi_work_order_${buaso}`,
                parseDate,
              );
              getNomorHandler(parseDate);
            }}
            error={Boolean(
              errors?.[`tgl_posting_realisasi_work_order_${buaso}`] &&
                touched?.[`tgl_posting_realisasi_work_order_${buaso}`],
            )}
            errorText={errors?.[`tgl_posting_realisasi_work_order_${buaso}`]}
          />
        </Col>

        <Col md={6}>
          <Input
            readOnly
            label="No. Posting Realisasi Work Order"
            placeholder={
              !values[`no_posting_realisasi_work_order_${buaso}`] &&
              'Pilih Tangga Terlebih Dahulu'
            }
            value={values[`no_posting_realisasi_work_order_${buaso}`]}
          />
        </Col>
      </Row>

      <InputCurrency
        readOnly
        label="Jumlah"
        className="text-right"
        value={getTotal({
          qty: values?.[`qty_realisasi_${buaso}`],
          harga: values?.harga_satuan ?? values[`harga_satuan_${buaso}`],
        })}
      />

      <SelectSearch
        label="COA Debet"
        placeholder="Pilih COA Debet"
        option={dropdownCOA?.data ?? []}
        onChange={(e) => {
          setFieldValue(`nomor_akun_debet`, e.value);
        }}
        error={errors.nomor_akun_debet && touched.nomor_akun_debet}
        errorText={errors.nomor_akun_debet}
      />

      <SelectSearch
        label="COA Kredit"
        placeholder="Pilih COA Kredit"
        option={dropdownCOA?.data ?? []}
        onChange={(e) => {
          setFieldValue(`nomor_akun_kredit`, e.value);
        }}
        error={errors.nomor_akun_kredit && touched.nomor_akun_kredit}
        errorText={errors.nomor_akun_kredit}
      />

      <TextArea
        label="Keterangan"
        onChange={(e) => {
          setFieldValue(`keterangan_jurnal`, e.target.value);
        }}
        error={errors.keterangan_jurnal && touched.keterangan_jurnal}
        errorText={errors.keterangan_jurnal}
      />
    </>
  );
};

export const DetailBody = ({ buaso }) => {
  const { values } = useFormikContext();

  return (
    <>
      <Row>
        <Col md={6}>
          <InfoItemVertical
            label="Tgl. Posting Realisasi Work Order"
            text={
              values?.[`tgl_posting_realisasi_work_order_${buaso}`]
                ? DateConvert(
                    new Date(
                      values?.[`tgl_posting_realisasi_work_order_${buaso}`],
                    ),
                  ).detail
                : '-'
            }
          />
        </Col>
        <Col md={6}>
          <InfoItemVertical
            label="No. Posting Realisasi Work Order"
            text={values?.[`no_posting_realisasi_work_order_${buaso}`] ?? '-'}
          />
        </Col>
      </Row>

      <InfoItemVertical
        label="Jumlah"
        text={RupiahConvert(String(parseFloat(values.jumlah))).getWithComa}
      />

      <InfoItemVertical
        label="COA Debet"
        text={`${values.nomor_akun_debet} - ${values.nama_akun_debet}`}
      />

      <InfoItemVertical
        label="COA Kredit"
        text={`${values.nomor_akun_kredit} - ${values.nama_akun_kredit}`}
      />

      <InfoItemVertical
        label="Keterangan"
        text={values.keterangan_jurnal ?? ''}
      />
    </>
  );
};
