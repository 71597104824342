import React, { useState, useEffect } from "react";
import { Card, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import {
  ActionButton,
  DatePicker,
  InfoItemHorizontal,
  Input,
  SelectSearch,
  TextArea,
} from "components";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";
import { InfoPPASection } from "../Section";
import { useFormikContext } from "formik";
import { Formik } from "formik";
import { formInitialValues, formSubmmitMapper } from "../Utils";
import { PostingRealisasiPPAApi } from "api";

export const ModalPostingRealisasiPPA = ({
  modalConfig,
  setModalConfig,
  tabType,
  readOnly,
  dropdownCOA,
  alertConfig,
  setAlertConfig,
}) => {
  const { values, setValues, setSubmitting, isSubmitting } = useFormikContext();

  const [isNomorLoading, setIsNomorLoading] = useState(false);

  const getNomorHandler = (tgl) => {
    setIsNomorLoading(true);
    const tanggal = DateConvert(new Date(tgl)).default;

    PostingRealisasiPPAApi.getKode(tabType, { tanggal })
      .then(({ data }) => {
        const no = data?.data;
        setValues({
          ...values,
          postingRealisasiPPA: {
            ...values?.postingRealisasiPPA,
            noPostingRealisasiPPA: no,
            tglPostingRealisasiPPA: tanggal,
          },
        });
      })
      .catch(() => {
        setValues({
          ...values,
          postingRealisasiPPA: {
            ...values?.postingRealisasiPPA,
            noPostingRealisasiPPA: "",
            tglPostingRealisasiPPA: null,
          },
        });
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  const formSubmitHandler = () => {
    PostingRealisasiPPAApi.create(tabType, formSubmmitMapper(tabType, values))
      .then(() => {
        setAlertConfig({
          show: true,
          text: "Data berhasil disimpan !!",
          variant: "primary",
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          text: "Data gagal disimpan !!",
          variant: "danger",
        });
      })
      .finally(() => {
        setSubmitting(false);
        setModalConfig({ ...modalConfig, show: false });
      });
  };

  return (
    <Modal
      show={modalConfig?.show}
      onHide={() => setModalConfig({ ...modalConfig, show: false })}
      size="md"
      scrollable
    >
      <Modal.Header closeButton>Posting Realisasi PPA</Modal.Header>
      <Modal.Body>
        <InfoItemHorizontal
          label="Tgl. PPA"
          text={DateConvert(new Date(values.PPA.tglPPA))?.detail ?? "-"}
        />
        <InfoItemHorizontal label="No. PPA" text={values.PPA.noPPA ?? "-"} />

        <hr />
        <InfoItemHorizontal
          label="Tgl. Realisasi PPA"
          text={
            DateConvert(new Date(values.realisasiPPA.tglRealisasiPPA))
              ?.detail ?? "-"
          }
        />
        <InfoItemHorizontal
          label="No. Realisasi PPA"
          text={values.realisasiPPA.noRealisasiPPA ?? "-"}
        />

        <hr />

        <InfoItemHorizontal
          label="Item Sumber Daya"
          text={modalConfig?.data?.namaItemSumberDaya ?? "-"}
        />
        <InfoItemHorizontal
          label="Qty"
          text={
            DecimalConvert(parseFloat(modalConfig?.data?.qtyPPA ?? 0))
              .getWithComa
          }
        />
        <InfoItemHorizontal
          label="Satuan"
          text={modalConfig?.data?.namaSatuan ?? "-"}
        />
        <hr />

        <Row>
          <Col lg="6">
            <DatePicker
              label="Tgl. Realisasi PPA"
              onChange={(date) => {
                getNomorHandler(date);
              }}
              selected={
                values?.postingRealisasiPPA?.tglPostingRealisasiPPA
                  ? new Date(
                      values?.postingRealisasiPPA?.tglPostingRealisasiPPA
                    )
                  : null
              }
              //   error={
              //     Boolean(errors?.realisasiPPA?.tglRealisasiPPA) &&
              //     touched?.realisasiPPA?.tglRealisasiPPA
              //   }
              //   errorText={
              //     Boolean(errors?.realisasiPPA?.tglRealisasiPPA) &&
              //     touched?.realisasiPPA?.tglRealisasiPPA &&
              //     errors?.realisasiPPA?.tglRealisasiPPA
              //   }
            />
          </Col>
          <Col lg="6">
            <Input
              label="No. Realisasi PPA"
              disabled
              value={
                isNomorLoading
                  ? "Memuat Data.."
                  : values?.postingRealisasiPPA?.noPostingRealisasiPPA
              }
              placeholder="Pilih tanggal terlebih dahulu"
            />
          </Col>
          <Col lg="12">
            <Input
              label="Jumlah"
              value={
                RupiahConvert(
                  parseFloat(
                    modalConfig?.data?.nominalRealisasi ?? 0
                  ).toString()
                ).getWithComa
              }
            />
            <SelectSearch
              label="COA Debet"
              placeholder="Pilih COA"
              option={dropdownCOA ?? []}
              onChange={(val) =>
                setValues({
                  ...values,
                  postingRealisasiPPA: {
                    ...values?.postingRealisasiPPA,
                    coaDebet: val.value,
                    coaDebetNama: val.label,
                  },
                })
              }
            />
            <SelectSearch
              label="COA Kredit"
              placeholder="Pilih COA"
              option={dropdownCOA ?? []}
              onChange={(val) =>
                setValues({
                  ...values,
                  postingRealisasiPPA: {
                    ...values?.postingRealisasiPPA,
                    coaKredit: val.value,
                    coaKreditNama: val.label,
                  },
                })
              }
            />
            <TextArea
              label="Keterangan Jurnal"
              rows="4"
              onChange={(val) =>
                setValues({
                  ...values,
                  postingRealisasiPPA: {
                    ...values?.postingRealisasiPPA,
                    keteranganJurnal: val.target.value,
                  },
                })
              }
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ActionButton
          text="Simpan"
          onClick={formSubmitHandler}
          loading={isSubmitting}
        />
      </Modal.Footer>
    </Modal>
  );
};
