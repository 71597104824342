// Components
import { NumberFormat } from "components"
import { Col, Row } from "react-bootstrap"

// Form
import { useFormikContext } from "formik"

const TabKonversi = ({ readOnly }) => {
    const { values, errors, touched, setFieldValue } = useFormikContext()

    return (
        <Row>
            <Col lg={6}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <small>Satuan Beli</small>
                            </td>
                            <td />
                            <td>
                                <small>Satuan Pakai</small>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right">
                                <b>1 {values.kode_satuan_beli ?? '-'}</b>
                            </td>
                            <td style={{ width: '25px' }} className="text-center"> = </td>
                            <td className="align-bottom">
                                <div className="d-flex">
                                    <NumberFormat
                                        label=""
                                        noMargin
                                        placeholder="Satuan Pakai"
                                        value={values.nilai_konversi}
                                        onChange={value => setFieldValue('nilai_konversi', value)}
                                        error={Boolean(errors.nilai_konversi && touched.nilai_konversi)}
                                        errorText={Boolean(errors.nilai_konversi && touched.nilai_konversi) && errors.nama_item}
                                        disabled={readOnly}
                                    />
                                    <div className="mt-1 ml-1">{values.kode_satuan_pakai ?? '-'}</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Col>
        </Row>
    )
}

export default TabKonversi