// Form
import { JobMixDesignApi } from "api";

// Components
import { useFormikContext } from "formik";

// API
import { SelectSearch } from "components";

const SalinAnalisa = ({ dropdown }) => {
  // Hooks
  const { setFieldValue, values, setValues } = useFormikContext();

  const onSalinAnalisaChange = (value) => {
    JobMixDesignApi.single_analisa({
      id_item_atribut: value.value,
    })
      .then((res) => {
        const filterAnalisa = res.data.data
          .filter((val) => val.id_buaso !== "1")
          .map((val) => ({
            ...val,
            nama_buaso: val.flag_kelompok === "BOK" ? "BOK" : val.nama_buaso,
          }));
        const finalValues = [...values.analisa, ...filterAnalisa];
        setFieldValue("analisa", finalValues);
        // setValues({ ...value, analisa:  });
      })
      .catch(() => alert("Data gagal dimuat"));
  };

  return (
    <SelectSearch
      label="Salin data analisa barang jadi"
      placeholder="Pilih untuk salin data analisa barang jadi"
      onChange={(val) => onSalinAnalisaChange(val)}
      option={dropdown.barang_jadi}
    />
  );
};

export default SalinAnalisa;
