import {
  ButtonCancel,
  ButtonCreate,
  ButtonDelete,
  ButtonUpdate,
  ButtonVerify,
} from "@bhawanadevteam/react-core";
import {
  DataStatus,
  NumberFormat,
  SelectSearch,
  TBody,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
  Tr,
} from "components";
import Thead from "components/Table/THead";
import { useFormikContext } from "formik";
import React from "react";

import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";

export const TableKomponenProduksi = ({ action, tab }) => {
  const { values } = useFormikContext();
  const checkTab = tab === "alatMesin" ? "alat mesin" : tab;
  const checkTabKey = tab === "alatMesin" ? "alat_mesin" : tab;

  const vendorShow = () => {
    if (tab === "upah" || tab === "subkon") return true;

    return false;
  };

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Informasi Realisasi Produksi Aset</ThFixed>
            <ThFixed>Informasi WIP Produksi Aset</ThFixed>
            <Th>Item {checkTab}</Th>
            {vendorShow() && <Th>Vendor</Th>}
            <Th>Qty.</Th>
            <Th>Harga Satuan</Th>
            <Th>Jumlah</Th>
            <Th>Akun COA</Th>
            <Th>Keterangan Jurnal</Th>
          </Tr>
        </Thead>
        <TBody>
          {values?.[tab]?.length > 0 ? (
            values?.[tab]?.map((val, ind) => (
              <Tr key={ind}>
                <Td>{ind + 1}</Td>
                <TdFixed>
                  <div>
                    {
                      DateConvert(new Date(val?.tgl_realisasi_produksi_aset))
                        .defaultDMY
                    }
                  </div>
                  <div>{val?.no_realisasi_produksi_aset ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {
                      DateConvert(new Date(val?.[`tgl_wip_${checkTabKey}`]))
                        .defaultDMY
                    }
                  </div>
                  <div>{val?.[`no_wip_${checkTabKey}`] ?? "-"}</div>
                </TdFixed>
                <Td>{val.nama_item ?? "-"}</Td>
                {vendorShow() && <Td>{val?.nama_vendor ?? "-"}</Td>}
                <Td className="text-right">
                  {DecimalConvert(parseFloat(val.qty ?? 0)).getWithComa}
                </Td>
                <Td>
                  {
                    RupiahConvert(
                      parseFloat(
                        val?.[`harga_satuan_${checkTabKey}`] ?? 0
                      ).toString()
                    ).getWithComa
                  }
                </Td>
                <Td>
                  {
                    RupiahConvert(parseFloat(val?.jumlah ?? 0).toString())
                      .getWithComa
                  }
                </Td>
                <Td>
                  <div>
                    D : {val?.nomor_akun_debet} - {val?.nama_akun_debet}
                  </div>
                  <div>
                    K : {val?.nomor_akun_kredit} - {val?.nama_akun_kredit}
                  </div>
                </Td>
                <Td>{val?.keterangan_jurnal ?? "-"}</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="8">
                <DataStatus text="Tidak Ada Data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </div>
  );
};
