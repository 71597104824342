import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, DatePicker, SelectSearch } from "components";
import { DateConvert } from "utilities";

export const ModalFilterPostingRealisasiPPA = ({
  show,
  setShow,
  data,
  setData,
  dropdown,
  type,
  setDefaultActiveKey,
}) => {
  const dataDropdownItem = [
    { label: "Semua", value: undefined },
    ...dropdown?.[type]?.map((val) => ({
      label: val?.nama_item,
      value: val?.id_item,
    })),
  ];

  console.log("cokk", type);

  // FORM VALUES
  const formInitialValues = {
    tgl_posting_realsasi_ppa_mulai: data?.tgl_posting_realsasi_ppa_mulai,
    tgl_posting_realsasi_ppa_selesai: data?.tgl_posting_realsasi_ppa_selesai,
    tgl_realisasi_ppa_mulai: data?.tgl_realisasi_ppa_mulai,
    tgl_realisasi_ppa_selesai: data?.tgl_realisasi_ppa_selesai,
    tgl_ppa_mulai: data?.tgl_ppa_mulai,
    tgl_ppa_selesai: data?.tgl_ppa_selesai,
    id_item_realisasi_ppa: data?.id_item_realisasi_ppa,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;
    if (show[type]?.show) {
      if (checkActive) {
        setData({
          ...data,
          [type]: { ...values, active: true },
        });
      } else {
        setData({
          ...data,
          [type]: { ...values, active: true },
        });
      }
    }
    setDefaultActiveKey(type);
    setShow({ ...show, [type]: false });
  };
  // HANDLE CHANGE
  const onTanggalChange = (dates, values, setValues, keyStart, keyEnd) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [keyStart]: startDate,
      [keyEnd]: endDate,
    });
  };

  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_posting_realsasi_ppa_mulai: undefined,
      tgl_posting_realsasi_ppa_selesai: undefined,
      tgl_realisasi_ppa_mulai: undefined,
      tgl_realisasi_ppa_selesai: undefined,
      tgl_ppa_mulai: undefined,
      tgl_ppa_selesai: undefined,
      id_item_realisasi_ppa: undefined,
    });
  };

  const toggleModalFilter = () => setShow({ ...show, [type]: false });

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          isToggle
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          toggleModal={toggleModalFilter}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Posting Realisasi PPA"
            placeholderText="Pilih tanggal"
            startDate={
              values.tgl_posting_realsasi_ppa_mulai
                ? new Date(values.tgl_posting_realsasi_ppa_mulai)
                : ""
            }
            endDate={
              values.tgl_posting_realsasi_ppa_selesai
                ? new Date(values.tgl_posting_realsasi_ppa_selesai)
                : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                "tgl_posting_realsasi_ppa_mulai",
                "tgl_posting_realsasi_ppa_selesai"
              )
            }
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Realisasi Produksi"
            placeholderText="Pilih tanggal"
            startDate={
              values.tgl_realisasi_ppa_mulai
                ? new Date(values.tgl_realisasi_ppa_mulai)
                : ""
            }
            endDate={
              values.tgl_realisasi_ppa_selesai
                ? new Date(values.tgl_realisasi_ppa_selesai)
                : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                "tgl_realisasi_ppa_mulai",
                "tgl_realisasi_ppa_selesai"
              )
            }
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. PPA"
            placeholderText="Pilih tanggal"
            startDate={
              values.tgl_ppa_mulai ? new Date(values.tgl_ppa_mulai) : ""
            }
            endDate={
              values.tgl_ppa_selesai ? new Date(values.tgl_ppa_selesai) : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                "tgl_ppa_mulai",
                "tgl_ppa_selesai"
              )
            }
            monthsShown={2}
          />
          <SelectSearch
            key={values.tgl_ppa_selesai}
            label={`Item Sumber Daya ${type}`}
            placeholder="Pilih item"
            defaultValue={dataDropdownItem.find(
              (val) => val.value === values.id_item_realisasi_ppa
            )}
            option={dataDropdownItem}
            onChange={(val) =>
              setFieldValue("id_item_realisasi_ppa", val.value)
            }
          />
        </FilterModal>
      )}
    </Formik>
  );
};
