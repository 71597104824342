import Services from "services";

class TransferOverheadJobMixApi {
  page(params) {
    return Services.get("/transfer_produksi_overhead/page", { params });
  }

  single(params) {
    return Services.get(`/transfer_produksi_overhead/single`, { params });
  }

  produksi_page(params) {
    return Services.get("/transfer_produksi_overhead/produksi_page", {
      params,
    });
  }

  produksi_single(value) {
    return Services.get(
      `/transfer_produksi_overhead/produksi_single?id_jobmix=${value}`
    );
  }

  jobmix_single(value) {
    return Services.get(
      `/transfer_produksi_overhead/jobmix_single?id_jobmix=${value}`
    );
  }

  histori(value) {
    return Services.get(
      `/transfer_produksi_overhead/histori?id_jobmix=${value}`
    );
  }

  getSinglePermintaan(params) {
    return Services.get("/transfer_produksi_overhead/permintaan_single", {
      params,
    });
  }

  no_baru(value) {
    return Services.get(`/transfer_produksi_overhead/no_baru?tanggal=${value}`);
  }

  getDropdownGudang(params) {
    return Services.get("/dropdown/gudang", { params });
  }

  getDropdownItem() {
    return Services.get("/dropdown/item_overhead");
  }

  getDropdownProduksi() {
    return Services.get("/dropdown/item_produksi");
  }

  getDropdownKaryawan() {
    return Services.get("/dropdown/karyawan");
  }

  save(data) {
    return Services.post("/transfer_produksi_overhead", data);
  }

  export(params) {
    return Services.get("/transfer_produksi_overhead/export/", { params });
  }
}

export default new TransferOverheadJobMixApi();
