import { useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import {
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  DataStatus,
} from "components";
import { DateConvert, RupiahConvert, TableNumber } from "utilities";

const TabItem = ({ data = [] }) => {
  return (
    <div className="p-1 bg-white">
      {/* Table Item */}
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th style={{ minWidth: 300 }} className="p-1">
              Item Komponen
            </Th>
            <Th style={{ width: 100 }} className="p-1">
              Qty. Komponen
            </Th>
            <Th style={{ width: 100 }} className="p-1">
              Satuan
            </Th>
            <Th style={{ width: 100 }} className="p-1">
              Harga Satuan
            </Th>
            <Th style={{ width: 100 }} className="p-1">
              Sub Total
            </Th>
          </Tr>
        </THead>
        <TBody>
          {data && data?.length > 0 ? (
            data.map((e, i) => (
              <Tr key={i}>
                <TdFixed>{i + 1}</TdFixed>
                <Td>{e?.nama_item ?? "-"}</Td>
                <Td textRight>
                  {e?.qty_produksi ? parseFloat(e.qty_produksi) : "-"}{" "}
                </Td>
                <Td>{e?.kode_satuan ?? "-"}</Td>
                <Td textRight>
                  {
                    RupiahConvert(String(parseFloat(e?.harga_satuan)))
                      .getWithComa
                  }
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(
                      String(
                        parseFloat(e?.harga_satuan ?? 0) *
                          parseFloat(e.qty_produksi ?? 0)
                      )
                    ).getWithComa
                  }
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={11}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </div>
  );
};

export default TabItem;
