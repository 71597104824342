import { Table, TBody, Td, Th, Tr } from "components";
import Thead from "components/Table/THead";
import React from "react";
import { DateConvert, DecimalConvert } from "utilities";

const TableSection = ({ data }) => {
  return (
    <Table responsive>
      <Thead>
        <Tr>
          <Th>Informasi Permintaan Produksi</Th>
          <Th>Item Overhead</Th>
          <Th>Qty. Permintaan</Th>
        </Tr>
      </Thead>
      <TBody>
        {data &&
          data?.map((val) => (
            <Tr>
              <Td>
                <div>
                  {val.tgl_permintaan_produksi_overhead
                    ? DateConvert(
                        new Date(val.tgl_permintaan_produksi_overhead)
                      ).defaultDMY
                    : "-"}
                </div>
                <div>{val.no_permintaan_produksi_overhead ?? "-"}</div>
              </Td>
              <Td>{val.nama_item ?? "-"}</Td>
              <Td className="text-right">
                {`${
                  DecimalConvert(parseFloat(val.qty_permintaan_produksi ?? 0))
                    .getWithComa
                } ${val.kode_satuan}`}
              </Td>
            </Tr>
          ))}
      </TBody>
    </Table>
  );
};

export default TableSection;
