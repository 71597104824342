import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, DatePicker, SelectSearch } from "components";
import { DateConvert } from "utilities";
import Axios from "axios";
import { PostingStockOpnameApi } from "api";

const ModalFilter = ({ show, setShow, data, setData }) => {
  // STATE DATA
  const [dataItemBarang, setDataUnitProduksi] = useState([
    { label: "Semua", value: undefined },
  ]);
  const [loading, setLoading] = useState(true);

  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([PostingStockOpnameApi.dropdownItemBarang()])
      .then(
        Axios.spread((unit_produksi) => {
          const mapDataPetugas = unit_produksi?.data?.data
            ? unit_produksi.data.data.map((val) => ({
                label: val?.nama_item,
                value: val?.id_item_buaso,
              }))
            : [];

          setDataUnitProduksi([...dataItemBarang, ...mapDataPetugas]);
        })
      )
      .finally(() => setLoading(false));
  };

  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown();
  }, []);
  // FORM VALUES
  const formInitialValues = {
    tgl_posting_stock_opname_mulai:
      data?.filter?.tgl_posting_stock_opname_mulai,
    tgl_posting_stock_opname_selesai:
      data?.filter?.tgl_posting_stock_opname_selesai,
    tgl_stock_opname_master_mulai: data?.filter?.tgl_stock_opname_master_mulai,
    tgl_stock_opname_master_selesai:
      data?.filter?.tgl_stock_opname_master_selesai,
    id_item_barang: data?.filter?.id_item_barang,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  // HANDLE CHANGE
  const onTglStockOpnameChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_stock_opname_master_mulai: startDate,
      tgl_stock_opname_master_selesai: endDate,
    });
  };

  const onTglPostingStockOpnameChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_posting_stock_opname_mulai: startDate,
      tgl_posting_stock_opname_selesai: endDate,
    });
  };

  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_posting_stock_opname_mulai: undefined,
      tgl_posting_stock_opname_selesai: undefined,
      tgl_stock_opname_master_mulai: undefined,
      tgl_stock_opname_master_selesai: undefined,
      id_item_barang: undefined,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}>
          <DatePicker
            selectsRange
            label="Tgl. Posting Stock Opname"
            placeholderText="Pilih tanggal posting stock opname"
            startDate={
              values.tgl_posting_stock_opname_mulai
                ? new Date(values.tgl_posting_stock_opname_mulai)
                : ""
            }
            endDate={
              values.tgl_posting_stock_opname_selesai
                ? new Date(values.tgl_posting_stock_opname_selesai)
                : ""
            }
            onChange={(dates) =>
              onTglPostingStockOpnameChange(dates, values, setValues)
            }
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Stock Opname"
            placeholderText="Pilih tanggal stock opname"
            startDate={
              values.tgl_stock_opname_master_mulai
                ? new Date(values.tgl_stock_opname_master_mulai)
                : ""
            }
            endDate={
              values.tgl_stock_opname_master_selesai
                ? new Date(values.tgl_stock_opname_master_selesai)
                : ""
            }
            onChange={(dates) =>
              onTglStockOpnameChange(dates, values, setValues)
            }
            monthsShown={2}
          />
          <SelectSearch
            key={values.id_item_barang}
            label="Item Barang"
            placeholder="Pilih Item Barang"
            defaultValue={dataItemBarang.find(
              (val) => val.value === values.id_item_barang
            )}
            option={dataItemBarang}
            onChange={(val) => {
              setFieldValue("id_item_barang", val.value);
            }}
            loading={loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
