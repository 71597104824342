import { DropdownButton, Spinner } from 'react-bootstrap';

const ExportButton = ({ disabled, loading, children }) => (
  <DropdownButton
    title={
      loading ? (
        <Spinner animation="border" variant="white" size="sm" />
      ) : (
        'Export Data'
      )
    }
    variant="warning text-white"
    disabled={loading || disabled}
  >
    {children}
  </DropdownButton>
);

export default ExportButton;
