import React from "react";
import { Row, Col } from "react-bootstrap";
import htmlParser from "html-react-parser";

const TabInfo = ({ data }) => {
  const InfoItem = ({ title, value }) => (
    <div className="d-flex flex-column mb-3">
      <small>{title}</small>
      <b>{value}</b>
    </div>
  );

  const InfoItemUraian = ({ title, value }) => (
    <div className="d-flex flex-column mb-3">
      <small>{title}</small>
      <div>{value}</div>
    </div>
  );

  const InfoItemLink = ({ title, value, text }) => (
    <div className="d-flex flex-column mb-3">
      <small>{title}</small>
      <b>
        <a href={value} target="_blank">
          {text}
        </a>
      </b>
    </div>
  );

  return (
    <Row>
      <Col>
        <InfoItem
          title="Kode Item"
          value={data?.kode_item ?? "-"}
        />
        <InfoItem
          title="Nama Barang Jadi"
          value={data?.nama_item ?? "-"}
        />
        <InfoItem
          title="Kelompok Barang Jadi"
          value={data?.nama_kelompok ?? "-"}
        />
        <InfoItem
          title="Jenis Barang Jadi"
          value={data?.nama_jenis ?? "-"}
        />
        <InfoItem
          title="Pabrikan"
          value={data?.nama_pabrikan ?? "-"}
        />
        <Row>
          <Col>
            <InfoItem
              title="Satuan Pakai"
              value={data?.nama_satuan_pakai ?? "-"} />
          </Col>
          <Col>
            <InfoItem
              title="Satuan Beli"
              value={data?.nama_satuan_beli ?? "-"} />
          </Col>
          <Col>
            <InfoItem
              title="Satuan Jual"
              value={data?.nama_satuan_jual ?? "-"} />
          </Col>

        </Row>

        {data.link_referensi ? (
          <InfoItemLink
            title="Link Referensi"
            value={data.link_referensi}
            text="Lihat Referensi"
          />
        ) : (
          <InfoItem title="Link Referensi" value="-" />
        )}
      </Col>
      <Col>
        <InfoItemUraian
          title="Uraian"
          value={data.uraian ? htmlParser(data.uraian) : "-"}
        />
      </Col>
    </Row>
  );
};

export default TabInfo