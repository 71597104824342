import { DataStatus, TBody, Table, Td, TdFixed, Th, Tr } from "components";
import Thead from "components/Table/THead";
import React from "react";
import { DateConvert, DecimalConvert } from "utilities";

export const TableInformasiUmum = ({ tableData }) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Informasi Sales Order</Th>
          <Th>Entitas</Th>
          <Th>Customer</Th>
          <Th>Proyek</Th>
          <Th>Sales</Th>
          <Th>Barang Jadi</Th>
          <Th>Tgl. Batas Waktu</Th>
        </Tr>
      </Thead>
      <TBody>
        {tableData && tableData.length > 0 ? (
          tableData?.map((val, ind) => (
            <Tr key={ind}>
              {val.rowspan !== 0 && (
                <TdFixed rowSpan={val.rowspan}>
                  <div>
                    {val?.tgl_sales_order
                      ? DateConvert(new Date(val?.tgl_sales_order)).defaultDMY
                      : "-"}
                  </div>
                  <div>{val?.no_sales_order ?? "-"}</div>
                </TdFixed>
              )}
              {val.rowspan !== 0 && (
                <>
                  <Td rowSpan={val.rowspan}>{val?.entitas ?? "AJB"}</Td>
                  <Td rowSpan={val.rowspan}>{val?.nama_customer ?? "-"}</Td>
                  <Td rowSpan={val.rowspan}>{val?.nama_proyek ?? "-"}</Td>
                  <Td rowSpan={val.rowspan}>{val?.nama_karyawan ?? "-"}</Td>
                </>
              )}
              <Td className="text-nowrap">{`${val?.nama_item ?? "-"} ${
                DecimalConvert(parseFloat(val?.qty_sales_order ?? 0))
                  .getWithComa
              } ${val?.kode_satuan ?? "-"}`}</Td>
              {val.rowspan !== 0 && (
                <Td rowSpan={val.rowspan}>
                  {val?.tgl_batas_waktu
                    ? DateConvert(new Date(val?.tgl_batas_waktu)).defaultDMY
                    : "-"}
                </Td>
              )}
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan="7">
              <DataStatus text="Data Tidak Ada" />
            </Td>
          </Tr>
        )}
      </TBody>
    </Table>
  );
};
