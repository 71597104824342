// React
import React, { useState, useEffect } from "react";

// Form
import { Formik } from "formik";

// Components
import { DatePicker, FilterModal, SelectSearch } from "components";
import { DateConvert } from "utilities";

// API
import Axios from "axios";
import { BDPProduksiApi } from "api";

// View Functions
// import { mappingDataItem } from "../../functions";

export const ModalFilterBDPProduksiList = ({
  toggle,
  data,
  setData,
  buaso,
  checkTitle,
}) => {
  // Variables
  const option = { label: "Semua Data", value: undefined };

  // States
  const [dropdownFilter, setDropdownFilter] = useState([]);
  const [loading, setLoading] = useState(true);

  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    Axios.all([BDPProduksiApi.dropdownBUASO(buaso)])
      .then(
        Axios.spread((buasoData) => {
          // eslint-disable-next-line
          const dataDropdownItem = buasoData.data.data.map((val) => ({
            label: val.nama_item,
            value: val.id_item,
          }));
          setDropdownFilter([option, ...dataDropdownItem]);
        })
      )
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line
  }, []);

  // FORM VALUES
  const formInitialValues = {
    tgl_realisasi_produksi_mulai: data?.[buaso]?.tgl_realisasi_produksi_mulai,
    tgl_realisasi_produksi_selesai:
      data?.[buaso]?.tgl_realisasi_produksi_selesai,
    [`tgl_bdp_${buaso}_mulai`]: data?.[buaso]?.[`tgl_bdp_${buaso}_mulai`],
    [`tgl_bdp_${buaso}_selesai`]: data?.[buaso]?.[`tgl_bdp_${buaso}_selesai`],
    id_item: data?.[buaso]?.id_item,
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setData({
      ...data,
      [buaso]: { ...values, active: checkActive, page: 1 },
    });

    toggle();
  };

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    setLoading(true);

    const myPromise = new Promise((resolve) => {
      setValues({
        tgl_realisasi_produksi_mulai: undefined,
        tgl_realisasi_produksi_selesai: undefined,
        [`tgl_bdp_${buaso}_mulai`]: undefined,
        [`tgl_bdp_${buaso}_selesai`]: undefined,
        id_item: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => setLoading(false));
  };

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          isToggle
          toggleModal={toggle}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. BDP"
            placeholderText="Tgl. BDP"
            startDate={
              values?.[`tgl_bdp_${buaso}_mulai`]
                ? new Date(values?.[`tgl_bdp_${buaso}_mulai`])
                : ""
            }
            endDate={
              values?.[`tgl_bdp_${buaso}_selesai`]
                ? new Date(values?.[`tgl_bdp_${buaso}_selesai`])
                : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                `tgl_bdp_${buaso}_mulai`,
                `tgl_bdp_${buaso}_selesai`
              )
            }
            monthsShown={2}
          />

          <DatePicker
            selectsRange
            label={
              <div className="text-capitalize">Tgl. Realisasi Produksi</div>
            }
            placeholderText={`Pilih Tgl. Realisasi Produksi`}
            startDate={
              values.tgl_realisasi_produksi_mulai
                ? new Date(values.tgl_realisasi_produksi_mulai)
                : ""
            }
            endDate={
              values?.tgl_realisasi_produksi_selesai
                ? new Date(values.tgl_realisasi_produksi_selesai)
                : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                `tgl_realisasi_produksi_mulai`,
                `tgl_realisasi_produksi_selesai`
              )
            }
            monthsShown={2}
          />

          <SelectSearch
            key="1"
            label={<div className="text-capitalize">Item {checkTitle}</div>}
            placeholderText={`Pilih Item ${checkTitle}`}
            defaultValue={
              dropdownFilter
                ? dropdownFilter.find((val) => val.value === values.id_item)
                : ""
            }
            option={dropdownFilter}
            onChange={(val) => setFieldValue("id_item", val.value)}
            loading={loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
