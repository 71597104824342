export const formInitialValues = (data) => {
  return Object({
    tgl_wip: data?.tgl_wip ?? undefined,
    tgl_permintaan_produksi_aset:
      data?.tgl_permintaan_produksi_aset ?? undefined,
    tgl_produksi_aset: data?.tgl_produksi_aset ?? undefined,
    tgl_realisasi_produksi_aset: data?.tgl_realisasi_produksi_aset ?? undefined,
    no_realisasi_produksi_aset: data?.no_realisasi_produksi_aset ?? "",
    no_produksi_aset: data?.no_produksi_aset ?? "",
    id_produksi_aset: data?.id_produksi_aset ?? undefined,
    id_permintaan_aset: data?.id_permintaan_aset ?? undefined,
    id_permintaan_aset_detail: data?.id_permintaan_aset_detail ?? undefined,
    tgl_permintaan_aset: data?.tgl_permintaan_aset ?? undefined,
    no_permintaan_aset: data?.no_permintaan_aset ?? undefined,
    nama_grup_aset: data?.nama_grup_aset ?? "",
    nama_kategori_aset: data?.nama_kategori_aset ?? "",
    nama_jenis_aset: data?.nama_jenis_aset ?? "",
    nama_aset: data?.nama_item_aset ?? "",
    keperluan: data?.keperluan ?? "",
    keterangan_jurnal: data?.keterangan_jurnal
      ? data?.keterangan_jurnal
      : `${data?.nama_item_aset} | ${data?.no_realisasi_produksi_aset}`,
    file:
      data?.file?.length > 0 ? data?.file?.map((val) => val?.path_file) : [],
    realisasi: {
      bahan:
        data?.realisasi?.bahan?.length > 0
          ? data?.realisasi?.bahan?.map((val) => ({
              id_realisasi: val?.id_realisasi_produksi_aset_bahan,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? undefined,
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nomor_akun_debet: val?.nomor_akun_debet ?? "",
              nama_akun_debet: val?.nama_akun_debet ?? "",
              nomor_akun_kredit: val?.nomor_akun_kredit ?? "",
              nama_akun_kredit: val?.nama_akun_kredit ?? "",
            }))
          : [],
      upah:
        data?.realisasi?.upah?.length > 0
          ? data?.realisasi?.upah?.map((val) => ({
              id_realisasi: val?.id_realisasi_produksi_aset_upah,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? undefined,
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_vendor: val?.nama_vendor ?? "",
              nomor_akun_debet: val?.nomor_akun_debet ?? "",
              nama_akun_debet: val?.nama_akun_debet ?? "",
              nomor_akun_kredit: val?.nomor_akun_kredit ?? "",
              nama_akun_kredit: val?.nama_akun_kredit ?? "",
            }))
          : [],
      alatMesin:
        data?.realisasi?.alat_mesin?.length > 0
          ? data?.realisasi?.alat_mesin?.map((val) => ({
              id_realisasi: val?.id_realisasi_produksi_aset_alat_mesin,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? undefined,
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nomor_akun_debet: val?.nomor_akun_debet ?? "",
              nama_akun_debet: val?.nama_akun_debet ?? "",
              nomor_akun_kredit: val?.nomor_akun_kredit ?? "",
              nama_akun_kredit: val?.nama_akun_kredit ?? "",
            }))
          : [],
      subkon:
        data?.realisasi?.subkon?.length > 0
          ? data?.realisasi?.subkon?.map((val) => ({
              id_realisasi: val?.id_realisasi_produksi_aset_subkon,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? undefined,
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_vendor: val?.nama_vendor ?? "",
              nomor_akun_debet: val?.nomor_akun_debet ?? "",
              nama_akun_debet: val?.nama_akun_debet ?? "",
              nomor_akun_kredit: val?.nomor_akun_kredit ?? "",
              nama_akun_kredit: val?.nama_akun_kredit ?? "",
            }))
          : [],
      overhead:
        data?.realisasi?.overhead?.length > 0
          ? data?.realisasi?.overhead?.map((val) => ({
              id_realisasi: val?.id_realisasi_produksi_aset_overhead,
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? undefined,
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nomor_akun_debet: val?.nomor_akun_debet ?? "",
              nama_akun_debet: val?.nama_akun_debet ?? "",
              nomor_akun_kredit: val?.nomor_akun_kredit ?? "",
              nama_akun_kredit: val?.nama_akun_kredit ?? "",
            }))
          : [],
    },
    history: {
      bahan:
        data?.wip?.bahan?.length > 0
          ? data?.wip?.bahan?.map((val) => ({
              id_realisasi: val?.id_realisasi_produksi_aset_bahan,
              tgl_wip: val?.tgl_wip_bahan ?? undefined,
              no_wip: val?.no_wip_bahan ?? "",
              tgl_produksi_aset: val?.tgl_produksi_aset ?? undefined,
              no_produksi_aset: val?.no_produksi_aset ?? "",
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? undefined,
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan_bahan ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nomor_akun_debet: val?.nomor_akun_debet ?? "",
              nama_akun_debet: val?.nama_akun_debet ?? "",
              nomor_akun_kredit: val?.nomor_akun_kredit ?? "",
              nama_akun_kredit: val?.nama_akun_kredit ?? "",
              keterangan_jurnal: val?.keterangan_jurnal ?? "",
              nama_grup_aset: val?.nama_grup_aset ?? "",
              nama_kategori_aset: val?.nama_kategori_aset ?? "",
              nama_jenis_aset: val?.nama_jenis_aset ?? "",
            }))
          : [],
      upah:
        data?.wip?.upah?.length > 0
          ? data?.wip?.upah?.map((val) => ({
              id_realisasi: val?.id_realisasi_produksi_aset_upah,
              tgl_wip: val?.tgl_wip_upah ?? undefined,
              no_wip: val?.no_wip_upah ?? "",
              tgl_produksi_aset: val?.tgl_produksi_aset ?? undefined,
              no_produksi_aset: val?.no_produksi_aset ?? "",
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? undefined,
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan_upah ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nomor_akun_debet: val?.nomor_akun_debet ?? "",
              nama_akun_debet: val?.nama_akun_debet ?? "",
              nomor_akun_kredit: val?.nomor_akun_kredit ?? "",
              nama_akun_kredit: val?.nama_akun_kredit ?? "",
              keterangan_jurnal: val?.keterangan_jurnal ?? "",
              nama_grup_aset: val?.nama_grup_aset ?? "",
              nama_kategori_aset: val?.nama_kategori_aset ?? "",
              nama_jenis_aset: val?.nama_jenis_aset ?? "",
            }))
          : [],
      alatMesin:
        data?.wip?.alat_mesin?.length > 0
          ? data?.wip?.alat_mesin?.map((val) => ({
              id_realisasi: val?.id_realisasi_produksi_aset_alat_mesin,
              tgl_wip: val?.tgl_wip_alat_mesin ?? undefined,
              no_wip: val?.no_wip_alat_mesin ?? "",
              tgl_produksi_aset: val?.tgl_produksi_aset ?? undefined,
              no_produksi_aset: val?.no_produksi_aset ?? "",
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? undefined,
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan_alat_mesin ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nomor_akun_debet: val?.nomor_akun_debet ?? "",
              nama_akun_debet: val?.nama_akun_debet ?? "",
              nomor_akun_kredit: val?.nomor_akun_kredit ?? "",
              nama_akun_kredit: val?.nama_akun_kredit ?? "",
              keterangan_jurnal: val?.keterangan_jurnal ?? "",
              nama_grup_aset: val?.nama_grup_aset ?? "",
              nama_kategori_aset: val?.nama_kategori_aset ?? "",
              nama_jenis_aset: val?.nama_jenis_aset ?? "",
            }))
          : [],
      subkon:
        data?.wip?.subkon?.length > 0
          ? data?.wip?.subkon?.map((val) => ({
              id_realisasi: val?.id_realisasi_produksi_aset_subkon,
              tgl_wip: val?.tgl_wip_subkon ?? undefined,
              no_wip: val?.no_wip_subkon ?? "",
              tgl_produksi_aset: val?.tgl_produksi_aset ?? undefined,
              no_produksi_aset: val?.no_produksi_aset ?? "",
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? undefined,
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan_subkon ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nomor_akun_debet: val?.nomor_akun_debet ?? "",
              nama_akun_debet: val?.nama_akun_debet ?? "",
              nomor_akun_kredit: val?.nomor_akun_kredit ?? "",
              nama_akun_kredit: val?.nama_akun_kredit ?? "",
              keterangan_jurnal: val?.keterangan_jurnal ?? "",
              nama_grup_aset: val?.nama_grup_aset ?? "",
              nama_kategori_aset: val?.nama_kategori_aset ?? "",
              nama_jenis_aset: val?.nama_jenis_aset ?? "",
            }))
          : [],
      overhead:
        data?.wip?.overhead?.length > 0
          ? data?.wip?.overhead?.map((val) => ({
              id_realisasi: val?.id_realisasi_produksi_aset_overhead,
              tgl_wip: val?.tgl_wip_overhead ?? undefined,
              no_wip: val?.no_wip_overhead ?? "",
              tgl_produksi_aset: val?.tgl_produksi_aset ?? undefined,
              no_produksi_aset: val?.no_produksi_aset ?? "",
              tgl_realisasi_produksi_aset:
                val?.tgl_realisasi_produksi_aset ?? undefined,
              no_realisasi_produksi_aset: val?.no_realisasi_produksi_aset ?? "",
              nama_item: val?.nama_item ?? "",
              qty_realisasi: val?.qty_realisasi ?? 0,
              harga_satuan: val?.harga_satuan_overhead ?? 0,
              nama_satuan: val?.nama_satuan ?? "",
              nomor_akun_debet: val?.nomor_akun_debet ?? "",
              nama_akun_debet: val?.nama_akun_debet ?? "",
              nomor_akun_kredit: val?.nomor_akun_kredit ?? "",
              nama_akun_kredit: val?.nama_akun_kredit ?? "",
              keterangan_jurnal: val?.keterangan_jurnal ?? "",
              nama_grup_aset: val?.nama_grup_aset ?? "",
              nama_kategori_aset: val?.nama_kategori_aset ?? "",
              nama_jenis_aset: val?.nama_jenis_aset ?? "",
            }))
          : [],
    },
  });
};
