// React
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// API
import { MappingCOAApi } from "api";
import axios from "axios";

// Components
import {
  CRUDLayout,
  DataStatus,
  Alert,
  Th,
  Td,
  BackButton,
  SelectSearch,
  ActionButton,
} from "components";
import { Card } from "react-bootstrap";

const CRUDMappingCOA = ({ setNavbarTitle }) => {
  // VARIABLES
  const title = "Mapping Kelompok Item terhadap COA";
  const history = useHistory();
  const location = useLocation();
  const routerState = location.state;

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(false);

  // STATE DATA KELOMPOK
  const [data, setData] = useState([]);
  const [dataAtribut, setDataAtribut] = useState({
    coa: [],
    posisi: [],
  });

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // FORM
  const [isSubmitting, setIsSubmitting] = useState(false);

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR

    setIsLoading(true);
    setShowAlert(false);

    axios
      .all([
        MappingCOAApi.data(),
        MappingCOAApi.dropdown({ tipe: "coa" }),
        MappingCOAApi.dropdown({ tipe: "posisi" }),
      ])
      .then(
        axios.spread((res, coa, posisi) => {
          setData(res.data.data);
          setDataAtribut({
            coa: coa.data.data,
            posisi: posisi.data.data,
          });
        })
      )
      .finally(() => setIsLoading(false));
  }, [setNavbarTitle]);

  const updateData = (index, coa, posisi) => {
    const value = data.find((val, id) => id === index);
    value.id_coa = coa;
    value.posisi = posisi;

    setData(data.map((val, id) => (id === index ? value : val)));
  };

  const submitData = () => {
    setIsSubmitting(true);
    let validation = true;

    // eslint-disable-next-line
    data.map((val) => {
      if (val?.id_coa === null || val.posisi === null) {
        validation = false;
      }
    });

    if (validation) {
      MappingCOAApi.create(data)
        .then(() =>
          history.push("/transaksi/mapping-kelompok-item-coa", {
            ...routerState,
            alert: {
              text: "Data berhasil diubah",
              variant: "success",
            },
          })
        )
        .catch(() => {
          setAlertConfig({
            text: "Data gagal diubah",
            variant: "danger",
          });
          setShowAlert(true);
        })
        .finally(() => setIsSubmitting(false));
    } else {
      setAlertConfig({
        text: "Akun COA dan Posisi wajib diisi",
        variant: "danger",
      });
      setShowAlert(true);

      setIsSubmitting(false);
    }
  };

  // TABLE COMPONENT
  const DataTable = () => {
    return (
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between">
            <b>Ubah {title}</b>
            <BackButton
              onClick={() =>
                history.push("/transaksi/mapping-kelompok-item-coa", {
                  ...routerState,
                })
              }
            />
          </div>
        </Card.Header>

        <Card.Body>
          <CRUDLayout.Table>
            <thead>
              <tr className="text-center">
                <Th>No.</Th>
                <Th>BUASO</Th>
                <Th>Kelompok Item</Th>
                <Th>Akun COA</Th>
                <Th>Posisi</Th>
              </tr>
            </thead>
            <tbody>
              {data?.map((val, index) => {
                const optionBaru = dataAtribut.coa.find(
                  (e) => e?.the_real_id_coa === val?.id_coa
                );
                const optionLama = dataAtribut.coa.filter(
                  (e) => e?.the_real_id_coa !== val?.id_coa
                );
                const option = [optionBaru, ...optionLama];

                return (
                  <tr key={val.id_kelompok}>
                    <Td className="text-center" width="20px">
                      {index + 1}
                    </Td>
                    <Td width="100px">{val.nama_buaso ?? "-"}</Td>
                    <Td width="150px">{val.nama_kelompok ?? "-"}</Td>
                    <Td noPadding={true}>
                      <SelectSearch
                        label=""
                        noMargin={true}
                        placeholder="Tidak dipilih"
                        onChange={(e) => updateData(index, e.value, val.posisi)}
                        option={option.map((e) => {
                          return {
                            value: e?.the_real_id_coa,
                            label:
                              e?.the_real_id_coa !== null
                                ? `${e.nomor_akun} - ${e.nama_akun}`
                                : e.nama_akun,
                          };
                        })} //option harus berupa value dan label
                        defaultValue={dataAtribut.coa.map((e) =>
                          e?.the_real_id_coa === val?.id_coa
                            ? {
                                value: e?.the_real_id_coa,
                                label:
                                  e?.the_real_id_coa !== null
                                    ? `${e.nomor_akun} - ${e.nama_akun}`
                                    : e.nama_akun,
                              }
                            : ""
                        )}
                      />
                    </Td>
                    <Td width="100px" noPadding={true}>
                      <SelectSearch
                        noMargin={true}
                        label=""
                        placeholder="Tidak dipilih"
                        onChange={(e) =>
                          updateData(index, val?.id_coa, e.value)
                        }
                        option={dataAtribut.posisi.map((e) => {
                          return {
                            value: e.kode,
                            label: e.nama,
                          };
                        })} //option harus berupa value dan label
                        defaultValue={dataAtribut.posisi.map((e) =>
                          e.kode === val.posisi
                            ? {
                                value: e.kode,
                                label: e.nama,
                              }
                            : ""
                        )}
                      />
                    </Td>
                  </tr>
                );
              })}
            </tbody>
          </CRUDLayout.Table>
        </Card.Body>

        <Card.Footer>
          <div className="text-right">
            <ActionButton
              text="Simpan"
              loading={isSubmitting}
              onClick={submitData}
            />
          </div>
        </Card.Footer>
      </Card>
    );
  };

  return (
    <>
      <CRUDLayout>
        {/* Alert */}
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />

        {isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data" />
        ) : !Array.isArray(data) ? (
          <DataStatus text="Tidak dapat memuat data" />
        ) : data.length > 0 ? (
          <DataTable />
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </CRUDLayout>
    </>
  );
};

export default CRUDMappingCOA;
