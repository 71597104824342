import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  CRUDLayout,
  InputSearch,
  // ExportButton,
  CreateButton,
  DataStatus,
  UpdateButton,
  Pagination,
  Alert,
  Th,
  Td,
  Tr,
  TBody,
  TdFixed,
  ReadButton,
  ApprovalStatusButton,
  FilterButton,
} from "components";
import { PostingRealisasiPPAApi } from "api";
import { DateConvert, RupiahConvert, TableNumber } from "utilities";
import Thead from "components/Table/THead";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { ModalFilterPostingRealisasiPPA, ExportButton } from "./Comps";
import { Card, Dropdown, Nav, Tab } from "react-bootstrap";
import { ModalDetailPostingRealisasiPPA } from "./Comps/ModalDetailPostingRealisasiPPA";
import FileSaver from "file-saver";
import { keyBy } from "lodash";

export const ListPostingRealisasiPPA = ({ setNavbarTitle }) => {
  // Title
  const title = "Realisasi PPA";

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDropdown, setIsLoadingDropdown] = useState(true);

  // STATE SEARCHING
  const [searchKey, setSearchKey] = useState({
    material: "",
    manusia: "",
    peralatan: "",
    overhead: "",
    lainnya: "",
    type: "",
  });

  // STATE DATA KELOMPOK
  const [data, setData] = useState({
    material: [],
    manusia: [],
    peralatan: [],
    overhead: [],
    lainnya: [],
  });

  const [dropdown, setDropdown] = useState({
    material: [],
    manusia: [],
    peralatan: [],
    overhead: [],
    lainnya: [],
  });

  const history = useHistory();
  const location = useLocation();
  const [defaultActiveKey, setDefaultActiveKey] = useState("material");

  const [dataFilter, setDataFilter] = useState({
    material: {
      tgl_posting_realsasi_ppa_mulai: undefined,
      tgl_posting_realsasi_ppa_selesai: undefined,
      tgl_realisasi_ppa_mulai: undefined,
      tgl_realisasi_ppa_selesai: undefined,
      tgl_ppa_mulai: undefined,
      tgl_ppa_selesai: undefined,
      id_item_realisasi_ppa: undefined,
      active: false,
    },
    manusia: {
      tgl_posting_realsasi_ppa_mulai: undefined,
      tgl_posting_realsasi_ppa_selesai: undefined,
      tgl_realisasi_ppa_mulai: undefined,
      tgl_realisasi_ppa_selesai: undefined,
      tgl_ppa_mulai: undefined,
      tgl_ppa_selesai: undefined,
      id_item_realisasi_ppa: undefined,
      active: false,
    },
    peralatan: {
      tgl_posting_realsasi_ppa_mulai: undefined,
      tgl_posting_realsasi_ppa_selesai: undefined,
      tgl_realisasi_ppa_mulai: undefined,
      tgl_realisasi_ppa_selesai: undefined,
      tgl_ppa_mulai: undefined,
      tgl_ppa_selesai: undefined,
      id_item_realisasi_ppa: undefined,
      active: false,
    },
    overhead: {
      tgl_posting_realsasi_ppa_mulai: undefined,
      tgl_posting_realsasi_ppa_selesai: undefined,
      tgl_realisasi_ppa_mulai: undefined,
      tgl_realisasi_ppa_selesai: undefined,
      tgl_ppa_mulai: undefined,
      tgl_ppa_selesai: undefined,
      id_item_realisasi_ppa: undefined,
      active: false,
    },
    lainnya: {
      tgl_posting_realsasi_ppa_mulai: undefined,
      tgl_posting_realsasi_ppa_selesai: undefined,
      tgl_realisasi_ppa_mulai: undefined,
      tgl_realisasi_ppa_selesai: undefined,
      tgl_ppa_mulai: undefined,
      tgl_ppa_selesai: undefined,
      id_item_realisasi_ppa: undefined,
      active: false,
    },
  });

  const [pagginationMaterial, setpagginationMaterial] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [pagginationManusia, setpagginationManusia] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [pagginationPeralatan, setpagginationPeralatan] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [pagginationIOverhead, setpagginationOverhead] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [pagginationLainnya, setpagginationLainnya] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });

  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    show: false,
  });

  const [alertSearch, setAlertSearch] = useState({
    material: { variant: "primary", text: "", show: false },
    manusia: { variant: "primary", text: "", show: false },
    peralatan: { variant: "primary", text: "", show: false },
    overhead: { variant: "primary", text: "", show: false },
    lainnya: { variant: "primary", text: "", show: false },
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);

    Axios.all([
      PostingRealisasiPPAApi.page("material", {
        q: searchKey.material,
        page: pagginationMaterial.page,
        per_page: pagginationMaterial.dataLength,
        ...dataFilter?.material,
      }),
      PostingRealisasiPPAApi.page("manusia", {
        q: searchKey.manusia,
        page: pagginationManusia.page,
        per_page: pagginationManusia.dataLength,
        ...dataFilter?.manusia,
      }),
      PostingRealisasiPPAApi.page("peralatan", {
        q: searchKey.peralatan,
        page: pagginationPeralatan.page,
        per_page: pagginationPeralatan.dataLength,
        ...dataFilter?.peralatan,
      }),
      PostingRealisasiPPAApi.page("overhead", {
        q: searchKey.overhead,
        page: pagginationIOverhead.page,
        per_page: pagginationIOverhead.dataLength,
        ...dataFilter?.overhead,
      }),
      PostingRealisasiPPAApi.page("lainnya", {
        q: searchKey.lainnya,
        page: pagginationLainnya.page,
        per_page: pagginationLainnya.dataLength,
        ...dataFilter?.lainnya,
      }),
    ])
      .then(
        Axios.spread((material, manusia, peralatan, overhead, lainnya) => {
          setData({
            material: material.data.data,
            manusia: manusia.data.data,
            peralatan: peralatan.data.data,
            overhead: overhead.data.data,
            lainnya: lainnya.data.data,
          });

          setpagginationMaterial({
            ...pagginationMaterial,
            dataCount: material?.data?.data_count,
            totalPage: material?.data?.total_page,
          });
          setpagginationManusia({
            ...pagginationMaterial,
            dataCount: manusia?.data?.data_count,
            totalPage: manusia?.data?.total_page,
          });
          setpagginationPeralatan({
            ...pagginationMaterial,
            dataCount: peralatan?.data?.data_count,
            totalPage: peralatan?.data?.total_page,
          });
          setpagginationOverhead({
            ...pagginationMaterial,
            dataCount: overhead?.data?.data_count,
            totalPage: overhead?.data?.total_page,
          });
          setpagginationLainnya({
            ...pagginationMaterial,
            dataCount: lainnya?.data?.data_count,
            totalPage: lainnya?.data?.total_page,
          });
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  // FUNCTION GET DATA DARI SERVER
  const getDataDrodpown = () => {
    setIsLoadingDropdown(true);

    Axios.all([
      PostingRealisasiPPAApi.dropdownItemMaterial(),
      PostingRealisasiPPAApi.dropdownItemManusia(),
      PostingRealisasiPPAApi.dropdownItemPeralatan(),
      PostingRealisasiPPAApi.dropdownItemOverhead(),
      PostingRealisasiPPAApi.dropdownItemLainnya(),
    ])
      .then(
        Axios.spread((material, manusia, peralatan, overhead, lainnya) => {
          setDropdown({
            material: material.data.data,
            manusia: manusia.data.data,
            peralatan: peralatan.data.data,
            overhead: overhead.data.data,
            lainnya: lainnya.data.data,
          });
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        setIsLoadingDropdown(false);
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER
    getDataDrodpown(); // GET DATA DROPDOWN DARI SERVER
    checkAlert();

    return () => {
      setIsLoading(false);
    };
  }, [
    setNavbarTitle,
    pagginationMaterial.page,
    pagginationMaterial.dataLength,
    pagginationManusia.page,
    pagginationManusia.dataLength,
    pagginationIOverhead.page,
    pagginationIOverhead.dataLength,
    pagginationPeralatan.page,
    pagginationPeralatan.dataLength,
    pagginationLainnya.page,
    pagginationLainnya.dataLength,
    dataFilter?.material?.tgl_posting_realsasi_ppa_mulai,
    dataFilter?.material?.tgl_posting_realsasi_ppa_selesai,
    dataFilter?.material?.tgl_realisasi_ppa_mulai,
    dataFilter?.material?.tgl_realisasi_ppa_selesai,
    dataFilter?.material?.tgl_ppa_mulai,
    dataFilter?.material?.tgl_ppa_selesai,
    dataFilter?.material?.id_item_realisasi_ppa,
    dataFilter?.manusia?.tgl_posting_realsasi_ppa_mulai,
    dataFilter?.manusia?.tgl_posting_realsasi_ppa_selesai,
    dataFilter?.manusia?.tgl_realisasi_ppa_mulai,
    dataFilter?.manusia?.tgl_realisasi_ppa_selesai,
    dataFilter?.manusia?.tgl_ppa_mulai,
    dataFilter?.manusia?.tgl_ppa_selesai,
    dataFilter?.manusia?.id_item_realisasi_ppa,
    dataFilter?.peralatan?.tgl_posting_realsasi_ppa_mulai,
    dataFilter?.peralatan?.tgl_posting_realsasi_ppa_selesai,
    dataFilter?.peralatan?.tgl_realisasi_ppa_mulai,
    dataFilter?.peralatan?.tgl_realisasi_ppa_selesai,
    dataFilter?.peralatan?.tgl_ppa_mulai,
    dataFilter?.peralatan?.tgl_ppa_selesai,
    dataFilter?.peralatan?.id_item_realisasi_ppa,
    dataFilter?.overhead?.tgl_posting_realsasi_ppa_mulai,
    dataFilter?.overhead?.tgl_posting_realsasi_ppa_selesai,
    dataFilter?.overhead?.tgl_realisasi_ppa_mulai,
    dataFilter?.overhead?.tgl_realisasi_ppa_selesai,
    dataFilter?.overhead?.tgl_ppa_mulai,
    dataFilter?.overhead?.tgl_ppa_selesai,
    dataFilter?.overhead?.id_item_realisasi_ppa,
    dataFilter?.lainnya?.tgl_posting_realsasi_ppa_mulai,
    dataFilter?.lainnya?.tgl_posting_realsasi_ppa_selesai,
    dataFilter?.lainnya?.tgl_realisasi_ppa_mulai,
    dataFilter?.lainnya?.tgl_realisasi_ppa_selesai,
    dataFilter?.lainnya?.tgl_ppa_mulai,
    dataFilter?.lainnya?.tgl_ppa_selesai,
    dataFilter?.lainnya?.id_item_realisasi_ppa,
    searchKey.material,
    searchKey.manusia,
    searchKey.overhead,
    searchKey.peralatan,
    searchKey.lainnya,
  ]);

  // TABLE COMPONENT
  const Table = ({ tableData, pagginate, tabType }) => {
    const [modalConfig, setModalConfig] = useState({
      show: false,
      tabType: "",
      id: "",
    });

    return isLoading ? (
      <DataStatus loading={isLoading} text="Memuat Data" />
    ) : (
      <>
        <CRUDLayout.Table>
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Aksi</Th>
              <Th>Informasi Posting Realisasi PPA</Th>
              <Th>Informasi Realisasi PPA</Th>
              <Th>Informasi PPA</Th>
              <Th>Item Sumber Daya</Th>
              <Th>Jumlah</Th>
              <Th>Akun COA</Th>
              <Th>Keterangan Jurnal</Th>
            </Tr>
          </Thead>
          <TBody>
            {tableData?.length <= 0 ? (
              <Tr>
                <Td colSpan="9">
                  <DataStatus text="Tidak Ada Data" />
                </Td>
              </Tr>
            ) : (
              tableData?.map((val, index) => {
                return (
                  <Tr key={val.id_posting_realisasi_ppa}>
                    <Td className="text-center" width="20px">
                      {TableNumber(
                        pagginationMaterial.page,
                        pagginationMaterial.dataLength,
                        index
                      )}
                    </Td>
                    <TdFixed>
                      <div className="d-flex justify-content-center">
                        <ReadButton
                          size="sm"
                          className="my-1 mr-1"
                          onClick={() => {
                            setModalConfig({
                              show: true,
                              id: val.id_posting_realisasi_ppa,
                              tabType: tabType,
                            });
                          }}
                        />
                      </div>
                    </TdFixed>
                    <TdFixed>
                      <div>
                        {val.tgl_posting_realsasi_ppa
                          ? DateConvert(new Date(val?.tgl_posting_realsasi_ppa))
                              .defaultDMY
                          : "-"}
                      </div>
                      <div>{val.no_posting_realisasi_ppa ?? ""}</div>
                    </TdFixed>
                    <TdFixed>
                      <div>
                        {val.tgl_realisasi_ppa
                          ? DateConvert(new Date(val?.tgl_realisasi_ppa))
                              .defaultDMY
                          : "-"}
                      </div>
                      <div>{val.no_realisasi_ppa ?? ""}</div>
                    </TdFixed>
                    <TdFixed>
                      <div>
                        {val.tgl_ppa
                          ? DateConvert(new Date(val?.tgl_ppa)).defaultDMY
                          : "-"}
                      </div>
                      <div>{val.no_ppa ?? ""}</div>
                    </TdFixed>
                    <Td>{val.nama_item_sumber_daya ?? "-"}</Td>
                    <Td>
                      {
                        RupiahConvert(
                          parseFloat(
                            val.jumlah_posting_realisasi_ppa ?? 0
                          ).toString()
                        ).getWithComa
                      }
                    </Td>
                    <Td>
                      <div>
                        D: {val.coa_debet ?? "-"} - {val.nama_akun_debet ?? "-"}
                      </div>
                      <div>
                        K: {val.coa_kredit ?? "-"} -{" "}
                        {val.nama_akun_kredit ?? "-"}
                      </div>
                    </Td>
                    <Td>{val.keterangan_jurnal ?? "-"}</Td>
                  </Tr>
                );
              })
            )}
          </TBody>
        </CRUDLayout.Table>
        {tableData.length > 0 && (
          <Pagination
            dataLength={pagginate.dataLength}
            dataNumber={
              pagginate.page * pagginate.dataLength - pagginate.dataLength + 1
            }
            dataPage={
              pagginate.dataCount < pagginate.dataLength
                ? pagginate.dataCount
                : pagginate.page * pagginate.dataLength
            }
            dataCount={pagginate.dataCount}
            currentPage={pagginate.page}
            totalPage={pagginate.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
        {modalConfig.show && (
          <ModalDetailPostingRealisasiPPA
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
          />
        )}
      </>
    );
  };

  const TabListPostingRealisasiPPA = () => {
    const [searchTime, setSearchTime] = useState(null);
    const [searchValue, setSearchValue] = useState({
      material: "",
      manusia: "",
      peralatan: "",
      overhead: "",
      lainnya: "",
      type: "",
    });
    const [loadingExport, setLoadingExport] = useState(false);

    const [showModalFilter, setShowModalFilter] = useState({
      material: { show: false },
      manusia: { show: false },
      peralatan: { show: false },
      overhead: { show: false },
      lainnya: { show: false },
    });

    const onInputSearch = (text, type) => {
      const key = text.target.value;

      setSearchValue({ ...searchValue, [type]: key });
      if (searchTime) clearTimeout(searchTime);
      setSearchTime(
        setTimeout(() => {
          setSearchKey({ ...searchKey, [type]: key, type });
          setDefaultActiveKey(type);
          setAlertSearch({
            ...alertSearch,
            [type]: {
              variant: "primary",
              text: `Hasil Pencarian : ${key}`,
              show: key === "" ? false : true,
            },
          });
        }, 750)
      );
    };

    const exportData = (tipe, item) => {
      setLoadingExport(true);

      PostingRealisasiPPAApi.export(
        {
          tipe,
          ...dataFilter?.[item],
        },
        item
      )
        .then((res) => {
          const data = res.data.data;

          FileSaver.saveAs(data, data);
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Export Data gagal",
          });
        })
        .finally(() => setLoadingExport(false));
    };

    useEffect(() => {
      setSearchValue({
        ...searchValue,
        [searchKey?.type]: searchKey?.[searchKey?.type],
      });
    }, []);

    return (
      <Tab.Container defaultActiveKey={defaultActiveKey}>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="material">Sumber Daya Material</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="manusia">Sumber Daya Manusia</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="peralatan">Sumber Daya Peralatan</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="overhead">
                Sumber Daya Biaya Overhead
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="lainnya">Sumber Daya Lainnya</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Tab.Content>
          <Tab.Pane eventKey="material">
            <div className="py-3 px-2">
              <div className="d-flex mb-3">
                <InputSearch
                  onChange={(e) => {
                    onInputSearch(e, "material");
                  }}
                  value={searchValue.material}
                />
                <FilterButton
                  active={dataFilter?.material?.active}
                  onClick={() =>
                    setShowModalFilter({
                      ...showModalFilter,
                      material: { ...showModalFilter.material, show: true },
                    })
                  }
                />
                <ExportButton loading={loadingExport}>
                  <Dropdown.Item
                    onClick={() => exportData("excel", "material")}
                  >
                    Excel (.xlsx)
                  </Dropdown.Item>
                </ExportButton>
              </div>

              {/* Alert */}
              <Alert
                show={alertSearch?.material?.show}
                showCloseButton={true}
                variant={alertSearch?.material?.variant}
                text={alertSearch?.material?.text}
                onClose={() =>
                  setAlertSearch({
                    ...alertSearch,
                    material: { ...alertSearch?.material, show: false },
                  })
                }
              />
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Material</b>
              </span>
              <Table
                tableData={data.material}
                pagginate={pagginationMaterial}
                tabType="material"
              />
              {showModalFilter.material.show && (
                <ModalFilterPostingRealisasiPPA
                  show={showModalFilter}
                  setShow={setShowModalFilter}
                  data={dataFilter}
                  setData={setDataFilter}
                  dropdown={dropdown}
                  setDefaultActiveKey={setDefaultActiveKey}
                  type="material"
                />
              )}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="manusia">
            <div className="py-3 px-2">
              <div className="d-flex mb-3">
                <InputSearch
                  onChange={(e) => {
                    onInputSearch(e, "manusia");
                  }}
                  value={searchValue.manusia}
                />
                <FilterButton
                  active={dataFilter?.manusia?.active}
                  onClick={() =>
                    setShowModalFilter({
                      ...showModalFilter,
                      manusia: { ...showModalFilter.manusia, show: true },
                    })
                  }
                />
                <ExportButton
                  loading={loadingExport}
                  // disabled={!dataFilter?.filter?.active}
                >
                  <Dropdown.Item onClick={() => exportData("excel", "manusia")}>
                    Excel (.xlsx)
                  </Dropdown.Item>
                </ExportButton>
              </div>

              {/* Alert */}
              <Alert
                show={alertSearch?.manusia?.show}
                showCloseButton={true}
                variant={alertSearch?.manusia?.variant}
                text={alertSearch?.manusia?.text}
                onClose={() =>
                  setAlertSearch({
                    ...alertSearch,
                    manusia: { ...alertSearch?.manusia, show: false },
                  })
                }
              />
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Manusia</b>
              </span>
              <Table
                tableData={data.manusia}
                pagginate={pagginationManusia}
                tabType="manusia"
              />
              {showModalFilter.manusia.show && (
                <ModalFilterPostingRealisasiPPA
                  show={showModalFilter}
                  setShow={setShowModalFilter}
                  data={dataFilter}
                  setData={setDataFilter}
                  dropdown={dropdown}
                  setDefaultActiveKey={setDefaultActiveKey}
                  type="manusia"
                />
              )}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="peralatan">
            <div className="py-3 px-2">
              <div className="d-flex mb-3">
                <InputSearch
                  onChange={(e) => {
                    onInputSearch(e, "peralatan");
                  }}
                  value={searchValue.peralatan}
                />
                <FilterButton
                  active={dataFilter?.peralatan?.active}
                  onClick={() =>
                    setShowModalFilter({
                      ...showModalFilter,
                      peralatan: { ...showModalFilter.peralatan, show: true },
                    })
                  }
                />
                <ExportButton
                  loading={loadingExport}
                  // disabled={!dataFilter?.filter?.active}
                >
                  <Dropdown.Item
                    onClick={() => exportData("excel", "peralatan")}
                  >
                    Excel (.xlsx)
                  </Dropdown.Item>
                </ExportButton>
              </div>

              {/* Alert */}
              <Alert
                show={alertSearch?.peralatan?.show}
                showCloseButton={true}
                variant={alertSearch?.peralatan?.variant}
                text={alertSearch?.peralatan?.text}
                onClose={() =>
                  setAlertSearch({
                    ...alertSearch,
                    peralatan: { ...alertSearch?.peralatan, show: false },
                  })
                }
              />
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Peralatan</b>
              </span>
              <Table
                tableData={data.peralatan}
                pagginate={pagginationPeralatan}
                tabType="peralatan"
              />
              {showModalFilter.peralatan.show && (
                <ModalFilterPostingRealisasiPPA
                  show={showModalFilter}
                  setShow={setShowModalFilter}
                  data={dataFilter}
                  setData={setDataFilter}
                  dropdown={dropdown}
                  setDefaultActiveKey={setDefaultActiveKey}
                  type="peralatan"
                />
              )}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="overhead">
            <div className="py-3 px-2">
              <div className="d-flex mb-3">
                <InputSearch
                  onChange={(e) => {
                    onInputSearch(e, "overhead");
                  }}
                  value={searchValue.overhead}
                />
                <FilterButton
                  active={dataFilter?.overhead?.active}
                  onClick={() =>
                    setShowModalFilter({
                      ...showModalFilter,
                      overhead: { ...showModalFilter.overhead, show: true },
                    })
                  }
                />
                <ExportButton
                  loading={loadingExport}
                  // disabled={!dataFilter?.filter?.active}
                >
                  <Dropdown.Item
                    onClick={() => exportData("excel", "overhead")}
                  >
                    Excel (.xlsx)
                  </Dropdown.Item>
                </ExportButton>
              </div>
              {/* Alert */}
              <Alert
                show={alertSearch?.overhead?.show}
                showCloseButton={true}
                variant={alertSearch?.overhead?.variant}
                text={alertSearch?.overhead?.text}
                onClose={() =>
                  setAlertSearch({
                    ...alertSearch,
                    overhead: { ...alertSearch?.overhead, show: false },
                  })
                }
              />
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Biaya Overhead</b>
              </span>
              <Table
                tableData={data.overhead}
                pagginate={pagginationIOverhead}
                tabType="overhead"
              />
              {showModalFilter.overhead.show && (
                <ModalFilterPostingRealisasiPPA
                  show={showModalFilter}
                  setShow={setShowModalFilter}
                  data={dataFilter}
                  setData={setDataFilter}
                  dropdown={dropdown}
                  setDefaultActiveKey={setDefaultActiveKey}
                  type="overhead"
                />
              )}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="lainnya">
            <div className="py-3 px-2">
              <div className="d-flex mb-3">
                <InputSearch
                  onChange={(e) => {
                    onInputSearch(e, "lainnya");
                  }}
                  value={searchValue.lainnya}
                />
                <FilterButton
                  active={dataFilter?.lainnya?.active}
                  onClick={() =>
                    setShowModalFilter({
                      ...showModalFilter,
                      lainnya: { ...showModalFilter.lainnya, show: true },
                    })
                  }
                />
                <ExportButton
                  loading={loadingExport}
                  // disabled={!dataFilter?.filter?.active}
                >
                  <Dropdown.Item onClick={() => exportData("excel", "lainnya")}>
                    Excel (.xlsx)
                  </Dropdown.Item>
                </ExportButton>
              </div>
              {/* Alert */}
              <Alert
                show={alertSearch?.lainnya?.show}
                showCloseButton={true}
                variant={alertSearch?.lainnya?.variant}
                text={alertSearch?.lainnya?.text}
                onClose={() =>
                  setAlertSearch({
                    ...alertSearch,
                    lainnya: { ...alertSearch?.lainnya, show: false },
                  })
                }
              />
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Lainnya</b>
              </span>
              <Table
                tableData={data.lainnya}
                pagginate={pagginationLainnya}
                tabType="lainnya"
              />
              {showModalFilter.lainnya.show && (
                <ModalFilterPostingRealisasiPPA
                  show={showModalFilter}
                  setShow={setShowModalFilter}
                  data={dataFilter}
                  setData={setDataFilter}
                  dropdown={dropdown}
                  setDefaultActiveKey={setDefaultActiveKey}
                  type="lainnya"
                />
              )}
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    );
  };

  return (
    <>
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection></CRUDLayout.HeadSearchSection>

          {/* Button Section */}
          <CRUDLayout.HeadButtonSection>
            <CreateButton
              onClick={() =>
                history.push("posting-realisasi-ppa/realisasi-ppa")
              }
              className="mb-3"
            />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>

        {/* Alert */}
        <Alert
          show={alertConfig.show}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />

        <>
          <Card>
            <TabListPostingRealisasiPPA />
          </Card>
        </>
      </CRUDLayout>
    </>
  );
};
