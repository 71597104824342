import { useState, createContext } from 'react';

export const PostingRealisasiContext = createContext();

export const PostingRealisasiProvider = ({ children }) => {
  const [modalFormPosting, setModalFormPosting] = useState({
    show: false,
    buaso: 'bahan',
    isCreate: true,
    data: null,
  });

  const [dataHistory, setDataHistory] = useState([
    {
      bahan: [],
      upah: [],
      alat_mesin: [],
      subkon: [],
      overhead: [],
    },
  ]);

  return (
    <PostingRealisasiContext.Provider
      value={{
        modalFormPosting,
        setModalFormPosting,
        dataHistory,
        setDataHistory,
      }}
    >
      {children}
    </PostingRealisasiContext.Provider>
  );
};

export const withPostingRealisasiProvider = (Component) => (props) => {
  return (
    <PostingRealisasiProvider>
      <Component {...props} />
    </PostingRealisasiProvider>
  );
};
