import { Table, Th, Td } from "components";
import { RupiahConvert } from "utilities";

const SummaryBDP = ({ calculateBDP }) => {
  const dataSummary = [
    { nama_bdp: "Bahan", subtotal: calculateBDP?.totalBahan ?? 0 },
    { nama_bdp: "Upah", subtotal: calculateBDP?.totalUpah ?? 0 },
    { nama_bdp: "Alat Mesin", subtotal: calculateBDP?.totalAlatMesin ?? 0 },
    { nama_bdp: "Subkon", subtotal: calculateBDP?.totalSubcont ?? 0 },
    { nama_bdp: "Overhead", subtotal: calculateBDP?.totalOverhead ?? 0 },
  ];
  return (
    <div className="table-responsive">
      <Table>
        <thead className="text-center bg-light">
          <tr>
            <Th>No.</Th>
            <Th>Grup BDP</Th>
            <Th width={300}> Total </Th>
          </tr>
        </thead>
        <tbody>
          {dataSummary?.map((val, index) => (
            <tr key={index}>
              <Td textCenter width={20}>
                {index + 1}
              </Td>
              <Td>{`BDP ${val.nama_bdp}`}</Td>
              <Td textRight>
                {
                  RupiahConvert(String(parseFloat(val.subtotal) ?? 0))
                    .getWithComa
                }
              </Td>
            </tr>
          ))}
          <tr className="bg-light">
            <Td colSpan={2} className="p-2" textRight>
              <b style={{ fontSize: 14 }}>Grand Total</b>
            </Td>
            <Td textRight className="p-2">
              <b style={{ fontSize: 14 }}>
                {
                  RupiahConvert(String(parseFloat(calculateBDP?.totalBDP ?? 0)))
                    .getWithComa
                }
              </b>
            </Td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default SummaryBDP;
