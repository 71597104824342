import { dateConvert } from 'utilities2';

export const formSubmitValueMapper = (data = {}, submitType = 'CREATE') => {
  if (submitType === 'REVISE') {
    return {
      status_approval: 'REV',
      id_realisasi_petty_cash: data.id_realisasi_petty_cash,
      catatan_revisi: data.catatan_revisi,
    };
  }

  return data?.detail
    ?.filter(({ isPosting }) => !!isPosting)
    ?.map((val) => ({
      status_approval: 'APP',
      tgl_posting_petty_cash: dateConvert().getDashYMD(
        new Date(val.tgl_posting_petty_cash)
      ),
      no_posting_petty_cash: val.no_posting_petty_cash,
      jumlah_realisasi_petty_cash: Math.round(val.qty * val.harga_satuan) ?? 0,
      keterangan: val.keterangan,
      nomor_akun_debet: val.nomor_akun_debet,
      nomor_akun_kredit: val.nomor_akun_kredit,
      id_realisasi_petty_cash_detail: val.id_realisasi_petty_cash_detail,
      id_realisasi_petty_cash: data.id_realisasi_petty_cash,
    }));
};
