import Services from "services";

class PostingRealisasiWorkOrderApi {
  get(params) {
    return Services.get("/posting_realisasi_work_order/page", { params });
  }

  getRealisasi(params) {
    return Services.get("/posting_realisasi_work_order/realisasi_page", {
      params,
    });
  }

  getSingleRealisasi(params) {
    return Services.get("/posting_realisasi_work_order/single_realisasi", {
      params,
    });
  }

  getNoBaru(params) {
    return Services.get("/posting_realisasi_work_order/no_baru", { params });
  }

  getDropdownAset() {
    return Services.get("/dropdown/item_aset");
  }

  getDropdownFilter(params) {
    return Services.get("/posting_realisasi_work_order/item", { params });
  }

  getDropdownCOA() {
    return Services.get("/dropdown/coa");
  }

  save(data) {
    return Services.post("/posting_realisasi_work_order", data);
  }

  export(params) {
    return Services.get(`/posting_realisasi_work_order/export`, {
      params,
    });
  }
}

export default new PostingRealisasiWorkOrderApi();
