import React, { useState, useEffect } from "react";
import {
  Table,
  Th,
  Td,
  DataStatus,
  TdFixed,
  InputQtyDecimal,
  ThFixed,
  Tr,
  THead,
  TBody,
  ActionButton,
  SelectSearch,
  CreateButton,
  UpdateButton,
  DeleteButton,
  NumberFormat,
} from "components";
import { useLocation } from "react-router-dom";
import { Formik, useFormikContext } from "formik";

import { SubKegiatanApi } from "api";
import * as Yup from "yup";
import { ButtonGroup } from "react-bootstrap";
import {
  AiOutlineDownload,
  AiOutlineEye,
  AiOutlineCheck,
} from "react-icons/ai";
import { DecimalConvert, RupiahConvert } from "utilities";
import { ModalPostingRealisasiPPA, ModalUpload } from "../Comps";
import { ButtonCreate } from "@bhawanadevteam/react-core";

export const TableItemAsetSection = ({
  dropdown,
  type,
  tabType,
  alertConfig,
  setAlertConfig,
}) => {
  const location = useLocation();
  const [update, setUpdate] = useState("");
  const [modalUploadConfig, setModalUploadConfig] = useState({
    show: false,
    index: null,
    data: {},
  });
  const [modalPostingConfig, setModalPostingConfig] = useState({
    show: false,
    index: null,
    data: {},
  });
  const { values, setValues } = useFormikContext();
  const checkKeyTab =
    tabType === "material"
      ? "detailMaterial"
      : tabType === "manusia"
      ? "detailManusia"
      : tabType === "peralatan"
      ? "detailPeralatan"
      : tabType === "overhead"
      ? "detailOverhead"
      : "detailLainnya";

  return (
    <>
      <Table>
        <THead>
          <Tr>
            <Th>No</Th>
            <Th>Grup Aset</Th>
            <Th>Kategori Aset</Th>
            <Th>Jenis Aset</Th>
            <Th className="text-capitalize">Item {tabType}</Th>
            <Th>Satuan</Th>
            <Th>Qty.</Th>
            {type !== "read" && (
              <>
                <Th>Qty. Terealisasi</Th>
                <Th>Sisa</Th>
              </>
            )}

            <Th>Qty. Realisasi</Th>
            <Th>Harga Satuan PPA</Th>
            <Th>Sub Total</Th>
            {type !== "read" && (
              <>
                <Th>Nominal Terealisasi</Th>
                <Th>Sisa</Th>
              </>
            )}
            <Th>Nominal Realisasi</Th>
            <ThFixed>Bukti</ThFixed>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {values?.subKegiatan?.[checkKeyTab]?.length > 0 &&
            values?.subKegiatan?.[checkKeyTab]?.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <Td>{val.namaGrup ?? "-"}</Td>
                <Td>{val.namaKategori ?? "-"}</Td>
                <Td>{val.namaJenis ?? "-"}</Td>
                <Td>{val.namaItemSumberDaya ?? "-"}</Td>
                <Td>{val.namaSatuan ?? "-"}</Td>
                <Td className="text-right">
                  {DecimalConvert(parseFloat(val.qtyPPA ?? 0)).getWithComa}
                </Td>
                {type !== "read" && (
                  <>
                    <Td className="text-right">
                      {
                        DecimalConvert(parseFloat(val.qtyTerealisasi ?? 0))
                          .getWithComa
                      }
                    </Td>
                    <Td className="text-right">
                      {
                        DecimalConvert(
                          parseFloat(val.qty ?? 0) -
                            parseFloat(val.qtyTerealisasi ?? 0)
                        ).getWithComa
                      }
                    </Td>
                  </>
                )}
                <Td className="text-right">
                  {type !== "read" ? (
                    <InputQtyDecimal
                      placeholder="Masukan qty"
                      value={values?.qtyRealisasi ?? null}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          subKegiatan: {
                            ...values.subKegiatan,
                            [checkKeyTab]: values?.subKegiatan?.[
                              checkKeyTab
                            ]?.map((res, i) =>
                              index === i ? { ...res, qtyRealisasi: e } : res
                            ),
                          },
                        })
                      }
                    />
                  ) : (
                    DecimalConvert(parseFloat(val.qtyRealisasi ?? 0))
                      .getWithComa
                  )}
                </Td>
                <Td className="text-right">
                  {
                    RupiahConvert(String(parseFloat(val.hargaSatuanPPA ?? 0)))
                      .getWithComa
                  }
                </Td>
                <Td className="text-right">
                  {
                    RupiahConvert(
                      String(
                        parseFloat(val.hargaSatuanPPA ?? 0) *
                          parseFloat(val.qtyPPA ?? 0)
                      )
                    ).getWithComa
                  }
                </Td>
                {type !== "read" && (
                  <>
                    <Td className="text-right">
                      {
                        RupiahConvert(
                          String(parseFloat(val.hargaSatuanRealisasi ?? 0))
                        ).getWithComa
                      }
                    </Td>
                    <Td className="text-right">
                      {
                        RupiahConvert(
                          String(
                            parseFloat(val.hargaSatuanPPA ?? 0) *
                              parseFloat(val.qtyPPA ?? 0) -
                              parseFloat(val.hargaSatuanRealisasi ?? 0)
                          )
                        ).getWithComa
                      }
                    </Td>
                  </>
                )}
                <Td className="text-right">
                  {type !== "read" ? (
                    <NumberFormat
                      placeholder="Masukan nominal terealisasi"
                      value={val.nominalRealisasi}
                      decimalSeparator=","
                      onChange={(e) =>
                        setValues({
                          ...values,
                          subKegiatan: {
                            ...values.subKegiatan,
                            [checkKeyTab]: values?.subKegiatan?.[
                              checkKeyTab
                            ]?.map((res, i) =>
                              index === i
                                ? { ...res, nominalRealisasi: e }
                                : res
                            ),
                          },
                        })
                      }
                    />
                  ) : (
                    RupiahConvert(
                      parseFloat(val.nominalRealisasi ?? 0).toString()
                    ).getWithComa
                  )}
                </Td>
                {/* {type !== "read" && ( */}
                <TdFixed>
                  <ButtonGroup>
                    <ActionButton
                      text={
                        type === "read" ? (
                          <AiOutlineEye />
                        ) : (
                          <AiOutlineDownload />
                        )
                      }
                      onClick={() =>
                        setModalUploadConfig({
                          show: true,
                          index: index,
                          data: val,
                        })
                      }
                      size="sm"
                    />
                  </ButtonGroup>
                </TdFixed>
                {/* )} */}
                <TdFixed>
                  <ButtonGroup>
                    {val.isPosting ? (
                      <ActionButton
                        variant="success"
                        text={<AiOutlineCheck />}
                        size="sm"
                      />
                    ) : (
                      <ButtonCreate
                        noText
                        icon
                        onClick={() => {
                          setModalPostingConfig({
                            show: true,
                            index: index,
                            data: val,
                          });
                          setValues({
                            ...values,
                            postingRealisasiPPA: {
                              ...values?.postingRealisasiPPA,
                              idRealisasiPPA: val?.idRealisasiPPAItem,
                              jumlah: val?.nominalRealisasi,
                            },
                          });
                        }}
                        size="sm"
                      />
                    )}
                  </ButtonGroup>
                </TdFixed>
              </Tr>
            ))}
        </TBody>
      </Table>
      <ModalUpload
        setModalConfig={setModalUploadConfig}
        modalConfig={modalUploadConfig}
        tabKey={checkKeyTab}
        readOnly={type === "read" ? true : false}
      />
      <ModalPostingRealisasiPPA
        setModalConfig={setModalPostingConfig}
        modalConfig={modalPostingConfig}
        tabType={tabType}
        readOnly={type === "read" ? true : false}
        dropdownCOA={dropdown}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
      />
    </>
  );
};
