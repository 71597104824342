import Services from "services";

class LaporanAkutansiManajemen {
  getPage(params) {
    return Services.get("/laporan_akuntansi_manajemen/page", { params });
  }
  getDropdown(params) {
    return Services.get("laporan_akuntansi_manajemen/" + params);
  }
  export(params) {
    return Services.get("/laporan_akuntansi_manajemen/export", { params });
  }
}

export default new LaporanAkutansiManajemen();
