// Components
import { Table, Td, TdFixed, Th, ThFixed } from "components";
import { Card } from "react-bootstrap";
import { RupiahConvert } from "utilities";

const SummarySection = ({ summary, total }) => (
  <>
    <b>Summary Biaya</b>
    <Card>
      <Card.Body>
        <Table noHover>
          <thead>
            <tr>
              <ThFixed>No.</ThFixed>
              <Th>Grup BUASO</Th>
              <ThFixed>Total</ThFixed>
            </tr>
          </thead>
          <tbody>
            {summary.map((val, index) => (
              <>
                <tr>
                  <TdFixed>{index + 1}</TdFixed>
                  <Td>{val.buaso}</Td>
                  <TdFixed>
                    {
                      RupiahConvert(parseFloat(val.total ?? 0).toString())
                        .getWithComa
                    }
                  </TdFixed>
                </tr>
              </>
            ))}
            <tr className="font-weight-bold">
              <TdFixed colSpan={2} textRight>
                Total
              </TdFixed>
              <TdFixed textRight>
                {RupiahConvert(String(total)).getWithComa}
              </TdFixed>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  </>
);

export default SummarySection;
