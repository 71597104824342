import Services from "services";

class MutasiAntarGudangApi {
  getPage(params) {
    return Services.get("/hsr_bahan/page", { params });
  }
  getHSRData(params) {
    return Services.get("/hsr_bahan/hsr", { params });
  }
  save(data) {
    return Services.post("/hsr_bahan", data);
  }
  export(params) {
    return Services.get("/hsr_bahan/export", { params });
  }
}

export default new MutasiAntarGudangApi();
