import { createContext } from "react";
import { useQuery } from "react-query";
import { PostingRealisasiWorkOrderApi } from "api";

export const DropdownContext = createContext();

export const DropdownProvider = ({ children }) => {
  const STALE_DURATION_MINUTES = 4 * 60 * 1000; // 4 MINUTES

  const dropdownCOA = useQuery(
    ["dropdown", "coa"],
    () =>
      PostingRealisasiWorkOrderApi.getDropdownCOA().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.nomor_akun,
          label: `${val.nomor_akun} - ${val.nama_akun}`,
        }));

        return data;
      }),

    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownAset = useQuery(
    ["dropdown", "aset"],
    () =>
      PostingRealisasiWorkOrderApi.getDropdownAset().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item_aset,
          label: val.nama_item_aset,
        }));

        return data;
      }),

    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownFilter = useQuery(
    ["dropdown", "filterAset", children?.props?.buaso],
    () =>
      PostingRealisasiWorkOrderApi.getDropdownFilter({
        buaso: children?.props?.buaso,
      }).then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item,
          label: val.nama_item,
        }));

        return data;
      }),

    { staleTime: STALE_DURATION_MINUTES, enabled: !!children?.props?.buaso }
  );

  return (
    <DropdownContext.Provider
      value={{ dropdownAset, dropdownCOA, dropdownFilter }}
    >
      {children}
    </DropdownContext.Provider>
  );
};

export const withDropdownProvider = (Component) => (props) => {
  return (
    <DropdownProvider>
      <Component {...props} />
    </DropdownProvider>
  );
};
