/* Table Realisasi Biaya Tambah Page*/

import { useContext } from 'react';
import { IoCheckmarkOutline, IoAddOutline } from 'react-icons/io5';
import {
  CRUDLayout,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  Td,
  TdFixed,
  DataStatus,
  ReadButton,
  ActionButton,
} from 'components';
import { DateConvert, DecimalConvert, RupiahConvert } from 'utilities';
import { calcTotalSparepart, getTotal } from '../utils';
import { PostingRealisasiContext } from '../context';

export const TableRealisasiBiaya = ({ buaso, data = [], isPageLoading }) => {
  const { setModalFormPosting, dataHistory } = useContext(
    PostingRealisasiContext,
  );
  const isUpahSubkon = buaso === 'upah' || buaso === 'subkon';

  const buasoTitle = {
    bahan: 'Bahan',
    upah: 'Upah',
    alat_mesin: 'Alat & Mesin',
    subkon: 'Subkon',
    overhead: 'Overhead',
  };

  if (isPageLoading) {
    return <DataStatus loading text="Memuat data..." />;
  }

  const postedRealisasiWorkOrder = dataHistory[buaso]?.map(
    (item) => item?.[`id_realisasi_work_order_${buaso}`],
  );

  return (
    <>
      <div className="m-2">
        <small className="font-weight-bold " style={{ fontSize: '14px' }}>
          Realisasi {buasoTitle[buaso]} Work Order
        </small>

        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <Th width={150}>Informasi Realisasi Work Order</Th>
              <Th>Item {buasoTitle[buaso]}</Th>
              {isUpahSubkon && <Th width={140}>Vendor</Th>}
              <Th width={80}>Satuan</Th>
              <Th width={80}>Qty. Realisasi</Th>
              <Th width={80}>Harga Satuan</Th>
              <Th width={80}>
                {isUpahSubkon ? 'Nominal Realisasi' : 'Jumlah '}
              </Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {!!data?.length ? (
              data?.map((val, index) => {
                const isPosted = postedRealisasiWorkOrder?.includes(
                  val?.[`id_realisasi_work_order_${buaso}`],
                );

                const hargaSatuan =
                  val?.harga_satuan ?? val[`harga_satuan_${buaso}`] ?? 0;

                return (
                  <Tr key={index}>
                    <TdFixed className="text-center">{index + 1}</TdFixed>

                    <Td>
                      <div>
                        {val.tgl_realisasi_work_order
                          ? DateConvert(new Date(val.tgl_realisasi_work_order))
                              .defaultDMY
                          : '-'}
                      </div>
                      <div>{val.no_realisasi_work_order ?? '-'}</div>
                    </Td>

                    <Td>{val.nama_item ?? val.nama_item_aset ?? '-'}</Td>

                    {isUpahSubkon && <Td>{val.nama_vendor ?? '-'}</Td>}

                    <Td>{val.nama_satuan ?? '-'}</Td>

                    <Td>
                      {DecimalConvert(val[`qty_realisasi_${buaso}`])
                        .getWithComa ?? '-'}
                    </Td>

                    <Td className="text-right">
                      {
                        RupiahConvert(parseFloat(hargaSatuan).toString())
                          .getWithComa
                      }
                    </Td>

                    <Td className="text-right">
                      {
                        RupiahConvert(
                          getTotal({
                            qty: val[`qty_realisasi_${buaso}`],
                            harga: hargaSatuan,
                          }).toString(),
                        ).getWithComa
                      }
                    </Td>

                    <Td>
                      <ActionButton
                        size="sm"
                        className={
                          isPosted
                            ? 'bg-success border-success text-white'
                            : 'text-white'
                        }
                        onClick={
                          isPosted
                            ? null
                            : () =>
                                setModalFormPosting({
                                  buaso,
                                  data: val,
                                  show: true,
                                  isCreate: true,
                                })
                        }
                      >
                        {isPosted ? <IoCheckmarkOutline /> : <IoAddOutline />}
                      </ActionButton>
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td colSpan={isUpahSubkon ? 10 : 9}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
            )}
          </TBody>
        </CRUDLayout.Table>

        <small className="font-weight-bold " style={{ fontSize: '14px' }}>
          History Posting Realisasi {buasoTitle[buaso]} Work Order
        </small>

        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <Th width={150}>Informasi Posting Realisasi Work Order</Th>
              <Th>Item {buasoTitle[buaso]}</Th>
              {isUpahSubkon && <Th>Vendor</Th>}
              <Th width={100}>Jumlah</Th>
              <ThFixed>Akun COA</ThFixed>
              <Th>Keterangan Jurnal</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {!!dataHistory?.[buaso]?.length ? (
              dataHistory?.[buaso]?.map((val, index) => {
                const qty = parseFloat(val?.[`qty_realisasi_${buaso}`]);

                const hargaSatuan = parseFloat(
                  val?.harga_satuan ?? val?.[`harga_satuan_${buaso}`] ?? 0,
                );

                const totalSparepart =
                  calcTotalSparepart(val?.[`sparepart_${buaso}`], buaso) ?? 0;

                return (
                  <Tr key={index}>
                    <TdFixed className="text-center">{index + 1}</TdFixed>

                    <Td>
                      <div>
                        {val?.[`tgl_posting_realisasi_work_order_${buaso}`]
                          ? DateConvert(
                              new Date(
                                val?.[
                                  `tgl_posting_realisasi_work_order_${buaso}`
                                ],
                              ),
                            ).defaultDMY
                          : '-'}
                      </div>
                      <div>
                        {val?.[`no_posting_realisasi_work_order_${buaso}`] ??
                          '-'}
                      </div>
                    </Td>

                    <Td>{val.nama_item ?? val?.nama_item_aset ?? '-'}</Td>

                    {isUpahSubkon && <Td>{val.nama_vendor ?? '-'}</Td>}

                    <Td className="text-right">
                      {
                        RupiahConvert(
                          String(hargaSatuan * qty + totalSparepart ?? 0),
                        ).getWithComa
                      }
                    </Td>

                    <Td className="text-nowrap">
                      <span className="d-block">{`D: ${val.nomor_akun_debet} - ${val.nama_akun_debet}`}</span>
                      <span className="d-block">{`K: ${val.nomor_akun_kredit} - ${val.nama_akun_kredit}`}</span>
                    </Td>

                    <Td>{val.keterangan_jurnal ?? '-'}</Td>

                    <Td>
                      <ReadButton
                        size="sm"
                        onClick={() =>
                          setModalFormPosting({
                            buaso,
                            data: val,
                            show: true,
                            isCreate: false,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td colSpan={isUpahSubkon ? 10 : 9}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
            )}
          </TBody>
        </CRUDLayout.Table>
      </div>
    </>
  );
};
