import { InfoItemHorizontal } from "components";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DateConvert, DecimalConvert } from "utilities";

export const InfoComp = ({ data }) => {
  return (
    <Row>
      <Col lg="6">
        <InfoItemHorizontal
          label="Tgl. Produksi"
          text={
            data.tglProduksi
              ? DateConvert(new Date(data?.tglProduksi)).detail
              : "-"
          }
        />
        <InfoItemHorizontal label="No. Produksi" text={data?.noProduksi} />
        <InfoItemHorizontal label="Kode Item Produksi" text={data?.kodeItem} />
        <InfoItemHorizontal label="Item Produksi" text={data?.namaItem} />
      </Col>
      <Col lg="6">
        <InfoItemHorizontal
          label="Tgl. Realisasi Produksi"
          text={
            data.tglRealisasiProduksi
              ? DateConvert(new Date(data?.tglRealisasiProduksi)).detail
              : "-"
          }
        />
        <InfoItemHorizontal
          label="No. Realisasi Produksi"
          text={data?.noRealisasiProduksi}
        />
        <InfoItemHorizontal
          label="Qty. Produksi"
          text={`${DecimalConvert(parseFloat(data?.qtyProduksi)).getWithComa} ${
            data?.namaSatuan
          }`}
        />
        <InfoItemHorizontal label="Proyek" text={data?.namaProyek} />
      </Col>
    </Row>
  );
};
