import React, { useState, useEffect } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useQuery } from "react-query";
import { debounce } from "lodash";
import {
  CRUDLayout,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  Pagination,
  Alert,
  ApprovalStatusButton,
  BackButton,
} from "components";
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonUpdate,
  ButtonFilter,
  ButtonDetail,
  Button,
} from "components2";
import { tableNumber } from "utilities2";
import { ModalFilter } from "./__PermintaanProduksiAsetComps__";
import PermintaanProduksiAsetApi from "./__WIProduksiAsetApi__";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Formik } from "formik";
import { DateConvert } from "utilities";
import { getStatusPermintaan } from "./__PermintaanProduksiAsetUtils__";

export const RealisasiProduksiAsetWIPProduksiAsetList = ({
  setNavbarTitle,
}) => {
  const history = useHistory();
  const { state } = useLocation();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const [filter, setFilter] = useState({
    q: "",
    page: 1,
    per_page: 10,
    tgl_realisasi_produksi_aset_mulai: undefined,
    tgl_realisasi_produksi_aset_selesai: undefined,
    tgl_produksi_aset_mulai: undefined,
    tgl_produksi_aset_selesai: undefined,
    active: false,
  });

  const [modalFilter, setModalFilter] = useState(false);

  const getRealisasiProduksiAset = useQuery(
    ["permintaan_aset", "list", filter],
    () => PermintaanProduksiAsetApi.getListRealisasiProduksiAvailable(filter)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setFilter({
      ...filter,
      q: searchKey,
    });
    setAlertConfig({
      show: searchKey ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  const onPaginationChange = ({ selected }) =>
    setFilter({ ...filter, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setFilter({ ...filter, page: 1, per_page: e.target.value });

  const checkAlert = () => {
    if (state) {
      if (state.alert) {
        setAlertConfig({
          show: state?.alert?.show,
          text: state?.alert?.text,
          variant: state?.alert?.variant,
        });
      }
    }
  };

  // FORM VALUES FILTER
  const formInitialValuesFilter = {
    tgl_realisasi_produksi_aset_mulai:
      filter?.tgl_realisasi_produksi_aset_mulai,
    tgl_realisasi_produksi_aset_selesai:
      filter?.tgl_realisasi_produksi_aset_selesai,
    tgl_produksi_aset_mulai: filter?.tgl_produksi_aset_mulai,
    tgl_produksi_aset_selesai: filter?.tgl_produksi_aset_selesai,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  useEffect(() => {
    setNavbarTitle("WIP Produksi Aset");
    checkAlert();
  }, []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() => history.push("/transaksi/wip-produksi-aset")}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {getRealisasiProduksiAset.isFetching ||
      getRealisasiProduksiAset.isError ? (
        <DataStatus
          loading={getRealisasiProduksiAset.isFetching}
          text={
            getRealisasiProduksiAset.isFetching
              ? "Memuat . . ."
              : "Data gagal dimuat"
          }
        />
      ) : (
        <>
          <Alert
            show={alertConfig?.show}
            text={alertConfig?.text}
            variant={alertConfig?.variant}
            showCloseButton
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Realisasi Produksi Aset Yang Siap Diminta
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Informasi Realisasi Produksi Aset</ThFixed>
                <ThFixed>Informasi Produksi Aset</ThFixed>
                <Th>Item Aset Yang Diproduksi</Th>
                <ThFixed>Aksi</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {getRealisasiProduksiAset?.data?.data?.length > 0 ? (
                getRealisasiProduksiAset?.data?.data?.map((e, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: filter.page,
                        dataLength: filter.per_page,
                        index,
                      })}
                    </TdFixed>
                    <Td>
                      <div>
                        {
                          DateConvert(new Date(e?.tgl_realisasi_produksi_aset))
                            .defaultDMY
                        }
                      </div>
                      <div>{e.no_realisasi_produksi_aset ?? "-"}</div>
                    </Td>
                    <Td>
                      <div>
                        {DateConvert(new Date(e?.tgl_produksi_aset)).defaultDMY}
                      </div>
                      <div>{e.no_produksi_aset ?? "-"}</div>
                    </Td>
                    <Td>{e?.nama_item_aset ?? "-"}</Td>
                    <Td>
                      <ButtonCreate
                        icon
                        noText
                        onClick={() =>
                          history.push(
                            "/transaksi/wip-produksi-aset/tambah/" +
                              e?.id_realisasi_produksi_aset
                          )
                        }
                      />
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={filter?.per_page}
            dataNumber={filter?.page * filter?.per_page - filter?.per_page + 1}
            dataPage={
              filter?.dataCount < filter?.per_page
                ? filter?.dataCount
                : filter?.page * filter?.per_page
            }
            dataCount={getRealisasiProduksiAset?.data?.data_count}
            currentPage={filter?.page}
            totalPage={getRealisasiProduksiAset?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter toggle={onFilterButtonClickkHandler} isPreForm />
        </Formik>
      )}
    </CRUDLayout>
  );
};
