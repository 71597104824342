// Components
import {
  DatePicker,
  InfoItemVertical,
  Input,
  NumberFormat,
  SelectSearch,
  TextArea,
} from "components";
import { Col, Row } from "react-bootstrap";
import { DateConvert, DecimalConvert } from "utilities";

// Form
import { useFormikContext } from "formik";
import { TransferOverheadJobMixApi } from "api";

const FormSection = ({ readOnly, buaso, dropdown, data }) => {
  // Variables
  const { values, setFieldValue, handleChange, errors, touched } =
    useFormikContext();

  const getDataNomor = (value) => {
    TransferOverheadJobMixApi.no_baru(value).then((res) => {
      setFieldValue("no_transfer_produksi_bahan", res.data.data);
    });
  };

  if (readOnly) {
    return (
      <Row>
        <Col md={6}>
          <InfoItemVertical
            className="text-capitalize"
            label={`Tgl. Transfer Overhead Produksi`}
            text={
              data[`tgl_transfer_produksi_${buaso}`]
                ? DateConvert(new Date(data[`tgl_transfer_produksi_${buaso}`]))
                    .detail
                : "-"
            }
          />
        </Col>

        <Col md={6}>
          <InfoItemVertical
            className="text-capitalize"
            label={`No. Transfer Overhead Produksi`}
            text={data[`no_transfer_produksi_${buaso}`] ?? "-"}
          />
        </Col>

        <Col md={12}>
          <InfoItemVertical
            label="Qty. Transfer"
            text={`${DecimalConvert(data.qty_transfer ?? 0).getWithComa} ${
              data.kode_satuan ?? ""
            }`}
          />
        </Col>

        <Col md={12}>
          <InfoItemVertical
            label="Keterangan Transfer"
            text={data.keterangan_transfer ?? "-"}
          />
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col md={6}>
          <DatePicker
            label="Tgl. Transfer Overhead Produksi"
            selected={
              values.tgl_transfer_produksi_overhead
                ? new Date(values.tgl_transfer_produksi_overhead)
                : ""
            }
            placeholderText="Pilih Tgl. Transfer Overhead Produksi"
            onChange={(date) => {
              const tanggal = DateConvert(new Date(date)).default;

              setFieldValue("tgl_transfer_produksi_overhead", tanggal);
              getDataNomor(tanggal);
            }}
            error={
              Boolean(errors.tgl_transfer_produksi_overhead) &&
              touched.tgl_transfer_produksi_overhead
            }
            errorText={
              Boolean(errors.tgl_transfer_produksi_overhead) &&
              touched.tgl_transfer_produksi_overhead &&
              errors.tgl_transfer_produksi_overhead
            }
          />
        </Col>

        <Col md={6}>
          <Input
            label="No. Transfer Overhead Produksi"
            placeholder="No. Transfer Overhead Produksi"
            value={values.no_transfer_produksi_overhead}
            readOnly
          />
        </Col>

        <Col md={4}>
          {/* <Input
            label="Qty. Transfer"
            type="text"
            name="qty_transfer"
            placeholder="Qty. Transfer"
            value={values.qty_transfer}
            onChange={handleChange}
            error={Boolean(errors.qty_transfer) && touched.qty_transfer}
            errorText={
              Boolean(errors.qty_transfer) &&
              touched.qty_transfer &&
              errors.qty_transfer
            }
          /> */}
          <NumberFormat
            label="Qty. Transfer"
            value={values.qty_transfer}
            onChange={(e) => setFieldValue("qty_transfer", e)}
            error={Boolean(errors.qty_transfer) && touched.qty_transfer}
            errorText={
              Boolean(errors.qty_transfer) &&
              touched.qty_transfer &&
              errors.qty_transfer
            }
          />
        </Col>

        <Col md={12}>
          <TextArea
            label="Keterangan Transfer"
            name="keterangan_transfer"
            rows={4}
            placeholder="Keterangan Transfer"
            value={values.keterangan_transfer}
            onChange={handleChange}
            error={
              Boolean(errors.keterangan_transfer) && touched.keterangan_transfer
            }
            errorText={
              Boolean(errors.keterangan_transfer) &&
              touched.keterangan_transfer &&
              errors.keterangan_transfer
            }
          />
        </Col>
      </Row>
    );
  }
};

export default FormSection;
