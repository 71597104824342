/*eslint-disable*/

// Dashboard
import Dashboard from "pages/Dashboard";
import {
  LaporanProyeksiLabaRugiList,
  RealisasiProduksiList,
  DetailRealisasiProduksi,
  ListLaporanAkuntansiManajemen,
  LaporanAkutansiManajemen,
  LaporanRekapitulasiPendapatanList,
  LaporanRekapitulasiHasilProduksiList,
} from "pages/Laporan";

// MASTER
import {
  ItemOverhead,
  KelompokOverhead,
  HargaPerkiraanSendiri,

  // Analisa Barang Jadi
  RegistrasiAnalisaBarangJadi,
  ListJobMixDesign,
  TambahAnalisaBarangJadi,
  UbahAnalisaBarangJadi,
  DetailAnalisaBarangJadi,
} from "pages/Master";
import MappingItemOverhead from "pages/Master/MappingItemOverhead";

// Transaksi
import {
  // Transfer Produksi Overhead
  TransferProduksiOverhead,
  DataTransferProduksiOverhead,

  // HPP
  ListHPP,
  TambahHPP,
  DetailHPP,
  ListProduksi,
  DetailProduksi,

  // Transfer Overhead Job Mix
  TransferOverheadJobMix,
  ListPermintaanOverheadJobMix,
  TambahTransferOverheadJobMix,

  // Harga Satuan Rata-Rata
  HargaSatuanRataRataPenyusutan,
  HargaSatuanRataRataPakai,
  HargaSatuanRataRataOverhead,
  HargaSatuanRataRataBahan,

  // Mapping COA
  ListMappingCOA,
  CRUDMappingCOA,

  // BDP Produksi
  BDPProduksiList,
  BDPProduksiRealisasiList,
  BDPProduksiDetail,
  BDPProduksiCreate,

  //RAE
  RAE,
  PeluangRAE,
  TambahRAE,
  UbahRAE,
  DetailRAE,
  HargaSatuanRataRataBarangjadi,
  ListStockOpname,
  ListPostingStockOpname,
  TambahPostingStockOpname,

  // Petty Cash
  PostingPettyCashList,
  PostingPettyCashTambah,
  PostingRealisasiPettyCashList,
  ListPostingRealisasiPPA,
  ListRealisasiPPAPostingRealisasiPPA,
  TambahPostingRealisasiPPA,
  MonitoringOrder,

  // Transfer Overhead Work Order
  ListTransferOverheadWorkOrder,
  ListPermintaanOverheadWorkOrder,
  TambahTransferOverheadWorkOrder,

  // Posting Realisasi Work Order
  ListPostingRealisasiWorkOrder,
  ListPreformPostingWorkOrder,
  TambahPostingRealisasiWorkOrder,
  TransferOverheadProduksiAsetList,
  WIPProduksiAsetList,
  RealisasiProduksiAsetWIPProduksiAsetList,
  WIPProduksiAsetCreate,
  NilaiPerolehanProduksiAsetList,
  NilaiPerolehanRealisasiProduksiAset,
  NilaiPerolehanProduksiAsetCreate,
  NilaiPerolehanProduksiAsetDetail,
} from "pages/Transaksi";

/* 
  MENU REQUIREMENTS
    > EXACT = OPTIONAL (TRUE/FALSE)
    > PATH  = REQUIRED
    > PAGE  = REQUIRED
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
*/

export default [
  // Dashboard
  {
    exact: true,
    path: "/",
    page: Dashboard,
    hak: ["SUPA", "COC"],
  },

  // MASTER
  {
    exact: true,
    path: "/master/item-overhead",
    page: ItemOverhead,
    hak: ["SUPA", "COC_REG_OVH"],
  },
  {
    exact: true,
    path: "/master/kelompok-overhead",
    page: KelompokOverhead,
    hak: ["SUPA", "COC_MAS_KLO"],
  },
  {
    text: "Harga Perkiraan Sendiri",
    path: "/master/harga-perkiraan-sendiri",
    page: HargaPerkiraanSendiri,
    hak: ["SUPA", "COC_MAS_HPS"],
  },
  {
    exact: true,
    path: "/cost-control/transaksi/harga-satuan-rata-rata-bahan",
    page: HargaSatuanRataRataBahan,
    hak: ["SUPA", "COC_TRN_HSB"],
  },

  // Mapping COA Item Overhead

  {
    exact: true,
    path: "/master/mapping-item-overhead",
    page: MappingItemOverhead,
    hak: ["SUPA", "COC_REG_OVH"],
  },

  // Analisa Barang Jadi
  {
    exact: true,
    path: "/master/analisa-barang-jadi",
    page: RegistrasiAnalisaBarangJadi,
    hak: ["SUPA", "COC_MAS_ABJ"],
  },
  {
    exact: true,
    path: "/master/analisa-barang-jadi/list-job-mix-design",
    page: ListJobMixDesign,
    hak: ["SUPA", "COC_MAS_ABJ"],
  },
  {
    exact: true,
    path: "/master/analisa-barang-jadi/tambah/:id_jobmix_design",
    page: TambahAnalisaBarangJadi,
    hak: ["SUPA", "COC_MAS_ABJ"],
  },
  {
    exact: true,
    path: "/master/analisa-barang-jadi/ubah/:id_analisa_barang_jadi",
    page: UbahAnalisaBarangJadi,
    hak: ["SUPA", "COC_MAS_ABJ"],
  },
  {
    exact: true,
    path: "/master/analisa-barang-jadi/detail/:id_analisa_barang_jadi",
    page: DetailAnalisaBarangJadi,
    hak: ["SUPA", "COC_MAS_ABJ"],
  },

  // TRANSAKSI
  // // Transfer Produksi Overhead
  // {
  //   exact: true,
  //   path: "/transaksi/transfer-produksi-overhead",
  //   page: TransferProduksiOverhead,
  //   hak: ["SUPA", "COC_TRN_TPO"],
  // },
  // {
  //   exact: true,
  //   path: "/transaksi/transfer-produksi-overhead/data",
  //   page: DataTransferProduksiOverhead,
  //   hak: ["SUPA", "COC_TRN_TPO"],
  // },

  // Transfer Overhead Job Mix
  {
    exact: true,
    path: "/transaksi/transfer-overhead-job-mix",
    page: TransferOverheadJobMix,
    hak: ["SUPA", "COC_TRN_TJO"],
  },
  {
    exact: true,
    path: "/transaksi/transfer-overhead-job-mix/list-permintaan-overhead",
    page: ListPermintaanOverheadJobMix,
    hak: ["SUPA", "COC_TRN_TJO"],
  },
  {
    exact: true,
    path: "/transaksi/transfer-overhead-job-mix/tambah/:id",
    page: TambahTransferOverheadJobMix,
    hak: ["SUPA", "COC_TRN_TJO"],
  },

  // BDP PRODUKSI
  {
    exact: true,
    path: "/transaksi/bdp",
    page: BDPProduksiList,
    hak: ["SUPA", "COC_TRN_BDP"],
  },
  {
    exact: true,
    path: "/transaksi/bdp/realisasi",
    page: BDPProduksiRealisasiList,
    hak: ["SUPA", "COC_TRN_BDP"],
  },
  {
    exact: true,
    path: "/transaksi/bdp/detail/:id",
    page: BDPProduksiDetail,
    hak: ["SUPA", "COC_TRN_BDP"],
  },
  {
    exact: true,
    path: "/transaksi/bdp/tambah/:id",
    page: BDPProduksiCreate,
    hak: ["SUPA", "COC_TRN_BDP"],
  },
  // HPP
  {
    exact: true,
    path: "/transaksi/hpp",
    page: ListHPP,
    hak: ["SUPA", "COC_TRN_HPP"],
  },
  {
    exact: true,
    path: "/transaksi/hpp/list-produksi",
    page: ListProduksi,
    hak: ["SUPA", "COC_TRN_HPP"],
  },
  {
    exact: true,
    path: "/transaksi/hpp/list-produksi/detail/:id",
    page: DetailProduksi,
    hak: ["SUPA", "COC_TRN_HPP"],
  },
  {
    exact: true,
    path: "/transaksi/hpp/tambah/:id",
    page: TambahHPP,
    hak: ["SUPA", "COC_TRN_HPP"],
  },
  {
    exact: true,
    path: "/transaksi/hpp/detail/:id",
    page: DetailHPP,
    hak: ["SUPA", "COC_TRN_HPP"],
  },
  // Harga Satuan Rata-Rata
  {
    exact: true,
    path: "/transaksi/harga-satuan-rata-rata-penyusutan",
    page: HargaSatuanRataRataPenyusutan,
    hak: ["SUPA", "COC_TRN_HSP"],
  },
  // {
  //   exact: true,
  //   path: "/transaksi/harga-satuan-rata-rata-pakai",
  //   page: HargaSatuanRataRataPakai,
  //   hak: ["SUPA", "COC_TRN_HSR"],
  // },
  {
    exact: true,
    path: "/transaksi/harga-satuan-rata-rata-overhead",
    page: HargaSatuanRataRataOverhead,
    hak: ["SUPA", "COC_TRN_HSO"],
  },
  // Harga Satuan Barang Jadi
  {
    exact: true,
    path: "/transaksi/harga-satuan-rata-rata-barang-jadi",
    page: HargaSatuanRataRataBarangjadi,
    hak: ["SUPA", "COC_TRN_HSJ"],
  },
  {
    exact: true,
    path: "/transaksi/mapping-kelompok-item-coa",
    page: ListMappingCOA,
    hak: ["SUPA", "COC_TRN_KTC"],
  },
  {
    exact: true,
    path: "/transaksi/mapping-kelompok-item-coa/ubah",
    page: CRUDMappingCOA,
    hak: ["SUPA", "COC_TRN_KTC"],
  },
  // RAE
  {
    exact: true,
    path: "/transaksi/unit-cost",
    page: RAE,
    hak: ["SUPA", "COC_TRN_UNC"],
  },
  {
    exact: true,
    path: "/transaksi/unit-cost/list-peluang",
    page: PeluangRAE,
    hak: ["SUPA", "COC_TRN_UNC"],
  },
  {
    exact: true,
    path: "/transaksi/unit-cost/tambah/:id_peluang",
    page: TambahRAE,
    hak: ["SUPA", "COC_TRN_UNC"],
  },
  {
    exact: true,
    path: "/transaksi/unit-cost/ubah/:id_rae",
    page: UbahRAE,
    hak: ["SUPA", "COC_TRN_UNC"],
  },
  {
    exact: true,
    path: "/transaksi/unit-cost/detail/:id_rae",
    page: DetailRAE,
    hak: ["SUPA", "COC_TRN_UNC"],
  },

  // Posting Stock Opname
  {
    exact: true,
    path: "/transaksi/posting-stock-opname",
    page: ListPostingStockOpname,
    hak: ["SUPA", "COC_TRN_PSO"],
  },
  {
    exact: true,
    path: "/transaksi/posting-stock-opname/stock-opname",
    page: ListStockOpname,
    hak: ["SUPA", "COC_TRN_PSO"],
  },

  // Laporan Proyeksi Laba Rugi
  {
    exact: true,
    path: "/laporan/proyeksi-laba-rugi",
    page: LaporanProyeksiLabaRugiList,
    hak: ["SUPA", "COC", "COC_TRN_UNC"],
  },

  {
    exact: true,
    path: "/laporan/proyeksi-laba-rugi/realisasi-produksi/:id_jenis/:id_unit_produksi",
    page: RealisasiProduksiList,
    hak: ["SUPA", "COC", "COC_TRN_UNC"],
  },

  {
    exact: true,
    path: "/laporan/proyeksi-laba-rugi/detail/:id",
    page: DetailRealisasiProduksi,
    hak: ["SUPA", "COC", "COC_TRN_UNC"],
  },

  // Posting Petty Cash
  {
    exact: true,
    path: "/transaksi/posting-petty-cash",
    page: PostingPettyCashList,
    hak: ["SUPA", "COC_TRN_PPC"],
  },
  {
    exact: true,
    path: "/transaksi/posting-petty-cash/realisasi-petty-cash",
    page: PostingRealisasiPettyCashList,
    hak: ["SUPA", "COC_TRN_PPC"],
  },
  {
    exact: true,
    path: "/transaksi/posting-petty-cash/tambah/:id",
    page: PostingPettyCashTambah,
    hak: ["SUPA", "COC_TRN_PPC"],
  },

  // Monitoring Order
  {
    exact: true,
    path: "/transaksi/monitoring-order",
    page: MonitoringOrder,
    hak: ["SUPA", "COC_TRN_MTO"],
  },

  // Posting Realisasi Anggaran
  {
    exact: true,
    path: "/transaksi/posting-realisasi-ppa",
    page: ListPostingRealisasiPPA,
    hak: ["SUPA", "COC_TRN_PRPPA"],
  },
  {
    exact: true,
    path: "/transaksi/posting-realisasi-ppa/realisasi-ppa",
    page: ListRealisasiPPAPostingRealisasiPPA,
    hak: ["SUPA", "COC_TRN_PRPPA"],
  },
  {
    exact: true,
    path: "/transaksi/posting-realisasi-ppa/tambah/:id",
    page: TambahPostingRealisasiPPA,
    hak: ["SUPA", "COC_TRN_PRPPA"],
  },

  // Transfer Overhead Work Order
  {
    exact: true,
    path: "/transaksi/transfer-overhead-work-order",
    page: ListTransferOverheadWorkOrder,
    hak: ["SUPA", "COC_TRN_TWO"],
  },
  {
    exact: true,
    path: "/transaksi/transfer-overhead-work-order/list-permintaan-work-order",
    page: ListPermintaanOverheadWorkOrder,
    hak: ["SUPA", "COC_TRN_TWO"],
  },
  {
    exact: true,
    path: "/transaksi/transfer-overhead-work-order/tambah/:id_work_order",
    page: TambahTransferOverheadWorkOrder,
    hak: ["SUPA", "COC_TRN_TWO"],
  },

  // Posting Realisasi Work Order
  {
    exact: true,
    path: "/transaksi/posting-realisasi-work-order",
    page: ListPostingRealisasiWorkOrder,
    hak: ["SUPA", "COC_TRN_PRW"],
  },
  {
    exact: true,
    path: "/transaksi/posting-realisasi-work-order/realisasi-work-order",
    page: ListPreformPostingWorkOrder,
    hak: ["SUPA", "COC_TRN_PRW"],
  },
  {
    exact: true,
    path: "/transaksi/posting-realisasi-work-order/tambah/:id_realisasi_work_order",
    page: TambahPostingRealisasiWorkOrder,
    hak: ["SUPA", "COC_TRN_PRW"],
  },

  // Laporan Akutansi Manajemen
  {
    exact: true,
    path: "/laporan/akutansi-manajemen",
    page: LaporanAkutansiManajemen,
    hak: ["SUPA", "COC", "COC_RPT_LAM"],
  },

  // Transfer Overhead Produksi Aset
  {
    exact: true,
    path: "/transaksi/transfer-overhead-produksi-aset",
    page: TransferOverheadProduksiAsetList,
    hak: ["SUPA", "COC", "COC_TRN_TOPA"],
  },

  // WIP Produksi Aset
  {
    exact: true,
    path: "/transaksi/wip-produksi-aset",
    page: WIPProduksiAsetList,
    hak: ["SUPA", "COC", "COC_TRN_WIP"],
  },
  {
    exact: true,
    path: "/transaksi/wip-produksi-aset/realisasi-produksi-aset",
    page: RealisasiProduksiAsetWIPProduksiAsetList,
    hak: ["SUPA", "COC", "COC_TRN_WIP"],
  },
  {
    exact: true,
    path: "/transaksi/wip-produksi-aset/tambah/:id",
    page: WIPProduksiAsetCreate,
    hak: ["SUPA", "COC", "COC_TRN_WIP"],
  },

  // Nilai Perolehan Produksi Aset
  {
    exact: true,
    path: "/transaksi/nilai-perolehan-produksi-aset",
    page: NilaiPerolehanProduksiAsetList,
    hak: ["SUPA", "COC", "COC_TRN_NPA"],
  },
  {
    exact: true,
    path: "/transaksi/nilai-perolehan-produksi-aset/realisasi-produksi-aset",
    page: NilaiPerolehanRealisasiProduksiAset,
    hak: ["SUPA", "COC", "COC_TRN_NPA"],
  },
  {
    exact: true,
    path: "/transaksi/nilai-perolehan-produksi-aset/tambah/:id",
    page: NilaiPerolehanProduksiAsetCreate,
    hak: ["SUPA", "COC", "COC_TRN_NPA"],
  },
  {
    exact: true,
    path: "/transaksi/nilai-perolehan-produksi-aset/detail/:id",
    page: NilaiPerolehanProduksiAsetDetail,
    hak: ["SUPA", "COC", "COC_TRN_NPA"],
  },

  // Laporan Rekapitulasi Pendapatan
  {
    exact: true,
    path: "/laporan/rekapitulasi-pendapatan",
    page: LaporanRekapitulasiPendapatanList,
    hak: ["SUPA", "COC", "COC_RPT_LPD"],
  },

  // Laporan Rekapitulasi Hasil Produksi
  {
    exact: true,
    path: "/laporan/rekapitulasi-hasil-produksi",
    page: LaporanRekapitulasiHasilProduksiList,
    hak: ["SUPA", "COC", "COC_RPT_LPN"],
  },
];
