import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";
import _ from "lodash";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
  ApprovalStatusButton,
  FilterButton,
  BackButton,
} from "components";
import {
  DateConvert,
  DecimalConvert,
  RupiahConvert,
  TableNumber,
} from "utilities";
import { PostingStockOpnameApi } from "api";
import { ModalFilter } from "./Section";
import { IoAddOutline } from "react-icons/io5";
import { ModalSection } from "../Section";

export const ListStockOpname = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataStockOpname, setDataStockOpname] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    type: "add",
    show: false,
    idStockOpnameDetail: null,
  });
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.surat_jalan?.filter?.active,
      tgl_stock_opname_master_mulai:
        location?.state?.surat_jalan?.filter?.tgl_stock_opname_master_mulai,
      tgl_stock_opname_master_selesai:
        location?.state?.surat_jalan?.filter?.tgl_stock_opname_master_selesai,
      tgl_stock_fisik_master_mulai:
        location?.state?.surat_jalan?.filter?.tgl_stock_fisik_master_mulai,
      tgl_stock_fisik_master_selesai:
        location?.state?.surat_jalan?.filter?.tgl_stock_fisik_master_selesai,
      id_unit_produksi: location?.state?.surat_jalan?.filter?.id_unit_produksi,
      id_gudang: location?.state?.surat_jalan?.filter?.id_gudang,
      is_opname: location?.state?.surat_jalan?.filter?.is_opname,
    },
    pagination: {
      page: location?.state?.surat_jalan?.filter?.page ?? "1",
      dataLength: location?.state?.surat_jalan?.filter?.dataLength ?? "10",
      totalPage: location?.state?.surat_jalan?.filter?.totalPage ?? "1",
      dataCount: location?.state?.surat_jalan?.filter?.dataCount ?? "0",
    },
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PostingStockOpnameApi.pageStockOpname({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_stock_opname_master_mulai:
        dataFilter?.filter?.tgl_stock_opname_master_mulai,
      tgl_stock_opname_master_selesai:
        dataFilter?.filter?.tgl_stock_opname_master_selesai,
      tgl_stock_fisik_master_mulai:
        dataFilter?.filter?.tgl_stock_fisik_master_mulai,
      tgl_stock_fisik_master_selesai:
        dataFilter?.filter?.tgl_stock_fisik_master_selesai,
      id_unit_produksi: dataFilter?.filter?.id_unit_produksi,
      id_gudang: dataFilter?.filter?.id_gudang,
      is_opname: dataFilter?.filter?.is_opname,
    })
      .then((data) => {
        setDataStockOpname(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      },
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    setNavbarTitle("Posting Stock Opname");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_stock_opname_master_mulai,
    dataFilter?.filter?.tgl_stock_opname_master_selesai,
    dataFilter?.filter?.tgl_stock_fisik_master_mulai,
    dataFilter?.filter?.tgl_stock_fisik_master_selesai,
    dataFilter?.filter?.id_unit_produksi,
    dataFilter?.filter?.id_gudang,
    dataFilter?.filter?.is_opname,
    dataFilter?.filter?.status_approval,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Stock Opname Yang Siap Diposting</b>
        </span>
        <Table>
          <thead>
            <tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Stock Opname</ThFixed>
              <ThFixed>Informasi Stock Fisik</ThFixed>
              <Th>Item Barang</Th>
              <Th>Qty. Adjustment</Th>
              {/* <Th>Jumlah</Th> */}
              <Th>Gudang</Th>
              <Th>Unit Produksi</Th>
              <ThFixed>Aksi</ThFixed>
            </tr>
          </thead>
          <tbody>
            {dataStockOpname.map((val, index) => (
              <tr key={index}>
                <TdFixed>
                  {TableNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div className="text-left">
                    {val.tgl_stock_opname_master
                      ? DateConvert(new Date(val.tgl_stock_opname_master))
                          .defaultDMY
                      : "-"}
                  </div>
                  <div className="text-left">
                    {val.no_stock_opname_master ?? "-"}
                  </div>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">
                    {val.tgl_stock_fisik_master
                      ? DateConvert(new Date(val.tgl_stock_fisik_master))
                          .defaultDMY
                      : "-"}
                  </div>
                  <div className="text-left">
                    {val.no_stock_fisik_master ?? "-"}
                  </div>
                </TdFixed>
                <Td>{val?.nama_item ?? "-"}</Td>
                <Td>
                  {
                    DecimalConvert(parseFloat(val?.qty_adjustment ?? 0))
                      .getWithComa
                  }
                </Td>
                {/* <Td>
                  {parseFloat(val.harga_adjustment) < 0
                    ? "(" +
                      RupiahConvert(
                        parseFloat(
                          parseFloat(val.harga_adjustment) ?? 0
                        ).toString()
                      )
                        .getWithComa.split("")
                        .map((val) => (val[0] === "-" ? "" : val))
                        .join("") +
                      ")"
                    : RupiahConvert(
                        parseFloat(
                          parseFloat(val.harga_adjustment) ?? 0
                        ).toString()
                      ).getWithComa}
                </Td> */}
                <Td>{val.nama_gudang ?? "-"}</Td>
                <Td>{val.nama_unit_produksi ?? "-"}</Td>
                <TdFixed>
                  <ActionButton
                    size="sm"
                    onClick={() =>
                      setModalConfig({
                        ...modalConfig,
                        show: true,
                        idStockOpnameDetail: val?.id_stock_opname_detail,
                      })
                    }
                    text={<IoAddOutline />}
                  />
                </TdFixed>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );

    if (!dataStockOpname || dataStockOpname.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() =>
              history.push("/transaksi/posting-stock-opname", {
                ...location?.state,
                surat_jalan: dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {modalConfig.show && (
        <ModalSection
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          setAlertConfig={setAlertConfig}
          alertConfig={alertConfig}
          getInitialData={getInitialData}
        />
      )}
    </CRUDLayout>
  );
};
