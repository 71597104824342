/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState, useContext } from "react";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, CreateButton, CRUDLayout, InputSearch } from "components";
import { PostingRealisasiWorkOrderApi } from "api";
import {
  TablePostingRealisasi,
  ModalFormUpahSubkon,
  ModalFormBahanAlatOverhead,
} from "./components";
import {
  withPostingRealisasiProvider,
  PostingRealisasiContext,
} from "./context/PostingRealisasiWOContext";

export const ListPostingRealisasiWorkOrder = withPostingRealisasiProvider(
  ({ setNavbarTitle }) => {
    const history = useHistory();
    const location = useLocation();
    const { setModalFormPosting, modalFormPosting } = useContext(
      PostingRealisasiContext
    );

    const [modalFilterBahan, setModalFilterBahan] = useState(false);
    const [modalFilterUpah, setModalFilterUpah] = useState(false);
    const [modalFilterAlatMesin, setModalFilterAlatMesin] = useState(false);
    const [modalFilterSubkon, setModalFilterSubkon] = useState(false);
    const [modalFilterOverhead, setModalFilterOverhead] = useState(false);

    const [dataFilter, setDataFilter] = useState({
      bahan: {
        active: false,
        tgl_realisasi_work_order_mulai: undefined,
        tgl_realisasi_work_order_selesai: undefined,
        tgl_posting_realisasi_work_order_mulai: undefined,
        tgl_posting_realisasi_work_order_selesai: undefined,
        id_item: undefined,
      },
      upah: {
        active: false,
        tgl_realisasi_work_order_mulai: undefined,
        tgl_realisasi_work_order_selesai: undefined,
        tgl_posting_realisasi_work_order_mulai: undefined,
        tgl_posting_realisasi_work_order_selesai: undefined,
        id_item: undefined,
      },
      alat_mesin: {
        active: false,
        tgl_realisasi_work_order_mulai: undefined,
        tgl_realisasi_work_order_selesai: undefined,
        tgl_posting_realisasi_work_order_mulai: undefined,
        tgl_posting_realisasi_work_order_selesai: undefined,
        id_item: undefined,
      },
      subkon: {
        active: false,
        tgl_realisasi_work_order_mulai: undefined,
        tgl_realisasi_work_order_selesai: undefined,
        tgl_posting_realisasi_work_order_mulai: undefined,
        tgl_posting_realisasi_work_order_selesai: undefined,
        id_item: undefined,
      },
      overhead: {
        active: false,
        tgl_realisasi_work_order_mulai: undefined,
        tgl_realisasi_work_order_selesai: undefined,
        tgl_posting_realisasi_work_order_mulai: undefined,
        tgl_posting_realisasi_work_order_selesai: undefined,
        id_item: undefined,
      },
    });

    const initialPagination = {
      page: "1",
      per_page: "10",
      total_page: "1",
      data_count: "0",
    };

    const [paginationConfigBahan, setPaginationConfigBahan] =
      useState(initialPagination);
    const [paginationConfigUpah, setPaginationConfigUpah] =
      useState(initialPagination);
    const [paginationConfigAlat, setPaginationConfigAlat] =
      useState(initialPagination);
    const [paginationConfigSubkon, setPaginationConfigSubkon] =
      useState(initialPagination);
    const [paginationConfigOverhead, setPaginationConfigOverhead] =
      useState(initialPagination);

    const [isPageLoading, setIsPageLoading] = useState({
      bahan: true,
      upah: true,
      alat: true,
      subkon: true,
      overhead: true,
    });
    const [searchConfig, setSearchConfig] = useState({
      bahan: {
        status: false,
        key: "",
      },
      upah: {
        status: false,
        key: "",
      },
      alat_mesin: {
        status: false,
        key: "",
      },
      subkon: {
        status: false,
        key: "",
      },
      overhead: {
        status: false,
        key: "",
      },
    });
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });

    const [dataBahan, setDataBahan] = useState([]);
    const [dataUpah, setDataUpah] = useState([]);
    const [dataAlatMesin, setDataAlatMesin] = useState([]);
    const [dataSubkon, setDataSubkon] = useState([]);
    const [dataOverhead, setDataOverhead] = useState([]);

    const fetchBahan = () => {
      setIsPageLoading((prev) => ({ ...prev, bahan: true }));

      PostingRealisasiWorkOrderApi.get({
        q: searchConfig.bahan.key,
        page: paginationConfigBahan.page,
        per_page: paginationConfigBahan.per_page,
        buaso: "bahan",
        ...dataFilter.bahan,
      })
        .then((res) => {
          const data = res?.data?.data ?? [];
          const dataBahan = data?.map((val) => ({
            ...val,
            tgl_realisasi_produksi: val.tgl_realisasi_produksi_bahan,
            no_realisasi_produksi: val.no_realisasi_produksi_bahan,
            qty_realisasi: val.qty_realisasi_produksi_bahan,
            harga_satuan: val.harga_satuan_rata_rata_pakai,
            tgl_bdp: val.tgl_bdp_bahan,
            no_bdp: val.no_bdp_bahan,
          }));

          setDataBahan(dataBahan ?? []);
          setPaginationConfigBahan((prev) => ({
            ...prev,
            data_count: res?.data.data_count,
            total_page: res?.data.total_page,
          }));
        })
        .finally(() => setIsPageLoading((prev) => ({ ...prev, bahan: false })));
    };

    const fetchUpah = () => {
      setIsPageLoading((prev) => ({ ...prev, upah: true }));

      PostingRealisasiWorkOrderApi.get({
        q: searchConfig.upah.key,
        page: paginationConfigUpah.page,
        per_page: paginationConfigUpah.per_page,
        ...dataFilter.upah,
        buaso: "upah",
      })
        .then((res) => {
          const data = res?.data?.data ?? [];
          const dataUpah = data?.map((val) => ({
            ...val,
            nama_coa_debet: `${val.nomor_akun_debet} - ${val.nama_akun_debet}`,
            nama_coa_kredit: `${val.nomor_akun_kredit} - ${val.nama_akun_kredit}`,
          }));

          setDataUpah(dataUpah ?? []);
          setPaginationConfigUpah((prev) => ({
            ...prev,
            data_count: res?.data.data_count,
            total_page: res?.data.total_page,
          }));
        })
        .finally(() => setIsPageLoading((prev) => ({ ...prev, upah: false })));
    };

    const fetchAlatMesin = () => {
      setIsPageLoading((prev) => ({ ...prev, alat: true }));

      PostingRealisasiWorkOrderApi.get({
        q: searchConfig.alat_mesin.key,
        page: paginationConfigAlat.page,
        per_page: paginationConfigAlat.per_page,
        buaso: "alat_mesin",
        ...dataFilter.alat_mesin,
      })
        .then((res) => {
          const data = res?.data?.data ?? [];
          const dataAlatMesin = data?.map((val) => ({
            ...val,
            tgl_realisasi_produksi: val.tgl_realisasi_produksi_alat_mesin,
            no_realisasi_produksi: val.no_realisasi_produksi_alat_mesin,
            qty_realisasi: val.qty_realisasi_produksi_alat_mesin,
            harga_satuan: val?.harga_satuan ?? val.harga_satuan_penyusutan,
            tgl_bdp: val.tgl_bdp_alat_mesin,
            no_bdp: val.no_bdp_alat_mesin,
            nama_item: val.nama_item,
          }));

          setDataAlatMesin(dataAlatMesin ?? []);
          setPaginationConfigAlat((prev) => ({
            ...prev,
            data_count: res?.data.data_count,
            total_page: res?.data.total_page,
          }));
        })
        .finally(() => setIsPageLoading((prev) => ({ ...prev, alat: false })));
    };

    const fetchSubkon = () => {
      setIsPageLoading((prev) => ({ ...prev, subkon: true }));

      PostingRealisasiWorkOrderApi.get({
        q: searchConfig.subkon.key,
        page: paginationConfigSubkon.page,
        per_page: paginationConfigSubkon.per_page,
        buaso: "subkon",
        ...dataFilter.subkon,
      })
        .then((res) => {
          const data = res?.data?.data ?? [];
          const dataSubkon = data?.map((val) =>
            Object({
              ...val,
              nama_coa_debet: `${val.nomor_akun_debet} - ${val.nama_akun_debet}`,
              nama_coa_kredit: `${val.nomor_akun_kredit} - ${val.nama_akun_kredit}`,
            })
          );
          setDataSubkon(dataSubkon ?? []);
          setPaginationConfigSubkon((prev) => ({
            ...prev,
            data_count: res?.data.data_count,
            total_page: res?.data.total_page,
          }));
        })
        .finally(() =>
          setIsPageLoading((prev) => ({ ...prev, subkon: false }))
        );
    };

    const fetchOverhead = () => {
      setIsPageLoading((prev) => ({ ...prev, overhead: true }));

      PostingRealisasiWorkOrderApi.get({
        q: searchConfig.overhead.key,
        page: paginationConfigOverhead.page,
        per_page: paginationConfigOverhead.per_page,
        buaso: "overhead",
        ...dataFilter.overhead,
      })
        .then((res) => {
          const data = res?.data?.data ?? [];
          const dataOverhead = data?.map((val) =>
            Object({
              ...val,
              tgl_realisasi_produksi: val.tgl_realisasi_produksi_overhead,
              no_realisasi_produksi: val.no_realisasi_produksi_overhead,
              qty_realisasi: val.qty_realisasi_produksi_overhead,
              harga_satuan: val?.harga_satuan ?? val.harga_satuan_overhead,
              tgl_bdp: val.tgl_bdp_overhead,
              no_bdp: val.no_bdp_overhead,
            })
          );

          setDataOverhead(dataOverhead ?? []);
          setPaginationConfigOverhead((prev) => ({
            ...prev,
            data_count: res?.data.data_count,
            total_page: res?.data.total_page,
          }));
        })
        .finally(() =>
          setIsPageLoading((prev) => ({ ...prev, overhead: false }))
        );
    };

    const checkAlert = () => {
      const locationState = location.state;

      if (locationState?.alert) {
        setAlertConfig(locationState.alert);
      }
    };

    const onDismissAlert = () => {
      const currentState = history.location.state;

      if (currentState && currentState.alert) {
        let state = { ...currentState };
        delete state.alert;

        history.replace(history.location.pathname, state);
        setAlertConfig({
          show: false,
          text: "",
          variant: "",
        });
      }
    };

    useEffect(() => {
      setNavbarTitle("Posting Realisasi Work Order");
      checkAlert();
    }, []);

    useEffect(
      () => fetchBahan(),
      [
        searchConfig.bahan.key,
        paginationConfigBahan.page,
        paginationConfigBahan.per_page,
        dataFilter?.bahan?.tgl_realisasi_work_order_mulai,
        dataFilter?.bahan?.tgl_realisasi_work_order_selesai,
        dataFilter?.bahan?.tgl_posting_realisasi_work_order_mulai,
        dataFilter?.bahan?.tgl_posting_realisasi_work_order_selesai,
        dataFilter?.bahan?.id_item,
      ]
    );
    useEffect(
      () => fetchUpah(),
      [
        searchConfig.upah.key,
        paginationConfigUpah.page,
        paginationConfigUpah.per_page,
        dataFilter?.upah?.tgl_realisasi_work_order_mulai,
        dataFilter?.upah?.tgl_realisasi_work_order_selesai,
        dataFilter?.upah?.tgl_posting_realisasi_work_order_mulai,
        dataFilter?.upah?.tgl_posting_realisasi_work_order_selesai,
        dataFilter?.upah?.id_item,
      ]
    );
    useEffect(
      () => fetchAlatMesin(),
      [
        searchConfig.alat_mesin.key,
        paginationConfigAlat.page,
        paginationConfigAlat.per_page,
        dataFilter?.alat_mesin?.tgl_realisasi_work_order_mulai,
        dataFilter?.alat_mesin?.tgl_realisasi_work_order_selesai,
        dataFilter?.alat_mesin?.tgl_posting_realisasi_work_order_mulai,
        dataFilter?.alat_mesin?.tgl_posting_realisasi_work_order_selesai,
        dataFilter?.alat_mesin?.id_item,
      ]
    );
    useEffect(
      () => fetchSubkon(),
      [
        searchConfig.subkon.key,
        paginationConfigSubkon.page,
        paginationConfigSubkon.per_page,
        dataFilter?.subkon?.tgl_realisasi_work_order_mulai,
        dataFilter?.subkon?.tgl_realisasi_work_order_selesai,
        dataFilter?.subkon?.tgl_posting_realisasi_work_order_mulai,
        dataFilter?.subkon?.tgl_posting_realisasi_work_order_selesai,
        dataFilter?.subkon?.id_item,
      ]
    );
    useEffect(
      () => fetchOverhead(),
      [
        searchConfig.overhead.key,
        paginationConfigOverhead.page,
        paginationConfigOverhead.per_page,
        dataFilter?.overhead?.tgl_realisasi_work_order_mulai,
        dataFilter?.overhead?.tgl_realisasi_work_order_selesai,
        dataFilter?.overhead?.tgl_posting_realisasi_work_order_mulai,
        dataFilter?.overhead?.tgl_posting_realisasi_work_order_selesai,
        dataFilter?.overhead?.id_item,
      ]
    );

    return (
      <CRUDLayout>
        <Row className="mb-2 d-flex justify-content-end align-items-center">
          <Col
            md={5}
            className="d-flex flex-row justify-content-start align-items-center"
          ></Col>
          <Col className="d-flex flex-row justify-content-end align-items-center">
            <CreateButton
              onClick={() => {
                history.push(
                  "/transaksi/posting-realisasi-work-order/realisasi-work-order"
                );
              }}
            />
          </Col>
        </Row>

        <Alert
          showCloseButton
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={onDismissAlert}
        />

        <Card className="border">
          <Tab.Container defaultActiveKey="bahan">
            <Card.Header className='className="d-flex justify-content-between align-items-center'>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="bahan">Bahan</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="upah">Upah</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="alat_mesin">Alat Mesin</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="subkon">Subkon</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="overhead">Overhead</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Tab.Content>
              <Tab.Pane eventKey="bahan">
                <TablePostingRealisasi
                  buaso="bahan"
                  data={dataBahan}
                  pagination={paginationConfigBahan}
                  setPagination={setPaginationConfigBahan}
                  initialPagination={initialPagination}
                  searchConfig={searchConfig}
                  setSearchConfig={setSearchConfig}
                  isPageLoading={isPageLoading.bahan}
                  setAlertConfig={setAlertConfig}
                  modalFilter={modalFilterBahan}
                  setModalFilter={setModalFilterBahan}
                  dataFilter={dataFilter}
                  setDataFilter={setDataFilter}
                />
              </Tab.Pane>

              <Tab.Pane eventKey="upah">
                <TablePostingRealisasi
                  buaso="upah"
                  data={dataUpah}
                  pagination={paginationConfigUpah}
                  setPagination={setPaginationConfigUpah}
                  initialPagination={initialPagination}
                  searchConfig={searchConfig}
                  setSearchConfig={setSearchConfig}
                  isPageLoading={isPageLoading.upah}
                  setAlertConfig={setAlertConfig}
                  modalFilter={modalFilterUpah}
                  setModalFilter={setModalFilterUpah}
                  dataFilter={dataFilter}
                  setDataFilter={setDataFilter}
                />
              </Tab.Pane>

              <Tab.Pane eventKey="alat_mesin">
                <TablePostingRealisasi
                  buaso="alat_mesin"
                  data={dataAlatMesin}
                  pagination={paginationConfigAlat}
                  setPagination={setPaginationConfigAlat}
                  initialPagination={initialPagination}
                  searchConfig={searchConfig}
                  setSearchConfig={setSearchConfig}
                  isPageLoading={isPageLoading.alat}
                  setAlertConfig={setAlertConfig}
                  modalFilter={modalFilterAlatMesin}
                  setModalFilter={setModalFilterAlatMesin}
                  dataFilter={dataFilter}
                  setDataFilter={setDataFilter}
                />
              </Tab.Pane>

              <Tab.Pane eventKey="subkon">
                <TablePostingRealisasi
                  buaso="subkon"
                  data={dataSubkon}
                  pagination={paginationConfigSubkon}
                  setPagination={setPaginationConfigSubkon}
                  initialPagination={initialPagination}
                  searchConfig={searchConfig}
                  setSearchConfig={setSearchConfig}
                  isPageLoading={isPageLoading.subkon}
                  setAlertConfig={setAlertConfig}
                  modalFilter={modalFilterSubkon}
                  setModalFilter={setModalFilterSubkon}
                  dataFilter={dataFilter}
                  setDataFilter={setDataFilter}
                />
              </Tab.Pane>

              <Tab.Pane eventKey="overhead">
                <TablePostingRealisasi
                  buaso="overhead"
                  data={dataOverhead}
                  pagination={paginationConfigOverhead}
                  setPagination={setPaginationConfigOverhead}
                  initialPagination={initialPagination}
                  searchConfig={searchConfig}
                  setSearchConfig={setSearchConfig}
                  isPageLoading={isPageLoading.overhead}
                  setAlertConfig={setAlertConfig}
                  modalFilter={modalFilterOverhead}
                  setModalFilter={setModalFilterOverhead}
                  dataFilter={dataFilter}
                  setDataFilter={setDataFilter}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card>

        {["upah", "subkon"].includes(modalFormPosting.buaso) ? (
          <ModalFormUpahSubkon
            show={modalFormPosting.show}
            buaso={modalFormPosting.buaso}
            isCreate={modalFormPosting.isCreate}
            onHide={() =>
              setModalFormPosting({
                show: false,
                data: null,
                isCreate: "false",
                buaso: "bahan",
              })
            }
          />
        ) : (
          <ModalFormBahanAlatOverhead
            show={modalFormPosting.show}
            buaso={modalFormPosting.buaso}
            isCreate={modalFormPosting.isCreate}
            onHide={() =>
              setModalFormPosting({
                show: false,
                data: null,
                isCreate: "false",
                buaso: "bahan",
              })
            }
          />
        )}
      </CRUDLayout>
    );
  }
);
