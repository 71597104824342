import { DatePicker, SelectSearch, FilterModal } from "components";
import { DateConvert } from "utilities";
import { useFormik } from "formik";

const ModalFilterProduksi = ({
  SESSION_KEY,
  dropdown,
  modalConfig,
  setModalConfig,
  data,
  setData,
}) => {
  const filter = data?.filter;
  const formInitialValues = {
    tgl_produksi_mulai: filter?.tgl_produksi_mulai,
    tgl_produksi_selesai: filter?.tgl_produksi_selesai,
    tgl_realisasi_produksi_mulai: filter?.tgl_realisasi_produksi_mulai,
    tgl_realisasi_produksi_selesai: filter?.tgl_realisasi_produksi_selesai,
    item_produksi: filter?.item_produksi,
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;
    if (checkActive) {
      setData({
        filter: { ...values, active: true },
        pagination: { ...data.pagination, page: 1 },
      });
    } else {
      setData({ ...data, filter: { ...values, active: false } });
    }
    setModalConfig(false);
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: formSubmitHandler,
  });

  const { values, setFieldValue, setValues, handleSubmit } = formik;

  const onChangeTglProduksi = (dates) => {
    const [start, end] = dates;

    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_produksi_mulai: startDate,
      tgl_produksi_selesai: endDate,
    });
  };

  const onChangeTglRealisasiProduksi = (dates) => {
    const [start, end] = dates;

    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_realisasi_produksi_mulai: startDate,
      tgl_realisasi_produksi_selesai: endDate,
    });
  };

  const onResetButtonClick = () => {
    // DELETE FILTER & PAGINATION DI SESSION STORAGE
    sessionStorage.removeItem(SESSION_KEY.FILTER);
    sessionStorage.removeItem(SESSION_KEY.PAGINATION);

    setData((prev) => ({
      ...prev,
      filter: {
        tgl_produksi_mulai: undefined,
        tgl_produksi_selesai: undefined,
        tgl_realisasi_produksi_mulai: undefined,
        tgl_realisasi_produksi_selesai: undefined,
        item_produksi: undefined,
      },
      pagination: {
        ...prev.pagination,
        page: 1,
        per_page: 10,
      },
    }));

    setModalConfig(false);
  };

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}>
      <DatePicker
        selectsRange
        label="Tgl. Produksi"
        placeholderText="Pilih tanggal produksi"
        startDate={
          values?.tgl_produksi_mulai ? new Date(values?.tgl_produksi_mulai) : ""
        }
        endDate={
          values?.tgl_produksi_selesai
            ? new Date(values?.tgl_produksi_selesai)
            : ""
        }
        onChange={(dates) => onChangeTglProduksi(dates)}
        monthsShown={2}
        dateFormat="dd/MM/yyyy"
      />

      <DatePicker
        selectsRange
        label="Tgl. Realisasi Produksi"
        placeholderText="Pilih tanggal realisasi produksi"
        startDate={
          values?.tgl_realisasi_produksi_mulai
            ? new Date(values?.tgl_realisasi_produksi_mulai)
            : ""
        }
        endDate={
          values?.tgl_realisasi_produksi_selesai
            ? new Date(values?.tgl_realisasi_produksi_selesai)
            : ""
        }
        onChange={(dates) => onChangeTglRealisasiProduksi(dates)}
        monthsShown={2}
        dateFormat="dd/MM/yyyy"
      />

      <SelectSearch
        key={values?.item_produksi}
        label="Item Produksi"
        placeholder="Pilih item produksi"
        defaultValue={dropdown?.item_produksi?.find(
          (item) => item.value === values.item_produksi
        )}
        option={dropdown?.item_produksi ?? []}
        onChange={(val) => setFieldValue("item_produksi", val.value)}
      />
    </FilterModal>
  );
};

export default ModalFilterProduksi;
