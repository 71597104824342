import { createContext } from 'react';
import { TransferOverheadWorkOrderApi } from 'api';
import { useQuery } from 'react-query';

export const DropdownContext = createContext();

export const DropdownProvider = ({ children }) => {
  const STALE_DURATION_MINUTES = 4 * 60 * 1000; // 4 MINUTES

  const dropdownOverhead = useQuery(
    ['dropdown', 'overhead'],
    () =>
      TransferOverheadWorkOrderApi.getDropdownOverhead().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item_buaso,
          label: `${val.kode_item} | ${val.nama_item}`,
        }));
        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownAset = useQuery(
    ['dropdown', 'aset'],
    () =>
      TransferOverheadWorkOrderApi.getDropdownAset().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item_aset,
          label: val.nama_item_aset,
        }));
        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  return (
    <DropdownContext.Provider
      value={{
        dropdownOverhead,
        dropdownAset,
      }}
    >
      {children}
    </DropdownContext.Provider>
  );
};

export const withDropdownProvider = (Component) => (props) => {
  return (
    <DropdownProvider>
      <Component {...props} />
    </DropdownProvider>
  );
};
