export const formInitialValues = (data) => {
  const jumlah =
    parseFloat(data?.summary?.bahan ?? 0) +
    parseFloat(data?.summary?.upah ?? 0) +
    parseFloat(data?.summary?.alat_mesin ?? 0) +
    parseFloat(data?.summary?.subkon ?? 0) +
    parseFloat(data?.summary?.overhead ?? 0);
  return Object({
    id_produksi_aset: data?.id_produksi_aset ?? undefined,
    id_item_aset: data?.id_item_aset ?? undefined,
    tgl_nilai_perolehan_aset: data?.tgl_nilai_perolehan_aset ?? undefined,
    no_nilai_perolehan_aset: data?.no_nilai_perolehan_aset ?? "",
    tgl_produksi_aset: data?.tgl_produksi_aset ?? undefined,
    no_produksi_aset: data?.no_produksi_aset ?? "",
    id_permintaan_aset: data?.id_permintaan_aset ?? undefined,
    id_permintaan_aset_detail: data?.id_permintaan_aset_detail ?? undefined,
    tgl_permintaan_aset: data?.tgl_permintaan_aset ?? undefined,
    no_permintaan_aset: data?.no_permintaan_aset ?? undefined,
    nomor_coa_debet: data?.nomor_coa_debet ?? "",
    nama_coa_debet: data?.nama_akun_debet ?? "",
    nomor_coa_kredit: data?.nomor_coa_kredit ?? "",
    nama_coa_kredit: data?.nama_akun_kredit ?? "",
    nama_grup_aset: data?.nama_grup_aset ?? "",
    nama_kategori_aset: data?.nama_kategori_aset ?? "",
    nama_jenis_aset: data?.nama_jenis_aset ?? "",
    nama_aset: data?.nama_item_aset ?? "",
    keperluan: data?.keperluan ?? "",
    jumlah: jumlah,
    keterangan_jurnal: data?.keterangan_jurnal
      ? data?.keterangan_jurnal
      : `${data?.nama_item_aset} | ${data?.no_produksi_aset}`,
    file:
      data?.file?.length > 0 ? data?.file?.map((val) => val?.path_file) : [],
    bahan: data?.wip?.bahan ?? [],
    upah: data?.wip?.upah ?? [],
    alatMesin: data?.wip?.alat_mesin ?? [],
    subkon: data?.wip?.subkon ?? [],
    overhead: data?.wip?.overhead ?? [],
    summary: data?.summary ?? {},
  });
};
