// React
import { Fragment, useEffect, useState } from "react";

// API
import { TransferOverheadJobMixApi } from "api";

// Components
import { DataStatus } from "components";
import { Card, Form, Nav, Tab } from "react-bootstrap";

// Form
import { Formik } from "formik";

// View Components
import {
  InfoFormSection,
  ProduksiSection,
  TabAnalisa,
  SummarySection,
  Divider,
} from "./components";

// View Functions
import { dataSummary, totalDataSummary } from "./functions";

const DetailProduksi = ({ id }) => {
  // States
  const [page, setPage] = useState({
    loading: true,
    status: true,
  });
  const [data, setData] = useState({});

  useEffect(() => {
    TransferOverheadJobMixApi.jobmix_single(id)
      .then((single) => {
        setData(single.data.data);

        setPage({
          loading: false,
          status: true,
        });
      })
      .catch(() => {
        setPage({
          loading: false,
          status: false,
        });
      });

    // eslint-disable-next-line
  }, []);

  if (page.loading || !page.status) {
    return (
      <DataStatus
        loading={page.loading}
        text={
          !page.status
            ? "Data gagal dimuat, tidak dapat menampilkan data"
            : "Memuat data..."
        }
      />
    );
  } else {
    return (
      <Fragment>
        <Formik
          initialValues={{
            id_sales_order: data.id_sales_order ?? "",
            id_sales_order_detail: data.id_sales_order_detail ?? "",
            id_jobmix: data.id_jobmix ?? "",
            tgl_jobmix: data.tgl_jobmix ?? "",
            nomor: data.no_jobmix ?? "",
            qty_jobmix: data.qty_jobmix ?? "",
            kode_satuan: data.kode_satuan ?? "",
            analisa_bahan: data.analisa_bahan ?? [],
            analisa_upah: data.analisa_upah ?? [],
            analisa_alat_mesin: data.analisa_alat_mesin ?? [],
            analisa_subcont: data.analisa_subcont ?? [],
            analisa_overhead: data.analisa_overhead ?? [],
          }}
          onSubmit={() => {}}>
          {({ handleSubmit, values }) => {
            // Variables
            const summary = dataSummary(values);
            const total = totalDataSummary(summary);

            return (
              <Form onSubmit={handleSubmit}>
                <Divider>
                  <InfoFormSection data={data} readOnly />
                </Divider>

                <Divider>
                  <ProduksiSection title="Komponen Utama">
                    <Card>
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="analisa-1">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                          <Nav variant="tabs">
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-1">Bahan</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-2">Upah</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-3">
                                Alat & Mesin
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-4">Subkon</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-5">Overhead</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Card.Header>
                        <Tab.Content>
                          <Tab.Pane eventKey="analisa-1">
                            <TabAnalisa
                              dropdown={{}}
                              id_buaso="1"
                              nama_buaso="bahan"
                              label="Bahan"
                              readOnly
                              flag="UT"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-2">
                            <TabAnalisa
                              dropdown={{}}
                              id_buaso="2"
                              nama_buaso="upah"
                              label="upah"
                              readOnly
                              flag="UT"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-3">
                            <TabAnalisa
                              dropdown={{}}
                              id_buaso="3"
                              nama_buaso="alat_mesin"
                              label="Alat & Mesin"
                              readOnly
                              flag="UT"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-4">
                            <TabAnalisa
                              dropdown={{}}
                              id_buaso="4"
                              nama_buaso="subcont"
                              label="Subkon"
                              readOnly
                              flag="UT"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-5">
                            <TabAnalisa
                              dropdown={{}}
                              id_buaso="5"
                              nama_buaso="overhead"
                              label="Overhead"
                              readOnly
                              flag="UT"
                            />
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Card>
                  </ProduksiSection>
                </Divider>

                <Divider>
                  <ProduksiSection title="Komponen Tambahan">
                    <Card>
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="analisa-1">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                          <Nav variant="tabs">
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-1">Bahan</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-2">Upah</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-3">
                                Alat & Mesin
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-4">Subkon</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-5">Overhead</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Card.Header>
                        <Tab.Content>
                          <Tab.Pane eventKey="analisa-1">
                            <TabAnalisa
                              dropdown={{}}
                              id_buaso="1"
                              nama_buaso="bahan"
                              label="Bahan"
                              readOnly
                              flag="TB"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-2">
                            <TabAnalisa
                              dropdown={{}}
                              id_buaso="2"
                              nama_buaso="upah"
                              label="upah"
                              readOnly
                              flag="TB"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-3">
                            <TabAnalisa
                              dropdown={{}}
                              id_buaso="3"
                              nama_buaso="alat_mesin"
                              label="Alat & Mesin"
                              readOnly
                              flag="TB"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-4">
                            <TabAnalisa
                              dropdown={{}}
                              id_buaso="4"
                              nama_buaso="subcont"
                              label="Subkon"
                              readOnly
                              flag="TB"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="analisa-5">
                            <TabAnalisa
                              dropdown={{}}
                              id_buaso="5"
                              nama_buaso="overhead"
                              label="Overhead"
                              readOnly
                              flag="TB"
                            />
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Card>
                  </ProduksiSection>
                </Divider>

                <Divider>
                  <SummarySection summary={summary} total={total} />
                </Divider>
              </Form>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
};

export default DetailProduksi;
