import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  ActionButton,
  DataStatus,
  DatePicker,
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "components";

import { Card, Col, Row } from "react-bootstrap";
import { DateConvert, RupiahConvert } from "utilities";
import { LaporanProyeksiLabaRugi } from "api";
import { TableComp } from "./Comps";

export const LaporanProyeksiLabaRugiList = ({ setNavbarTitle }) => {
  const [header, setHeader] = useState([]);
  const [groupCoa, setGroupCoa] = useState({});
  const [dateFilter, setDateFilter] = useState({
    tanggal_mulai: null,
    tanggal_selesai: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleFilter = () => {
    setIsLoading(true);

    Axios.all([LaporanProyeksiLabaRugi.getListCoa(dateFilter)])
      .then(
        Axios.spread((res) => {
          const data = res.data.data;
          const sumPerCoa = Object.entries(res.data.sum_per_coa);

          const head = data.filter(
            (val) => val.nomor_akun.toString().split("").length === 1
          );

          const mapSumPerCoa = sumPerCoa.map((val) => ({
            ...data.find((res) => res.nomor_akun === val[0]),
            total: val[1],
          }));

          mapSumPerCoa.map((val) => {
            const indexSum = data.findLastIndex(
              (item) => parseInt(item.parent_coa) === parseInt(val.nomor_akun)
            );

            const finalIndex = indexSum + 1;

            return data.splice(finalIndex, 0, {
              grup: val.grup,
              id_jenis: null,
              id_unit_produksi: null,
              is_header: false,
              kelompok: val.kelompok,
              nama_akun: "TOTAL " + val.nama_akun.toUpperCase(),
              nilai: val.total,
              nomor_akun: val.nomor_akun,
              normal_pos: val.normal_pos,
              parent_coa: val.nomor_akun,
              is_total: true,
            });
          });

          const groupCoa = head.reduce((acc, cur) => {
            return {
              ...acc,
              [cur.nama_akun]: data.filter(
                (val) =>
                  val.nomor_akun.toString().split("")[0] === cur.nomor_akun
              ),
            };
          }, {});

          setHeader(head.map((val) => val.nama_akun));
          setGroupCoa(groupCoa);
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onTanggalChange = (dates) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setDateFilter({
      tanggal_mulai: startDate,
      tanggal_selesai: endDate,
    });
  };

  useEffect(() => {
    setNavbarTitle("Laba Rugi Indikatif");
  }, []);

  return (
    <>
      <Card className="mb-4">
        <Card.Body>
          <div className="d-flex">
            {/* <DatePicker
                label="Tanggal"
                onChange={(e) =>
                  setDateFilter(DateConvert(new Date(e)).default)
                }
                selected={dateFilter && new Date(dateFilter)}
              /> */}
            <div style={{ width: 400 }}>
              <DatePicker
                selectsRange
                label="Tanggal"
                startDate={
                  dateFilter?.tanggal_mulai
                    ? new Date(dateFilter.tanggal_mulai)
                    : ""
                }
                endDate={
                  dateFilter?.tanggal_selesai
                    ? new Date(dateFilter.tanggal_selesai)
                    : ""
                }
                onChange={(dates) => onTanggalChange(dates)}
                monthsShown={2}
              />
            </div>
            <ActionButton
              text="Cari"
              className="my-4 mx-3"
              onClick={handleFilter}
            />
          </div>
        </Card.Body>
      </Card>
      {console.log(groupCoa)}

      {isLoading ? (
        <DataStatus loading text="Memuat Data" />
      ) : header.length > 0 ? (
        header.map((val) => (
          <TableComp data={groupCoa[val] ?? []} title={val} tgl={dateFilter} />
        ))
      ) : (
        <Card className="p-4">
          <Card.Body>
            <DataStatus text="Pilih Tanggal Terlebih Dahulu" />
          </Card.Body>
        </Card>
      )}
    </>
  );
};
