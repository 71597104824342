import { useState, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { IoAlert, IoAlertCircleOutline, IoIosWarning } from "react-icons/io";
import { useFormik } from "formik";
import _ from "lodash";
import { ActionButton, Input, Select, SelectMonth } from "components";
import { HargaSatuanRataRataApi } from "api";

const ModalPosting = ({ show, onHide, setAlert }) => {
  const today = new Date();
  const [type, setType] = useState("form");
  const [isValid, setIsValid] = useState({
    loading: false,
    valid: false,
  });

  const onCloseHandler = () => {
    setType("form");
    onHide();
  };

  // FORM VALIDATION
  const { values, isSubmitting, setFieldValue, resetForm, handleSubmit } =
    useFormik({
      initialValues: {
        month:
          String(today.getMonth() + 1).length > 1
            ? today.getMonth() + 1
            : `0${today.getMonth() + 1}`,
        year: today.getFullYear(),
      },

      onSubmit: (values) => {
        const finalValue = `${values.year}-${values.month}-01`;

        HargaSatuanRataRataApi.save({ tgl_posting: finalValue })
          .then(() => {
            setAlert({
              show: true,
              variant: "primary",
              text: "Posting HPS berhasil!",
            });
          })
          .catch(() => {
            setAlert({
              show: true,
              variant: "danger",
              text: "Posting HPS gagal!",
            });
          })
          .finally(() => {
            onCloseHandler();
          });
      },
    });

  const checkPostingIsValid = (month, year) => {
    const finalValue = `${year}-${month}-01`;

    setIsValid({ loading: true, valid: false });

    return HargaSatuanRataRataApi.getHSRData({ tanggal: finalValue })
      .then((res) => {
        const status = res?.data?.data?.status_posting?.toLowerCase();

        if (String(status).toLowerCase() === "belum di posting") {
          setIsValid({ loading: false, valid: true });
        } else {
          setIsValid({ loading: false, valid: false });
        }
      })
      .catch(() => setIsValid({ loading: false, valid: false }));
  };

  useEffect(() => {
    !show && resetForm();
    show && checkPostingIsValid(values.month, values.year);
  }, [show]);

  return (
    <Modal show={show} onHide={onCloseHandler}>
      <Modal.Header closeButton>
        {type === "form" ? (
          <b>Posting Harga Satuan Rata-Rata Bahan</b>
        ) : (
          <b>Konfirmasi Posting</b>
        )}
      </Modal.Header>
      {type === "form" ? (
        <>
          <Modal.Body>
            <Row>
              <Col>
                <SelectMonth
                  noMargin
                  size="sm"
                  label="Bulan Posting"
                  defaultValue={values.month}
                  onChange={(e) => {
                    setFieldValue("month", e.target.value);
                    checkPostingIsValid(e.target.value, values.year);
                  }}
                />
              </Col>
              <Col>
                <Select
                  label="Tahun Posting"
                  size="sm"
                  defaultValue={values.year}
                  onChange={(e) => {
                    setFieldValue("year", e.target.value);
                    checkPostingIsValid(values.month, e.target.value);
                  }}>
                  {_.range(today.getFullYear(), 1999).map((e) => (
                    <option value={e}>{e}</option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Input
              disabled
              label="Status Posting"
              value={
                isValid.loading
                  ? "Memuat data . . ."
                  : isValid.valid
                  ? "Belum di posting"
                  : "Sudah diposting"
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <ActionButton
              text="Posting"
              onClick={() => setType("confirm")}
              disable={!isValid.valid}
            />
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Body className="text-center">
            <IoIosWarning className="text-danger" size={150} />
            <span className="d-block mt-3">
              Apa Anda yakin akan memposting data ini?
            </span>
            <span className="d-block">
              Data yang telah diposting tidak bisa di edit!
            </span>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <div>
              <ActionButton
                text="Posting"
                className="m-1"
                loading={isSubmitting}
                onClick={handleSubmit}
              />
              <ActionButton
                text="Batal"
                variant="danger"
                className="m-1"
                onClick={() => setType("form")}
              />
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};
export default ModalPosting;
