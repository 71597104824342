/*eslint-disable*/

import {
  IoCashOutline,
  IoSpeedometerOutline,
  IoServerOutline,
  IoDocumentTextOutline,
} from "react-icons/io5";

/* 
  MENU REQUIREMENTS
    > TEXT  = REQUIRED
    > LINK  = REQUIRED
    > EXACT = OPTIONAL (TRUE/FALSE)
    > TYPE  = REQUIRED (MENU/DROPDOWN)
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
    > ICON  = OPTIONAL (REACT-ICONS)
*/

export default [
  {
    //Dashboard
    text: "Dashboard",
    link: "/",
    type: "menu",
    exact: true,
    icon: <IoSpeedometerOutline />,
    hak: ["SUPA"],
  },
  {
    // Master
    text: "Master",
    type: "dropdown",
    icon: <IoServerOutline />,
    hak: [
      "SUPA",
      "COC",
      "COC_MAS_ABJ",
      "COC_REG_OVH",
      "COC_MAS_KLO",
      "COC_MAS_HPS",
    ],
    menu: [
      {
        text: "Kelompok Overhead",
        link: "/master/kelompok-overhead",
        hak: ["SUPA", "COC_MAS_KLO"],
      },
      {
        text: "Mapping Kelompok Item terhadap COA",
        link: "/transaksi/mapping-kelompok-item-coa",
        hak: ["SUPA", "COC_TRN_KTC"],
      },
      {
        text: "Item Overhead",
        link: "/master/item-overhead",
        hak: ["SUPA", "COC_REG_OVH"],
      },
      {
        text: "MAPPING COA ITEM OVERHEAD ",
        link: "/master/mapping-item-overhead",
        hak: ["SUPA", "COC_REG_OVH"],
      },
      {
        text: "Harga Perkiraan Sendiri",
        link: "/master/harga-perkiraan-sendiri",
        hak: ["SUPA", "COC_MAS_HPS"],
      },
      {
        text: "Analisa Barang Jadi",
        link: "/master/analisa-barang-jadi",
        hak: ["SUPA", "COC_MAS_ABJ"],
      },
    ],
  },
  {
    // Transaksi
    text: "Transaksi",
    type: "dropdown",
    icon: <IoCashOutline />,
    hak: [
      "SUPA",
      "COC",
      "COC_TRN_BDP",
      "COC_TRN_HSB",
      "COC_TRN_HSP",
      "COC_TRN_TJO",
      "COC_TRN_HPP",
      "COC_TRN_KTC",
      // "COC_TRN_UNC",
    ],
    menu: [
      {
        text: "Harga Satuan Rata-Rata Bahan",
        link: "/cost-control/transaksi/harga-satuan-rata-rata-bahan",
        hak: ["SUPA", "COC_TRN_HSB"],
      },
      {
        text: "Harga Satuan Rata-Rata Penyusutan",
        link: "/transaksi/harga-satuan-rata-rata-penyusutan",
        hak: ["SUPA", "COC_TRN_HSP"],
      },
      {
        text: "Harga Satuan Rata-Rata Overhead",
        link: "/transaksi/harga-satuan-rata-rata-overhead",
        hak: ["SUPA", "COC_TRN_HSO"],
      },
      {
        text: "Harga Satuan Rata-Rata Barang Jadi",
        link: "/transaksi/harga-satuan-rata-rata-barang-jadi",
        hak: ["SUPA", "COC_TRN_HSJ"],
      },
      {
        text: "Transfer Overhead Produksi",
        link: "/transaksi/transfer-overhead-job-mix",
        hak: ["SUPA", "COC_TRN_TJO"],
      },
      {
        text: "BDP Produksi",
        link: "/transaksi/bdp",
        hak: ["SUPA", "COC_TRN_BDP"],
      },
      {
        text: "Harga Pokok Produksi",
        link: "/transaksi/hpp",
        hak: ["SUPA", "COC_TRN_HPP"],
      },
      // {
      //   text: "Harga Satuan Rata-Rata Pakai",
      //   link: "/transaksi/harga-satuan-rata-rata-pakai",
      //   hak: ["SUPA", "COC_TRN_HSR"],
      // },

      // {
      //   text: "Unit Cost",
      //   link: "/transaksi/unit-cost",
      //   hak: ["SUPA", "COC_TRN_UNC"],
      // },
      {
        text: "Posting Stock Opname",
        link: "/transaksi/posting-stock-opname",
        hak: ["SUPA", "COC_TRN_PSO"],
      },
      {
        text: "Posting Petty Cash",
        link: "/transaksi/posting-petty-cash",
        hak: ["SUPA", "COC_TRN_PPC"],
      },
      {
        text: "Posting Realisasi PPA",
        link: "/transaksi/posting-realisasi-ppa",
        hak: ["SUPA", "COC_TRN_PRPPA"],
      },
      {
        text: "Monitoring Order",
        link: "/transaksi/monitoring-order",
        hak: ["SUPA", "COC_TRN_MTO"],
      },
      {
        text: "Transfer Overhead Work Order",
        link: "/transaksi/transfer-overhead-work-order",
        hak: ["SUPA", "COC_TRN_TWO"],
      },
      {
        text: "Posting Realisasi Work Order",
        link: "/transaksi/posting-realisasi-work-order",
        hak: ["SUPA", "COC_TRN_PRW"],
      },
      {
        text: "Transfer Overhead Produksi Aset",
        link: "/transaksi/transfer-overhead-produksi-aset",
        hak: ["SUPA", "COC_TRN_TOPA"],
      },
      {
        text: "WIP Produksi Aset",
        link: "/transaksi/wip-produksi-aset",
        hak: ["SUPA", "COC_TRN_WIP"],
      },
      {
        text: "Nilai Perolehan Produksi Aset",
        link: "/transaksi/nilai-perolehan-produksi-aset",
        hak: ["SUPA", "COC_TRN_NPA"],
      },
    ],
  },
  {
    // Laporan
    text: "Laporan",
    type: "dropdown",
    icon: <IoDocumentTextOutline />,
    hak: ["SUPA", "COC", "COC_RPT_LRI"],
    menu: [
      {
        text: "Laba Rugi Indikatif",
        link: "/laporan/proyeksi-laba-rugi",
        hak: ["SUPA", "COC_RPT_LRI"],
      },
      {
        text: "Akutansi Manajemen",
        link: "#",
        hak: ["SUPA", "COC_RPT_LAM"],
        menu: [
          {
            text: "Kumulatif Produksi",
            link: "/laporan/akutansi-manajemen",
            hak: ["SUPA", "COC_RPT_LAM"],
          },
          {
            text: "Rekapitulasi Pendapatan",
            link: "/laporan/rekapitulasi-pendapatan",
            hak: ["SUPA", "COC_RPT_LPD"],
          },
          {
            text: "Rekapitulasi Hasil Produksi",
            link: "/laporan/rekapitulasi-hasil-produksi",
            hak: ["SUPA", "COC_RPT_LPN"],
          },
        ],
      },
    ],
  },
];
