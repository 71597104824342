import React, {useState, useEffect} from 'react'
import { Formik } from 'formik'
import {
    FilterModal,
    DatePicker,
    SelectSearch
} from 'components'
import { DateConvert } from 'utilities'
import Axios from "axios"
import { RAEApi } from 'api'

const ModalFilter = ({ show, setShow, data, setData }) => {
// STATE DATA
    const [dataCustomer, setDataCustomer] = useState([{label: 'Semua', value: undefined}])
    const [dataUnitProduksi, setDataUnitProduksi] = useState([{label: 'Semua', value: undefined}])
    const [dataPeringkatPeluang, setDataPeringkatPeluang] = useState([{label: 'Semua', value: undefined}])
    const [dataProyek, setDataProyek] = useState([{label: 'Semua', value: undefined}])
    const [loading, setLoading] = useState({
        allDropdown: true,
        proyek: true
    })
// REQUSET DATA SERVER
    const getDataDropdown = () => {
        Axios.all([
          RAEApi.getDropdownPeringkatPeluang(),
          RAEApi.getDropdownCustomer(),
          RAEApi.getDropdownProyek(),
          RAEApi.getDropdownUnitProduksi()
        ])
        .then(Axios.spread((peringkat, customer, proyek, unit) => {
            const mapDataPeringkat = peringkat?.data?.data ? peringkat.data.data.map(val => ({label: val?.nama_peringkat_peluang, value: val?.id_peringkat_peluang})) : []
            const mapDataCustomer = customer?.data?.data ? customer.data.data.map(val => ({label: val?.nama_customer, value: val?.id_customer})) : []
            const mapDataProyek = proyek?.data?.data ? proyek.data.data.map(val => ({label: val?.nama_proyek, value: val?.id_proyek})) : []
            const mapDataUnit = unit?.data?.data ? unit?.data.data.map(val => ({label: val?.nama_unit_produksi, value: val?.id_unit_produksi})) : []

            setDataPeringkatPeluang([...dataPeringkatPeluang, ...mapDataPeringkat])
            setDataCustomer([...dataCustomer, ...mapDataCustomer])
            setDataProyek([{label: 'Semua', value: undefined}, ...mapDataProyek])
            setDataUnitProduksi([...dataUnitProduksi, ...mapDataUnit])
        }))
          .finally(() => {
            setLoading({
                ...loading,
                allDropdown: false,
                proyek: false
            })
          })
    }
// DROPDOWN PROYEK
    const getProyek = (data) => {
        setLoading({
            ...loading,
            proyek: true
        })
    
        RAEApi.getDropdownProyek({id_customer: data})
        .then(proyek => {
            const mapDataProyek = proyek?.data?.data ? proyek.data.data.map(val => ({label: val.nama_proyek, value: val.id_proyek})) : []
            setDataProyek([{label: 'Semua', value: undefined} ,...mapDataProyek])
        })
        .finally(() => {
            setLoading({
                ...loading,
                proyek: false
            })
        })
    }
// USE EFFECT ALL DROPDOWN
    useEffect(() => {
        getDataDropdown()
    }, [])
// USE EFFECT DROPDOWN PROYEK
    useEffect(() => {
        data.customer && getProyek(data.customer)
    }, [data.customer])
// FORM VALUES
    const formInitialValues = {
        tgl_peluang_mulai: data?.tgl_peluang_mulai,
        tgl_peluang_selesai: data?.tgl_peluang_selesai,
        peringkat_peluang: data?.peringkat_peluang,
        customer: data?.customer,
        proyek: data?.proyek,
        unit_produksi: data?.unit_produksi,
    }
    const formSubmitHandler = (values) => {
        const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

        if (checkActive) {
          setData({ ...values, active: true })
        } else { 
          setData({ ...values, active: false })
        }
    
        setShow(false)
    }
// HANDLE CHANGE
    const onTanggalPeluangChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_peluang_mulai: startDate,
            tgl_peluang_selesai: endDate
        })
    }
    const onResetButtonClick = (values, setValues) => {
        setValues({
            tgl_peluang_mulai: undefined,
            tgl_peluang_selesai: undefined,
            peringkat_peluang: undefined,
            customer: undefined,
            proyek: undefined,
            unit_produksi: undefined,
        })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={formSubmitHandler}>
            
            {({values, handleSubmit, setFieldValue, setValues}) => (
                <FilterModal
                    show={show}
                    setShow={setShow}
                    onResetButtonClick={() => onResetButtonClick(values, setValues)}
                    onFilterButtonClick={handleSubmit}>
                    
                    <DatePicker 
                        selectsRange
                        label="Tgl. Peluang"
                        placeholderText="Pilih tanggal peluang"
                        startDate={values.tgl_peluang_mulai ? new Date(values.tgl_peluang_mulai) : ''}
                        endDate={values.tgl_peluang_selesai ? new Date(values.tgl_peluang_selesai) : ''}
                        onChange={dates => onTanggalPeluangChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <SelectSearch 
                        key={values.peringkat_peluang}
                        label="Peringkat Peluang"
                        placeholder="Pilih proyek untuk memilih peringkat peluang"
                        defaultValue={dataPeringkatPeluang.find(val => val.value === values.peringkat_peluang)}
                        option={dataPeringkatPeluang}
                        onChange={val => setFieldValue('peringkat_peluang', val.value)}
                        loading={loading.allDropdown}
                    />
                    <SelectSearch 
                        key={values.customer}
                        label="Customer"
                        placeholder="Pilih customer"
                        defaultValue={dataCustomer.find(val => val.value === values.customer)}
                        option={dataCustomer}
                        onChange={val => {
                            setValues({
                                ...values,
                                customer: val.value,
                                proyek: undefined
                            })
                            getProyek(val.value, values, setValues)
                        }}
                        loading={loading.allDropdown}
                    />
                    <SelectSearch 
                        key={values.proyek}
                        label="Proyek"
                        placeholder="Pilih proyek"
                        defaultValue={dataProyek.find(val => val.value === values.proyek)}
                        option={dataProyek}
                        onChange={val => setFieldValue('proyek', val.value)}
                        loading={loading.proyek}
                    />
                    <SelectSearch 
                        key={values.unit_produksi}
                        label="Unit Produksi"
                        placeholder="Pilih unit produksi"
                        defaultValue={dataUnitProduksi.find(val => val.value === values.unit_produksi)}
                        option={dataUnitProduksi}
                        onChange={val => setFieldValue('unit_produksi', val.value)}
                        loading={loading.allDropdown}
                    />
                </FilterModal>
            )}
        </Formik>
    )
}
export default ModalFilter;