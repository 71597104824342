import { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  ActionButton,
  DatePicker,
  InfoItemHorizontal,
  InfoItemVertical,
  Input,
  SelectSearch,
  TextArea,
} from "components";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";
import { BDPProduksiApi } from "api";
import { generateNameHandler, getTotal } from "../Utils";

const decimalConvert = (value) => {
  const newValue = value.toString().replace(/[^0-9\.]/g, "");
  const convert = newValue.substring(0, 10);

  return convert;
};

const ModalDetail = ({ data = {}, removeStatus = false }) => {
  return (
    <>
      <Row>
        <Col md>
          <InfoItemVertical
            label="Tgl. BDP"
            text={
              data?.tgl_bdp ? DateConvert(new Date(data.tgl_bdp)).detail : "-"
            }
          />
        </Col>
        <Col md>
          <InfoItemVertical label="No. BDP" text={data?.no_bdp ?? "-"} />
        </Col>
      </Row>
      <InfoItemVertical
        label="Jumlah"
        text={RupiahConvert(getTotal(data).toString()).getWithComa}
      />
      <InfoItemVertical
        label="COA Debet"
        text={`${data?.nomor_akun_debet ?? ""} - ${
          data?.nama_akun_debet ?? ""
        }`}
      />
      <InfoItemVertical
        label="COA Kredit"
        text={`${data?.nomor_akun_kredit ?? ""} - ${
          data?.nama_akun_kredit ?? ""
        }`}
      />
      <InfoItemVertical
        label="Keterangan Jurnal"
        text={data?.keterangan ?? "-"}
      />
    </>
  );
};

const ModalForm = ({ type, data = {}, dataHistory = [] }) => {
  const generateName = generateNameHandler(type);
  const { values, errors, touched, setValues, setFieldValue } =
    useFormikContext();
  const [dataCOA, setDataCOA] = useState([]);
  const [loadingNomor, setLoadingNomor] = useState(true);
  const [loadingCOA, setLoadingCOA] = useState(true);

  const generateNomorHandler = (date) => {
    setLoadingNomor(true);

    const getNo = () =>
      type === "bahan"
        ? BDPProduksiApi.getNoBahan(values)
        : type === "upah"
        ? BDPProduksiApi.getNoUpah(values)
        : type === "alat_mesin"
        ? BDPProduksiApi.getNoAlatMesin(values)
        : type === "subkon"
        ? BDPProduksiApi.getNoSubkon(values)
        : type === "overhead"
        ? BDPProduksiApi.getNoOverhead(values)
        : new Promise((_res, rej) => rej("bukan type buaso"));

    getNo()
      .then((res) => {
        setFieldValue("no_bdp", res?.data?.data);
      })
      .catch(() => {
        setValues({
          ...values,
          tgl_bdp: "",
          no_bdp: "",
        });
      })
      .finally(() => {
        setLoadingNomor(false);
      });
  };

  const getDataCOA = () => {
    setLoadingCOA(true);

    BDPProduksiApi.getCOA()
      .then((res) => {
        const data = res?.data?.data?.map((val) => ({
          value: val?.nomor_akun,
          label: `${val.nomor_akun} - ${val.nama_akun}`,
          tipe: val?.normal_pos,
        }));

        setDataCOA(data ?? []);
      })
      .catch(() => {
        setDataCOA([]);
      })
      .finally(() => setLoadingCOA(false));
  };

  useEffect(() => {
    values?.tgl_bdp && generateNomorHandler(values.tgl_bdp);
    getDataCOA();
  }, []);

  return (
    <>
      <Row>
        <Col md>
          <DatePicker
            label="Tgl. BDP "
            placeholderText="Pilih tgl. BDP"
            selected={values?.tgl_bdp ? new Date(values?.tgl_bdp) : undefined}
            onChange={(date) => {
              const parseDate = DateConvert(new Date(date)).default;
              setFieldValue("tgl_bdp", parseDate);
              generateNomorHandler(parseDate);
            }}
            error={!!(errors?.tgl_bdp && touched?.tgl_bdp)}
            errorText={
              !!(errors?.tgl_bdp && touched?.tgl_bdp) && errors?.tgl_bdp
            }
          />
        </Col>
        <Col md>
          <Input
            label="No. BDP"
            value={loadingNomor ? "Memuat nomor . . ." : values?.no_bdp ?? ""}
            error={!!(errors?.no_bdp && touched?.no_bdp)}
            errorText={!!(errors?.no_bdp && touched?.no_bdp) && errors?.no_bdp}
            disabled
          />
        </Col>
      </Row>
      <Input
        label="Jumlah"
        className="text-right"
        value={RupiahConvert(getTotal(data).toString()).getWithComa}
        disabled
      />
      <SelectSearch
        label="COA Debet"
        placeholder="Pilih COA debet"
        loading={loadingCOA}
        defaultValue={dataCOA?.find(
          (val) => values.nomor_akun_debet === val.value
        )}
        onChange={(val) =>
          setFieldValue(
            `id_item_${type === "alat_mesin" ? "aset" : "buaso"}`,
            val.value
          )
        }
        option={dataCOA.filter((val) => val.tipe === "D")}
        error={!!(errors.nomor_akun_debet && touched.nomor_akun_debet)}
        errorText={
          !!(errors.nomor_akun_debet && touched.nomor_akun_debet) &&
          errors.nomor_akun_debet
        }
      />
      <SelectSearch
        label="COA Kredit"
        placeholder="Pilih COA Kredit"
        loading={loadingCOA}
        defaultValue={dataCOA?.find(
          (val) => values.nomor_akun_kredit === val.value
        )}
        onChange={(val) => setFieldValue("nomor_akun_kredit", val.value)}
        option={dataCOA.filter((val) => val.tipe === "K")}
        error={!!(errors.nomor_akun_kredit && touched.nomor_akun_kredit)}
        errorText={
          !!(errors.nomor_akun_kredit && touched.nomor_akun_kredit) &&
          errors.nomor_akun_kredit
        }
      />
      <TextArea
        label="Keterangan Jurnal"
        placeholder="Masukan keterangan jurnal"
        value={values.keterangan_jurnal}
        onChange={(e) => {
          const value = e.target.value;
          setFieldValue("keterangan_jurnal", value);
        }}
        error={!!(errors.keterangan_jurnal && touched.keterangan_jurnal)}
        errorText={
          !!(errors.keterangan_jurnal && touched.keterangan_jurnal) &&
          errors.keterangan_jurnal
        }
      />
    </>
  );
};

export const ModalBDPProduksi = ({
  show,
  onHide,
  data = {},
  dataHistory = [],
  type = "",
  action,
  alertSuccessTrigger,
  alertErrorTrigger,
  refreshHandler,
}) => {
  const generateName = generateNameHandler(type);

  const formInitialValues = {
    tgl_bdp: undefined,
    no_bdp: "",
    harga_satuan: data?.harga_satuan,
    nomor_akun_debet: "1010604",
    nomor_akun_kredit:
      type === "bahan"
        ? "1010601"
        : type === "upah"
        ? "2010102"
        : type === "alat_mesin"
        ? "1020302"
        : type === "subkon"
        ? "2010102"
        : type === "overhead"
        ? "2010399"
        : "",
    keterangan_jurnal: "",
  };

  const formValidationSchema = Yup.object().shape({
    tgl_bdp: Yup.string().required("Tgl. HPP tidak boleh kosong"),
    no_bdp: Yup.string().required("No. HPP tidak boleh kosong"),
    nomor_akun_debet: Yup.string().required("COA debet tidak boleh kosong"),
    nomor_akun_kredit: Yup.string().required("COA kredit tidak boleh kosong"),
    keterangan_jurnal: Yup.string().required(
      "Keterangan jurnal tidak boleh kosong"
    ),
  });

  const formSubmitHandler = (values) => {
    const finalValues = {
      [`tgl_bdp_${type}`]: values.tgl_bdp,
      [`id_realisasi_produksi_${type}`]: data?.id,
      [`harga_satuan_${type}`]: values.harga_satuan,
      nomor_akun_debet: values.nomor_akun_debet,
      nomor_akun_kredit: values.nomor_akun_kredit,
      keterangan_jurnal: values.keterangan_jurnal,
    };

    const saveData = () =>
      type === "bahan"
        ? BDPProduksiApi.saveBahan(finalValues)
        : type === "upah"
        ? BDPProduksiApi.saveUpah(finalValues)
        : type === "alat_mesin"
        ? BDPProduksiApi.saveAlatMesin(finalValues)
        : type === "subkon"
        ? BDPProduksiApi.saveSubkon(finalValues)
        : type === "overhead"
        ? BDPProduksiApi.saveOverhead(finalValues)
        : new Promise((_res, rej) => rej("bukan type buaso"));

    saveData()
      .then(() => {
        alertSuccessTrigger();
        refreshHandler();
      })
      .catch(() => {
        alertErrorTrigger();
      })
      .finally(() => {
        onHide();
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <b>
          {action === "form"
            ? `Tambah Data BDP ${generateName}`
            : action === "detail"
            ? `Detail Data BDP ${generateName}`
            : ""}
        </b>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={
          action === "confirm" ? undefined : formValidationSchema
        }
        onSubmit={formSubmitHandler}
      >
        {({ isSubmitting, handleSubmit }) => (
          <>
            <Modal.Body>
              <InfoItemHorizontal
                label="Tgl. Realisasi Produksi"
                text={
                  data?.tgl_realisasi
                    ? DateConvert(new Date(data.tgl_realisasi)).defaultDMY
                    : "-"
                }
              />
              <InfoItemHorizontal
                label="No. Realisasi Produksi"
                text={data?.no_realisasi ?? "-"}
              />
              <InfoItemHorizontal
                label={`Item ${generateName}`}
                text={data?.nama_item ?? "-"}
              />
              {type === "upah" || type === "subkon" ? (
                <InfoItemHorizontal
                  label="Vendor"
                  text={data?.nama_vendor ?? "-"}
                />
              ) : (
                <></>
              )}
              <InfoItemHorizontal label="Satuan" text={data?.satuan ?? "-"} />
              <InfoItemHorizontal
                label="Qty. Realisasi"
                text={
                  data?.qty_realisasi
                    ? DecimalConvert(data?.qty_realisasi).getWithComa
                    : "-"
                }
              />
              <InfoItemHorizontal
                label="Harga Satuan"
                text={
                  data?.harga_satuan
                    ? RupiahConvert(parseFloat(data?.harga_satuan).toString())
                        .getWithComa
                    : "-"
                }
              />
              <InfoItemHorizontal
                label="Proyek"
                text={data?.nama_proyek ?? "-"}
              />
              <hr />
              {action === "form" ? (
                <ModalForm
                  type={type}
                  data={data}
                  generateName={generateName}
                  dataHistory={dataHistory}
                />
              ) : action === "detail" ? (
                <ModalDetail
                  type={type}
                  data={data}
                  generateName={generateName}
                />
              ) : (
                <></>
              )}
            </Modal.Body>
            <Modal.Footer>
              <ActionButton
                variant="outline-secondary"
                text={action === "form" ? "Batal" : "Kembali"}
                onClick={onHide}
              />
              {action === "detail" ? (
                ""
              ) : (
                <ActionButton
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  variant={action === "confirm" ? "danger" : "primary"}
                  text={action === "confirm" ? "Batalkan" : "Simpan"}
                />
              )}
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
