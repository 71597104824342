export const STATUS_APPROVAL = {
  PEN: 'PENDING',
  VER: 'VERIFIED',
  APP: 'APPROVED',
  REV: 'REVISE',
  REJ: 'REJECT',
};

export const accImageTypes = ['jpg', 'jpeg', 'png'];

export const accDocTypes = ['pdf'];
