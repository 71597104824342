import Services from "services";

class TransferOverheadWorkOrderApi {
  page(params) {
    return Services.get("/transfer_work_order_overhead/page", { params });
  }

  preformPage(params) {
    return Services.get("/transfer_work_order_overhead/work_order", { params });
  }

  singlePermintaanPreform(params) {
    return Services.get("/transfer_work_order_overhead/detail_work_order", {
      params,
    });
  }

  singlePermintaan(params) {
    return Services.get("/transfer_work_order_overhead/permintaan", { params });
  }

  no_baru(params) {
    return Services.get("/transfer_work_order_overhead/no_baru", { params });
  }

  getDropdownOverhead(params) {
    return Services.get("/dropdown/item_overhead", { params });
  }

  getDropdownAset(params) {
    return Services.get("/dropdown/item_aset", { params });
  }

  save(data) {
    return Services.post("/transfer_work_order_overhead", data);
  }

  export(params) {
    return Services.get("/transfer_work_order_overhead/export/", { params });
  }
}

export default new TransferOverheadWorkOrderApi();
