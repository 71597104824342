import React from "react";
import { Card, Nav, Tab, Tabs } from "react-bootstrap";
import { TableBdp } from "./TableBdp";

export const TabComp = ({ data }) => {
  return (
    <Card className="p-0 m-0">
      <Tab.Container defaultActiveKey="bdpBahan">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="bdpBahan">BDP Bahan</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="bdpUpah">BDP Upah</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="bdpAlatMesin">BDP Alat Mesin</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="bdpSubkon">BDP Subkon</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="bdpOverhead">BDP Overhead</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Tab.Content>
          <Tab.Pane eventKey="bdpBahan">
            <TableBdp data={data?.bahan} type="Bahan" />
          </Tab.Pane>
          <Tab.Pane eventKey="bdpUpah">
            <TableBdp data={data?.upah} withVendor type="Upah" />
          </Tab.Pane>
          <Tab.Pane eventKey="bdpAlatMesin">
            <TableBdp data={data?.alatMesin} type="Alat Mesin" />
          </Tab.Pane>
          <Tab.Pane eventKey="bdpSubkon">
            <TableBdp data={data?.subkon} withVendor type="Subkon" />
          </Tab.Pane>
          <Tab.Pane eventKey="bdpOverhead">
            <TableBdp data={data?.overhead} type="Overhead" />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
};
