import Services from "services";

class HargaSatuanRataRataBarangJadiApi {
  page(params) {
    return Services.get("/hsr_barang_jadi/page", { params });
  }

  getHSRData(params) {
    return Services.get("hsr_barang_jadi/hsr", { params });
  }

  save(data) {
    return Services.post("/hsr_barang_jadi", data);
  }

  export(params) {
    return Services.get("/hsr_barang_jadi/export", { params });
  }
}

export default new HargaSatuanRataRataBarangJadiApi();
