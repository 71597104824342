import React, { useState, useContext } from "react";
import { Formik } from "formik";
import { DatePicker, FilterModal, SelectSearch } from "components";
import { DateConvert } from "utilities";
import { DropdownContext } from "../context";

export const ModalFilterPostingRealisasiWorkOrder = ({
  toggle,
  data,
  setData,
  buasoTitle,
  buaso,
}) => {
  const option = { label: "Semua Data", value: undefined };
  const [loading, setLoading] = useState(false);
  const { dropdownFilter } = useContext(DropdownContext);
  const optionAset = [option].concat(dropdownFilter?.data ?? []);

  const formInitialValues = {
    tgl_posting_realisasi_work_order_mulai:
      data?.[buaso]?.tgl_posting_realisasi_work_order_mulai,
    tgl_posting_realisasi_work_order_selesai:
      data?.[buaso]?.tgl_posting_realisasi_work_order_selesai,
    tgl_realisasi_work_order_mulai:
      data?.[buaso]?.tgl_realisasi_work_order_mulai,
    tgl_realisasi_work_order_selesai:
      data?.[buaso]?.tgl_realisasi_work_order_selesai,
    id_item: data?.[buaso]?.id_item,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setData({
      ...data,
      [buaso]: { ...values, active: checkActive, page: 1 },
    });

    toggle();
  };

  const onResetButtonClick = (setValues) => {
    setLoading(true);

    const myPromise = new Promise((resolve) => {
      setValues({
        tgl_posting_realisasi_work_order_mulai: undefined,
        tgl_posting_realisasi_work_order_selesai: undefined,
        tgl_realisasi_work_order_mulai: undefined,
        tgl_realisasi_work_order_selesai: undefined,
        id_item: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => setLoading(false));
  };

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          isToggle
          toggleModal={toggle}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label={`Tgl. Posting Realisasi ${buasoTitle[buaso]} Work Order`}
            placeholderText={`Pilih Tgl. Posting Realisasi ${buasoTitle[buaso]} Work Order`}
            startDate={
              values[`tgl_posting_realisasi_work_order_mulai`]
                ? new Date(values[`tgl_posting_realisasi_work_order_mulai`])
                : ""
            }
            endDate={
              values[`tgl_posting_realisasi_work_order_selesai`]
                ? new Date(values[`tgl_posting_realisasi_work_order_selesai`])
                : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                `tgl_posting_realisasi_work_order_mulai`,
                `tgl_posting_realisasi_work_order_selesai`
              )
            }
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Realisasi Work Order"
            placeholderText="Pilih Tgl. Realisasi Work Order"
            startDate={
              values[`tgl_realisasi_work_order_mulai`]
                ? new Date(values[`tgl_realisasi_work_order_mulai`])
                : ""
            }
            endDate={
              values[`tgl_realisasi_work_order_selesai`]
                ? new Date(values[`tgl_realisasi_work_order_selesai`])
                : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                `tgl_realisasi_work_order_mulai`,
                `tgl_realisasi_work_order_selesai`
              )
            }
            monthsShown={2}
          />

          <SelectSearch
            key="2"
            label={`Item ${buasoTitle[buaso]}`}
            placeholder={`Pilih Item ${buasoTitle[buaso]}`}
            defaultValue={optionAset.find(
              (val) => val.value === values.id_item
            )}
            option={optionAset}
            onChange={(val) => setFieldValue("id_item", val.value)}
            loading={dropdownFilter?.isLoading || loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
