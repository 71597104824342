import { useEffect, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import {
  CRUDLayout,
  Pagination,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
} from "components";
import { ButtonBack, ButtonCreate, DataStatus, InputSearch } from "components2";
import { dateConvert, rupiahConvert, tableNumber } from "utilities2";
import PostingPettyCashApi from "./__PostingPettyCashApi__";

export const PostingRealisasiPettyCashList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const [pagination, setPagination] = useState({
    q: "",
    page: 1,
    per_page: 10,
  });

  const { data, isError, isLoading } = useQuery(
    ["posting_petty_cash", "realisasi", "list", pagination],
    () => PostingPettyCashApi.getListRealisasi(pagination)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setPagination({
      ...pagination,
      q: searchKey,
    });
  };

  const onCreateButtonClickHandler = (id) =>
    history.push(`/transaksi/posting-petty-cash/tambah/${id}`);

  const onPaginationChange = ({ selected }) =>
    setPagination({ ...pagination, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setPagination({ ...pagination, page: 1, per_page: e.target.value });

  useEffect(() => setNavbarTitle("Posting Petty Cash"), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch onChange={debounce(searchHandler, 1500)} />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonBack size="md" onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {isLoading || isError ? (
        <DataStatus
          loading={isLoading}
          text={isLoading ? "Memuat . . ." : "Data gagal dimuat"}
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Realisasi Petty Cash
            </small>
          </div>
          <Table>
            <thead>
              <tr>
                <ThFixed>No.</ThFixed>
                <Th style={{ minWidth: 100 }} className="p-1">
                  Informasi Realisasi Petty Cash
                </Th>
                <Th style={{ minWidth: 100 }} className="p-1">
                  Informasi Penerimaan Petty Cash
                </Th>
                <Th style={{ minWidth: 150 }} className="p-1">
                  Pengaju Petty Cash
                </Th>
                <Th style={{ minWidth: 150 }} className="p-1">
                  Unit Produksi
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  Jumlah Pengeluaran Petty Cash
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  Sisa Petty Cash
                </Th>
                <ThFixed>Aksi</ThFixed>
              </tr>
            </thead>
            <tbody>
              {data?.data?.length > 0 ? (
                data?.data?.map((e, index) => (
                  <tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: pagination.page,
                        dataLength: pagination.per_page,
                        index,
                      })}
                    </TdFixed>
                    <Td>
                      <div>
                        {e.tgl_realisasi_petty_cash
                          ? dateConvert().getSlashDMY(
                              new Date(e.tgl_realisasi_petty_cash)
                            )
                          : "-"}
                      </div>
                      <div>{e.no_realisasi_petty_cash}</div>
                    </Td>
                    <Td>
                      <div>
                        {e.tgl_penerimaan_petty_cash
                          ? dateConvert().getSlashDMY(
                              new Date(e.tgl_penerimaan_petty_cash)
                            )
                          : "-"}
                      </div>
                      <div>{e.no_penerimaan_petty_cash}</div>
                    </Td>
                    <Td>{e.nama_karyawan_penerima ?? "-"}</Td>
                    <Td>{e.nama_unit_produksi}</Td>
                    <Td textRight>
                      {rupiahConvert().getWithComa(
                        e.nominal_realisasi_petty_cash
                      )}
                    </Td>
                    <Td textRight>
                      {rupiahConvert().getWithComa(
                        parseFloat(e.sisa_saldo_petty_cash ?? 0)
                      )}
                    </Td>
                    <TdFixed>
                      <ButtonGroup>
                        <ButtonCreate
                          icon
                          noText
                          onClick={() =>
                            onCreateButtonClickHandler(
                              e.id_realisasi_petty_cash
                            )
                          }
                        />
                      </ButtonGroup>
                    </TdFixed>
                  </tr>
                ))
              ) : (
                <tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
              )}
            </tbody>
          </Table>

          {data?.data?.length > 0 && (
            <Pagination
              dataLength={pagination?.per_page}
              dataNumber={
                pagination?.page * pagination?.per_page -
                pagination?.per_page +
                1
              }
              dataPage={
                pagination?.dataCount < pagination?.per_page
                  ? pagination?.dataCount
                  : pagination?.page * pagination?.per_page
              }
              dataCount={data?.data_count}
              currentPage={pagination?.page}
              totalPage={data?.total_page}
              onPaginationChange={onPaginationChange}
              onDataLengthChange={onPaginationDataLengthChange}
            />
          )}
        </>
      )}
    </CRUDLayout>
  );
};
