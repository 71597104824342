import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Dropdown } from "react-bootstrap";
import _, { debounce } from "lodash";
import {
  InputSearch,
  CRUDLayout,
  Alert,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  ReadButton,
  UpdateButton,
  DataStatus,
  Pagination,
  ActionButton,
  CreateButton,
  SelectMonth,
  Select,
} from "components";
import { ModalPosting, ExportButton } from "./Comps";
import { HargaSatuanRataRataApi } from "api";
import { DateConvert, RupiahConvert, TableNumber } from "utilities";
import FileSaver from "file-saver";

const HargaSatuanRataRataBahan = ({ setNavbarTitle }) => {
  const today = new Date();
  const history = useHistory();

  // STATE DATA
  const [dataHargaSatuan, setDataHargaSatuan] = useState([]);

  // CONFIG
  // const [searchConfig, setSearchConfig] = useState(undefined);
  const [searchTime, setSearchTime] = useState(null);
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [fetchConfig, setFetchConfig] = useState({
    loading: true,
    error: true,
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [paginationConfig, setPaginationConfig] = useState({
    page: 1,
    dataLength: 10,
    totalPage: 1,
    dataCount: 0,
  });

  const [loadingExport, setLoadingExport] = useState(false);

  // HANDLE DATA FILTER
  const [dataFilter, setDataFilter] = useState({
    month:
      String(today.getMonth() + 1).length > 1
        ? today.getMonth() + 1
        : `0${today.getMonth() + 1}`,
    year: today.getFullYear(),
  });

  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian:" + key,
        });
      }, 750)
    );
  };

  // REQUEST DATA SERVER
  const getInitialData = () => {
    setDataHargaSatuan([]);
    setFetchConfig({ loading: true, error: true });

    HargaSatuanRataRataApi.getPage({
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
      q: searchConfig.key,
      tanggal: `${dataFilter.year}-${dataFilter.month}-01`,
    })
      .then((res) => {
        setDataHargaSatuan(res?.data?.data ?? []);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: res?.data?.data_count,
          totalPage: res?.data?.total_page,
        });
        setFetchConfig({ loading: false, error: false });
      })
      .catch(() => {
        setDataHargaSatuan([]);
        setFetchConfig({ loading: false, error: true });
      });
  };

  const exportData = (tipe) => {
    setLoadingExport(true);

    HargaSatuanRataRataApi.export({
      tanggal: `${dataFilter.year}-${dataFilter.month}-01`,
      tipe,
    })
      .then((res) => {
        const data = res.data.data;
        // const urlFile = process.env.REACT_APP_EXPORT_BASE_URL + data;
        FileSaver.saveAs(data, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        });
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setNavbarTitle("Harga Satuan Rata-Rata Bahan");
    setAlertConfig({
      show: !!history?.location?.state?.alert?.show,
      variant: history?.location?.state?.alert?.variant,
      text: history?.location?.state?.alert?.text,
    });
  }, []);

  useEffect(() => {
    getInitialData();
  }, [
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
    dataFilter.month,
    dataFilter.year,
  ]);

  // TABLE LAYOUT
  const PageContent = () => {
    return (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Harga Satuan Rata-Rata Bahan</b>
        </span>
        <Table>
          <thead>
            <tr>
              <ThFixed>No.</ThFixed>
              <Th style={{ width: 100 }} className="p-1">
                Kode Item Bahan
              </Th>
              <Th style={{ minWidth: 300 }} className="p-1">
                Item Bahan
              </Th>
              <Th style={{ width: 150 }} className="p-1">
                Satuan
              </Th>
              <Th style={{ width: 150 }} className="p-1">
                Kelompok Bahan
              </Th>
              <Th style={{ width: 150 }} className="p-1">
                Jenis Bahan
              </Th>
              <Th style={{ width: 150 }} className="p-1">
                Harga Satuan Rata-Rata Bahan
              </Th>
            </tr>
          </thead>
          <tbody>
            {dataHargaSatuan && dataHargaSatuan?.length > 0 ? (
              dataHargaSatuan.map((val, index) => (
                <tr key={index}>
                  <TdFixed>
                    {TableNumber(
                      paginationConfig.page,
                      paginationConfig.dataLength,
                      index
                    )}
                  </TdFixed>
                  <Td>{val?.kode_item ?? "-"}</Td>
                  <Td>{val?.nama_item ?? "-"}</Td>
                  <Td>{val?.nama_satuan ?? "-"}</Td>
                  <Td>{val?.nama_kelompok ?? "-"}</Td>
                  <Td>{val?.nama_jenis ?? "-"}</Td>
                  <Td className="text-right">
                    {val?.harga_satuan
                      ? RupiahConvert(String(parseFloat(val?.harga_satuan)))
                          .getWithComa
                      : "-"}
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <Td colSpan={7}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          dataLength={paginationConfig?.dataLength}
          dataNumber={
            paginationConfig?.page * paginationConfig?.dataLength -
            paginationConfig?.dataLength +
            1
          }
          dataPage={
            paginationConfig?.dataCount < paginationConfig?.dataLength
              ? paginationConfig?.dataCount
              : paginationConfig?.page * paginationConfig?.dataLength
          }
          dataCount={paginationConfig?.dataCount}
          currentPage={paginationConfig?.page}
          totalPage={paginationConfig?.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearchChange} />
            <ExportButton
              loading={loadingExport}
              // disabled={!dataFilter?.filter?.active}
            >
              <Dropdown.Item onClick={() => exportData("excel")}>
                Excel (.xlsx)
              </Dropdown.Item>
            </ExportButton>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <div className="d-flex justify-content-end">
            <SelectMonth
              noMargin
              size="md"
              style={{ width: 150 }}
              defaultValue={dataFilter.month}
              onChange={(val) => {
                setDataFilter({ ...dataFilter, month: val.target.value });
                setPaginationConfig({
                  ...paginationConfig,
                  page: 1,
                });
              }}
            />
            <Select
              name="tahun"
              size="md"
              style={{ width: 100 }}
              wrapperClassName="mx-2"
              defaultValue={dataFilter.year}
              onChange={(val) => {
                setDataFilter({ ...dataFilter, year: val.target.value });
                setPaginationConfig({
                  ...paginationConfig,
                  page: 1,
                });
              }}
            >
              {_.range(today.getFullYear(), 1999).map((e) => (
                <option value={e}>{e}</option>
              ))}
            </Select>
            <div>
              <ActionButton
                text="Posting Data"
                className="px-4"
                onClick={() => setModalConfig({ show: true })}
              />
            </div>
          </div>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {/* Content */}
      {fetchConfig.loading || fetchConfig.error ? (
        <DataStatus
          loading={fetchConfig.loading}
          text={fetchConfig.loading ? "Memuat..." : "Data gagal dimuat"}
        />
      ) : (
        <PageContent />
      )}

      {/* Modal */}
      <ModalPosting
        show={modalConfig.show}
        onHide={() => setModalConfig({ ...modalConfig, show: false })}
        setAlert={setAlertConfig}
      />
    </CRUDLayout>
  );
};

export default HargaSatuanRataRataBahan;
