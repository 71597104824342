import React, { useState, useEffect } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { TableItemSumberDayaSection } from "../Section/TableItemSumberDayaSection";
import { PostingRealisasiPPAApi } from "api";
import Axios from "axios";
import {
  TableItemAsetSection,
  TableItemMaterialManusiaSection,
} from "../Section";

export const TabItemSumberDaya = ({
  type,
  coa,
  setAlertConfig,
  alertConfig,
}) => {
  return (
    <Card className="p-0 m-0">
      <Tab.Container defaultActiveKey="material">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="material">Sumber Daya Material</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="manusia">Sumber Daya Manusia</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="peralatan">Sumber Daya Peralatan</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="overhead">
                Sumber Daya Biaya Overhead
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="lainnya">Sumber Daya Lainnya</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Tab.Content>
          <Tab.Pane eventKey="material">
            <div className="py-3 px-2">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Material</b>
              </span>
              <TableItemMaterialManusiaSection
                dropdown={coa}
                tabType="material"
                type={type}
                alertConfig={alertConfig}
                setAlertConfig={setAlertConfig}
              />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="manusia">
            <div className="py-3 px-2">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Manusia</b>
              </span>
              <TableItemMaterialManusiaSection
                dropdown={coa}
                tabType="manusia"
                type={type}
                alertConfig={alertConfig}
                setAlertConfig={setAlertConfig}
              />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="peralatan">
            <div className="py-3 px-2">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Peralatan</b>
              </span>
              <TableItemAsetSection
                dropdown={coa}
                tabType="peralatan"
                type={type}
                alertConfig={alertConfig}
                setAlertConfig={setAlertConfig}
              />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="overhead">
            <div className="py-3 px-2">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Biaya Overhead</b>
              </span>
              <TableItemSumberDayaSection
                dropdown={coa}
                tabType="overhead"
                type={type}
                alertConfig={alertConfig}
                setAlertConfig={setAlertConfig}
              />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="lainnya">
            <div className="py-3 px-2">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Lainnya</b>
              </span>
              <TableItemSumberDayaSection
                dropdown={coa}
                tabType="lainnya"
                type={type}
                alertConfig={alertConfig}
                setAlertConfig={setAlertConfig}
              />
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
};
