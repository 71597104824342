import { ButtonBack, ButtonCancel } from "@bhawanadevteam/react-core";
import { InfoItemHorizontal, InfoItemVertical } from "components";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { DateConvert, DecimalConvert } from "utilities";

export const ModalDetail = ({ setModalConfig, modalConfig, tab }) => {
  return (
    <Modal
      show={modalConfig?.show}
      onHide={() => setModalConfig({ ...modalConfig, data: {}, show: false })}
    >
      <Modal.Header closeButton className="text-capitalize">
        Detail Data WIP {tab}
      </Modal.Header>
      <Modal.Body>
        <InfoItemHorizontal
          label="Tgl. Produksi Aset"
          text={
            DateConvert(new Date(modalConfig?.data?.tgl_produksi_aset)).detail
          }
        />
        <InfoItemHorizontal
          label="No. Produksi Aset"
          text={modalConfig?.data?.no_produksi_aset ?? "-"}
        />
        <InfoItemHorizontal
          label="Tgl. reaalisasi Produksi Aset"
          text={
            DateConvert(
              new Date(modalConfig?.data?.tgl_realisasi_produksi_aset)
            ).detail
          }
        />
        <InfoItemHorizontal
          label="No. Realisasi Produksi Aset"
          text={modalConfig?.data?.no_realisasi_produksi_aset ?? "-"}
        />
        <InfoItemHorizontal
          label="Grup Aset"
          text={modalConfig?.data?.nama_grup_aset ?? "-"}
        />
        <InfoItemHorizontal
          label="Kategori Aset"
          text={modalConfig?.data?.nama_kategori_aset ?? "-"}
        />
        <InfoItemHorizontal
          label="Jenis Aset"
          text={modalConfig?.data?.nama_jenis_aset ?? "-"}
        />
        <InfoItemHorizontal
          label="Item Aset Yang Di Produksi"
          text={modalConfig?.data?.nama_item ?? "-"}
        />
        <hr />
        <Row>
          <Col>
            <InfoItemVertical
              label="Tgl. WIP Produksi Aset"
              text={DateConvert(new Date(modalConfig?.data?.tgl_wip)).detail}
            />
            <InfoItemVertical
              label="COA Debet"
              text={`${modalConfig?.data?.nomor_akun_debet ?? "-"} - ${
                modalConfig?.data?.nama_akun_debet ?? "-"
              }`}
            />
            <InfoItemVertical
              label="COA Kredit"
              text={`${modalConfig?.data?.nomor_akun_kredit ?? "-"} - ${
                modalConfig?.data?.nama_akun_kredit ?? "-"
              }`}
            />
            <InfoItemVertical
              label="Keterangan Jurnal"
              text={modalConfig?.data?.keterangan_jurnal ?? "-"}
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="No. WIP Produksi Aset"
              text={modalConfig?.data?.no_wip ?? "-"}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonCancel
          text="Tutup"
          tooltipText="Tutup"
          onClick={() =>
            setModalConfig({ ...modalConfig, data: {}, show: false })
          }
        />
      </Modal.Footer>
    </Modal>
  );
};
