import { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { ActionButton, Alert, BackButton, DataStatus } from "components";
import { BDPProduksiApi } from "api";
import { InfoSection, TabItem } from "./Comps";

export const BDPProduksiCreate = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const paging = location.state.pagination ?? {};
  const [tab, setTab] = useState("bahan");
  const [dataDetail, setDataDetail] = useState([]);
  const [dataBahan, setDataBahan] = useState([]);
  const [dataUpah, setDataUpah] = useState([]);
  const [dataAlatMesin, setDataAlatMesin] = useState([]);
  const [dataSubkon, setDataSubkon] = useState([]);
  const [dataOverhead, setDataOverhead] = useState([]);
  const [dataHistory, setDataHistory] = useState({});
  const [alert, setAlert] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [fetching, setFetching] = useState({
    loading: true,
    error: false,
  });

  const alertSuccessTrigger = () =>
    setAlert({ show: true, variant: "primary", text: "Tambah BDP berhasil!" });

  const alertErrorsTrigger = () =>
    setAlert({ show: true, variant: "danger", text: "Tambah BDP gagal!" });

  const mapDataItem = (dataItem = []) =>
    dataItem
      ? dataItem.map((val) => ({
          id:
            val?.id_realisasi_produksi_bahan ??
            val?.id_realisasi_produksi_upah ??
            val?.id_realisasi_produksi_alat_mesin ??
            val?.id_realisasi_produksi_subkon ??
            val?.id_realisasi_produksi_overhead,
          tgl_realisasi: val?.tgl_realisasi_produksi,
          no_realisasi: val?.no_realisasi_produksi,
          nama_item: val?.nama_item_buaso ?? val?.nama_item,
          nama_vendor: val?.nama_vendor,
          satuan: val?.nama_satuan,
          qty_realisasi:
            val?.qty_realisasi_bahan ??
            val?.qty_realisasi_upah ??
            val?.qty_realisasi_alat_mesin ??
            val?.qty_realisasi_subkon ??
            val?.qty_realisasi_overhead,
          harga_satuan: val?.harga_satuan,
          nama_proyek: val?.nama_proyek,
          nama_akun: val?.nama_akun,
          nomor_akun: val?.nomor_akun,
        }))
      : [];

  const mapDataHistory = (dataItem = []) =>
    dataItem
      ? dataItem.map((val) => ({
          id:
            val?.id_bdp_bahan ??
            val.id_bdp_upah ??
            val.id_bdp_alat_mesin ??
            val.id_bdp_subkon ??
            val.id_bdp_overhead,
          id_realisasi:
            val?.id_realisasi_produksi_bahan ??
            val.id_realisasi_produksi_upah ??
            val.id_realisasi_produksi_alat_mesin ??
            val.id_realisasi_produksi_subkon ??
            val.id_realisasi_produksi_overhead,
          tgl_realisasi: val?.tgl_realisasi_produksi,
          no_realisasi: val?.no_realisasi_produksi,
          tgl_bdp:
            val?.tgl_bdp_bahan ??
            val.tgl_bdp_upah ??
            val.tgl_bdp_alat_mesin ??
            val.tgl_bdp_subkon ??
            val.tgl_bdp_overhead,
          no_bdp:
            val?.no_bdp_bahan ??
            val.no_bdp_upah ??
            val.no_bdp_alat_mesin ??
            val.no_bdp_subkon ??
            val.no_bdp_overhead,
          nama_item: val?.nama_item,
          nama_vendor: val?.nama_vendor,
          satuan: val?.nama_satuan,
          qty_realisasi:
            val?.qty_realisasi_bahan ??
            val.qty_realisasi_upah ??
            val.qty_realisasi_alat_mesin ??
            val.qty_realisasi_subkon ??
            val.qty_realisasi_overhead,
          harga_satuan:
            val?.harga_satuan_bahan ??
            val.harga_satuan_upah ??
            val.harga_satuan_alat_mesin ??
            val.harga_satuan_subkon ??
            val.harga_satuan_overhead,
          keterangan: val?.keterangan_jurnal,
          nomor_akun_debet: val?.nomor_akun_debet,
          nomor_akun_kredit: val?.nomor_akun_kredit,
          nama_akun_debet: val?.nama_akun_debit,
          nama_akun_kredit: val?.nama_akun_kredit,
          nama_proyek: val?.nama_proyek,
        }))
      : [];

  const getInitialData = () => {
    setFetching({
      loading: true,
      error: false,
    });

    Axios.all([
      BDPProduksiApi.getSingleRealisasi({ id_realisasi_produksi: id }),
      BDPProduksiApi.getHistory({ id_realisasi_produksi: id }),
    ])
      .then(
        Axios.spread((data, history) => {
          const getDataDetail = data?.data?.data ?? {};
          const getDataHistory = history?.data?.data ?? {};

          setDataDetail(getDataDetail);
          setDataBahan(mapDataItem(getDataDetail?.bahan));
          setDataUpah(mapDataItem(getDataDetail?.upah));
          setDataAlatMesin(mapDataItem(getDataDetail?.alat_mesin));
          setDataSubkon(mapDataItem(getDataDetail?.subkon));
          setDataOverhead(mapDataItem(getDataDetail?.overhead));
          setDataHistory({
            bahan: mapDataHistory(getDataHistory?.bahan),
            upah: mapDataHistory(getDataHistory?.upah),
            alat_mesin: mapDataHistory(getDataHistory?.alat_mesin),
            subkon: mapDataHistory(getDataHistory?.subkon),
            overhead: mapDataHistory(getDataHistory?.overhead),
          });
          setFetching({
            loading: false,
            error: false,
          });
        })
      )
      .catch(() => {
        setFetching({
          loading: false,
          error: true,
        });
      });
  };

  useEffect(() => {
    setNavbarTitle("BDP Produksi");
    getInitialData();
  }, []);

  if (fetching.loading || fetching.error) {
    return (
      <>
        <div className="d-flex justify-content-between align-items-end">
          <b className="px-2">Informasi BDP Produksi</b>
          <BackButton size="sm" onClick={history.goBack} />
        </div>
        <div className="my-3">
          <Alert
            showCloseButton
            show={alert.show}
            variant={alert.variant}
            text={alert.text}
            onClose={() => setAlert({ ...alert, show: false })}
          />
        </div>
        <DataStatus
          loading={fetching.loading}
          text={fetching.loading ? "Memuat data . . ." : "Data gagal dimuat"}
        />
      </>
    );
  }

  return (
    <>
      {/* Title */}
      <div className="d-flex justify-content-between align-items-end mb-2">
        <b className="px-2">Informasi BDP Produksi</b>
        <BackButton
          size="sm"
          onClick={() =>
            history.push("/transaksi/bdp/realisasi", location.state)
          }
        />
      </div>

      {/* Alert */}
      <Alert
        showCloseButton
        show={alert.show}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert({ ...alert, show: false })}
      />

      {/* Info Section */}
      <div className="p-3 bg-white rounded border">
        <InfoSection action="CREATE" data={dataDetail} />
      </div>

      {/* Tabs Section */}
      <div className="mt-4 mb-1">
        <b className="px-2">Data Realisasi Produksi</b>
      </div>
      <Tabs activeKey={tab} onSelect={(key) => setTab(key)} transition={false}>
        <Tab
          eventKey="bahan"
          title="BDP Bahan"
          children={
            <TabItem
              type="bahan"
              dataRealisasi={dataBahan}
              dataHistory={dataHistory?.bahan}
              setDataRealisasi={setDataBahan}
              alertSuccessTrigger={alertSuccessTrigger}
              alertErrorTrigger={alertErrorsTrigger}
              refreshHandler={getInitialData}
            />
          }
        />
        <Tab
          eventKey="upah"
          title="BDP Upah"
          children={
            <TabItem
              type="upah"
              dataRealisasi={dataUpah}
              dataHistory={dataHistory?.upah}
              setDataRealisasi={setDataUpah}
              alertSuccessTrigger={alertSuccessTrigger}
              alertErrorTrigger={alertErrorsTrigger}
              refreshHandler={getInitialData}
            />
          }
        />
        <Tab
          eventKey="alat_mesin"
          title="BDP Alat & Mesin"
          children={
            <TabItem
              type="alat_mesin"
              dataRealisasi={dataAlatMesin}
              dataHistory={dataHistory?.alat_mesin}
              setDataRealisasi={setDataAlatMesin}
              alertSuccessTrigger={alertSuccessTrigger}
              alertErrorTrigger={alertErrorsTrigger}
              refreshHandler={getInitialData}
            />
          }
        />
        <Tab
          eventKey="subkon"
          title="BDP Subkon"
          children={
            <TabItem
              type="subkon"
              dataRealisasi={dataSubkon}
              dataHistory={dataHistory?.subkon}
              setDataRealisasi={setDataSubkon}
              alertSuccessTrigger={alertSuccessTrigger}
              alertErrorTrigger={alertErrorsTrigger}
              refreshHandler={getInitialData}
            />
          }
        />
        <Tab
          eventKey="overhead"
          title="BDP Overhead"
          children={
            <TabItem
              type="overhead"
              dataRealisasi={dataOverhead}
              dataHistory={dataHistory?.overhead}
              setDataRealisasi={setDataOverhead}
              alertSuccessTrigger={alertSuccessTrigger}
              alertErrorTrigger={alertErrorsTrigger}
              refreshHandler={getInitialData}
            />
          }
        />
      </Tabs>
    </>
  );
};
