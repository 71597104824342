// React
import { useEffect, useState } from "react";

// API
import axios from "axios";
import { TransferOverheadJobMixApi } from "api";

// Components
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
} from "components";
import { Card } from "react-bootstrap";
import { DateConvert, DecimalConvert } from "utilities";

// Router
import { useHistory, useLocation, useParams } from "react-router-dom";

// View Components
import { InfoTambahSection, TableTransfer, TransferModal } from "./components";
import { Divider } from "./components/modals/DetailProduksi/components";

// Icons
import { IoAddOutline, IoCheckmarkOutline } from "react-icons/io5";

const TambahTransferOverheadJobMix = ({ setNavbarTitle }) => {
  // Variables
  const title = "Transfer Overhead Produksi";

  // Hooks
  const location = useLocation();
  const { id } = useParams();
  const history = useHistory();

  // States
  const [page, setPage] = useState({
    loading: true,
    status: false,
  });
  const [data, setData] = useState({});
  const [dataHistory, setDataHistory] = useState({});
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
    status: [],
  });

  useEffect(() => {
    setNavbarTitle(title);
    getData();

    // eslint-disable-next-line
  }, []);

  const getData = () => {
    setPage({
      loading: true,
      status: false,
    });

    axios
      .all([
        TransferOverheadJobMixApi.produksi_single(id),
        TransferOverheadJobMixApi.histori(id),
      ])
      .then(
        axios.spread((res, histori) => {
          setData(res.data.data);
          setDataHistory(histori.data.data);

          setPage({
            loading: false,
            status: true,
          });
        })
      )
      .catch(() => {
        setPage({
          loading: false,
          status: false,
        });
      });
  };

  const toggleModal = () => setModal(!modal);
  const toggleSuccess = (status) => {
    setAlertConfig({
      show: true,
      status: status,
    });
    getData();
  };

  return (
    <>
      <div className="text-right">
        <BackButton
          onClick={() =>
            history.push(
              "/transaksi/transfer-overhead-job-mix/list-permintaan-overhead",
              location.state
            )
          }
        />
      </div>
      <b>Tambah Data {title}</b>
      {alertConfig.show
        ? alertConfig?.status.map((val) => (
            <Alert
              show={val.status}
              variant={val.status ? "primary" : "danger"}
              text={
                val.status
                  ? `Tambah data ${val.no_ref} berhasil`
                  : `Tambah data ${val.no_ref} gagal`
              }
              showCloseButton={true}
              onClose={() => {
                setAlertConfig({
                  ...alertConfig,
                  show: false,
                });
              }}
            />
          ))
        : ""}
      {page.loading || !page.status ? (
        <DataStatus
          loading={page.loading}
          text={
            page.loading
              ? "Memuat data..."
              : "Data gagal dimuat, tidak dapat menampilkan data"
          }
        />
      ) : (
        <>
          <Divider>
            <InfoTambahSection data={data} />
          </Divider>

          <Divider>
            <div className="d-flex justify-content-end mb-3">
              <ActionButton
                text="Transfer"
                onClick={() => {
                  setModalData(data.permintaan);
                  toggleModal();
                }}
              />
            </div>
            <Card>
              <Card.Body>
                <small>List Data Permintaan Produksi</small>
                <Table responsive>
                  <thead>
                    <tr>
                      <ThFixed>No</ThFixed>
                      <ThFixed>Informasi Permintaan Overhead Produksi</ThFixed>
                      <Th>Item Overhead</Th>
                      <ThFixed>Satuan</ThFixed>
                      <ThFixed>Qty. Permintaan Produksi</ThFixed>
                      <ThFixed>Total Qty. Telah Ditransfer</ThFixed>
                      {/* <ThFixed>Aksi</ThFixed> */}
                    </tr>
                  </thead>

                  <tbody>
                    {data.permintaan.length === 0 ? (
                      <tr>
                        <Td colSpan={7}>
                          <DataStatus text="Tidak ada data" />
                        </Td>
                      </tr>
                    ) : (
                      data.permintaan.map((val, index) => {
                        // Variables
                        const qty_permintaan_produksi = parseFloat(
                          val.qty_permintaan_produksi ?? 0
                        );
                        const qty_transfer = parseFloat(val.qty_transfer ?? 0);
                        const notAvailable = Boolean(
                          qty_transfer >= qty_permintaan_produksi
                        );

                        return (
                          <tr key={index}>
                            <TdFixed>{index + 1}</TdFixed>
                            <Td>
                              <div>
                                {val.tgl_permintaan_produksi_overhead
                                  ? DateConvert(
                                      new Date(
                                        val.tgl_permintaan_produksi_overhead
                                      )
                                    ).defaultDMY
                                  : "-"}
                              </div>
                              <div>
                                {val.no_permintaan_produksi_overhead ?? "-"}
                              </div>
                            </Td>
                            <Td>{val.nama_item ?? "-"}</Td>
                            <Td>{val.kode_satuan ?? "-"}</Td>
                            <Td className="text-right">
                              {
                                DecimalConvert(qty_permintaan_produksi)
                                  .getWithComa
                              }
                            </Td>
                            <Td className="text-right">
                              {DecimalConvert(qty_transfer).getWithComa}
                            </Td>
                            {/* <TdFixed>
                              <ActionButton
                                text={
                                  notAvailable ? (
                                    <IoCheckmarkOutline size={12} />
                                  ) : (
                                    <IoAddOutline size={12} />
                                  )
                                }
                                variant={notAvailable ? "success" : "primary"}
                                className="my-1"
                                size="sm"
                                onClick={() => {
                                  // if (!notAvailable) {
                                  setModalData(val);
                                  toggleModal();
                                  // } else {
                                  //   return null;
                                  // }
                                }}
                              />
                            </TdFixed> */}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>

                <small>History {title}</small>
                <TableTransfer data={dataHistory} title={title} />
              </Card.Body>
            </Card>
          </Divider>
        </>
      )}
      {modal && (
        <TransferModal
          data={modalData}
          dataPermintaan={data?.permintaan}
          toggle={toggleModal}
          title={title}
          toggleSuccess={toggleSuccess}
          isCreate
        />
      )}
    </>
  );
};

export default TambahTransferOverheadJobMix;
