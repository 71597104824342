// Components
import { Col, Row } from "react-bootstrap"

// Form
import { useFormikContext } from "formik"

const TabKonversiDetail = () => {
    const { values } = useFormikContext()

    return (
        <Row>
            <Col lg={6}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <small>Satuan Beli</small>
                            </td>
                            <td />
                            <td>
                                <small>Satuan Pakai</small>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right">
                                <b>1 {values.kode_satuan_beli ?? '-'}</b>
                            </td>
                            <td style={{ width: '25px' }} className="text-center"> = </td>
                            <td>
                                <b>{values.nilai_konversi ?? '-'} {values.kode_satuan_pakai ?? '-'}</b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Col>
        </Row>
    )
}

export default TabKonversiDetail