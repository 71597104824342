import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { DateConvert } from "utilities";
import { ModalPeluang } from "../Section";

const InfoSection = ({ data }) => {
  const [modalPeluangConfig, setModalPeluangConfig] = useState({
    show: false,
    data_peluang: data,
  });

  const InfoItem = ({ title, value, link, onClick }) => (
    <tr onClick={onClick}>
      <td>{title}</td>
      <td className={`pl-4 pr-2`}>:</td>
      <td
        className={link ? "text-primary" : ""}
        style={{ cursor: link ? "pointer" : "default" }}
      >
        {value}
      </td>
    </tr>
  );

  return (
    <>
      <Row>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Tgl. Peluang"
                value={
                  data.tgl_peluang
                    ? DateConvert(new Date(data.tgl_peluang)).detail
                    : "-"
                }
              />
              <InfoItem
                link
                title="No. Peluang"
                value={data?.no_peluang ?? "-"}
                onClick={() =>
                  setModalPeluangConfig({
                    show: true,
                    data_peluang: data,
                  })
                }
              />
              <InfoItem
                title="Peringkat Peluang"
                value={data?.nama_peringkat_peluang ?? "-"}
              />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem title="Customer" value={data?.nama_customer ?? "-"} />
              <InfoItem title="ATT" value={data?.att ?? "-"} />
              <InfoItem title="Proyek" value={data?.nama_proyek ?? "-"} />
            </tbody>
          </table>
        </Col>
      </Row>

      {modalPeluangConfig.show && (
        <ModalPeluang
          modalPeluangConfig={modalPeluangConfig}
          setModalPeluangConfig={setModalPeluangConfig}
        />
      )}
    </>
  );
};

export default InfoSection;
