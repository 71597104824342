import { ActionButton, TextArea } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";

export const ModalCatatanRevisi = ({ setShow, show }) => {
  const { values, handleSubmit, handleChange } = useFormikContext();
  console.log("bos", values);
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>Revisi Realisasi Petty Cash</Modal.Header>
        <Modal.Body>
          <TextArea
            label="Catatan Revisi"
            name="catatan_revisi"
            rows={5}
            value={values.catatan_revisi}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <ActionButton
            size="md"
            variant="outline-secondary"
            className="px-4 mr-2"
            text="Batal"
            onClick={() => setShow(false)}
          />
          <ActionButton
            size="md"
            variant="warning"
            className="text-white px-4 mr-2"
            text="Revisi"
            disable={!values?.catatan_revisi?.length}
            onClick={handleSubmit}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
