import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { debounce } from "lodash";
import {
  CRUDLayout,
  InputSearch,
  Alert,
  DataStatus,
  Pagination,
  CreateButton,
} from "components";
import { BDPProduksiApi } from "api";
import { TableBDPProduksiList } from "./Comps";

export const BDPProduksiList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paging = location?.state?.pagination ?? {};
  const [data, setData] = useState([]);
  // const [search, setSearch] = useState(undefined);
  const [tab, setTab] = useState("bahan");
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    error: true,
  });
  const [alert, setAlert] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [searchConfig, setSearchConfig] = useState({
    bahan: "",
    upah: "",
    alat_mesin: "",
    subkon: "",
    overhead: "",
  });

  const [searchTime, setSearchTime] = useState(null);

  const [pagination, setPagination] = useState({
    page: paging?.pre?.page ?? 1,
    dataLength: paging?.pre?.dataLength ?? 10,
    totalPage: paging?.pre?.totalPage ?? 1,
    dataCount: paging?.pre?.dataCount ?? 0,
  });

  const [dataFilter, setDataFilter] = useState({
    bahan: {
      active: false,
      tgl_realisasi_produksi_mulai: undefined,
      tgl_realisasi_produksi_selesai: undefined,
      tgl_bdp_bahan_mulai: undefined,
      tgl_bdp_bahan_selesai: undefined,
      id_item: undefined,
    },
    upah: {
      active: false,
      tgl_realisasi_produksi_mulai: undefined,
      tgl_realisasi_produksi_selesai: undefined,
      tgl_bdp_upah_mulai: undefined,
      tgl_bdp_upah_selesai: undefined,
      id_item: undefined,
    },
    alat_mesin: {
      active: false,
      tgl_realisasi_produksi_mulai: undefined,
      tgl_realisasi_produksi_selesai: undefined,
      tgl_bdp_alat_mesin_mulai: undefined,
      tgl_bdp_alat_mesin_selesai: undefined,
      id_item: undefined,
    },
    subkon: {
      active: false,
      tgl_realisasi_produksi_mulai: undefined,
      tgl_realisasi_produksi_selesai: undefined,
      tgl_bdp_subkon_mulai: undefined,
      tgl_bdp_subkon_selesai: undefined,
      id_item: undefined,
    },
    overhead: {
      active: false,
      tgl_realisasi_produksi_mulai: undefined,
      tgl_realisasi_produksi_selesai: undefined,
      tgl_bdp_overhead_mulai: undefined,
      tgl_bdp_overhead_selesai: undefined,
      id_item: undefined,
    },
  });

  const mapDataHandler = (data = [], type) =>
    data.map((val) => ({
      id:
        val?.id_bdp_bahan ??
        val.id_bdp_upah ??
        val.id_bdp_alat_mesin ??
        val.id_bdp_subkon ??
        val.id_bdp_overhead,
      id_realisasi:
        val?.id_realisasi_produksi_bahan ??
        val.id_realisasi_produksi_upah ??
        val.id_realisasi_produksi_alat_mesin ??
        val.id_realisasi_produksi_subkon ??
        val.id_realisasi_produksi_overhead,
      tgl_bdp:
        val?.tgl_bdp_bahan ??
        val.tgl_bdp_upah ??
        val.tgl_bdp_alat_mesin ??
        val.tgl_bdp_subkon ??
        val.tgl_bdp_overhead,
      no_bdp:
        val?.no_bdp_bahan ??
        val.no_bdp_upah ??
        val.no_bdp_alat_mesin ??
        val.no_bdp_subkon ??
        val.no_bdp_overhead,
      tgl_realisasi: val?.tgl_realisasi_produksi,
      no_realisasi: val?.no_realisasi_produksi,
      nama_item: val?.nama_item,
      nama_vendor: val?.nama_vendor,
      satuan: val?.nama_satuan,
      keterangan: val?.keterangan_jurnal,
      nomor_akun_debet: val?.nomor_akun_debet,
      nomor_akun_kredit: val?.nomor_akun_kredit,
      nama_akun_debet: val?.nama_akun_debit,
      nama_akun_kredit: val?.nama_akun_kredit,
      nama_proyek: val?.nama_proyek,
      tgl_realisasi_produksi: val?.tgl_realisasi_produksi,
      no_realisasi_produksi: val?.no_realisasi_produksi,
      harga_satuan:
        val?.harga_satuan_bahan ??
        val.harga_satuan_upah ??
        val.harga_satuan_alat_mesin ??
        val.harga_satuan_subkon ??
        val.harga_satuan_overhead,
      qty_realisasi:
        val?.qty_realisasi_bahan ??
        val.qty_realisasi_upah ??
        val.qty_realisasi_alat_mesin ??
        val.qty_realisasi_subkon ??
        val.qty_realisasi_overhead,
    }));

  const getInitialData = () => {
    setData([]);
    setFetchingStatus({
      loading: true,
      error: true,
    });

    const fetchParams = {
      page: pagination.page,
      per_page: pagination.dataLength,
    };

    const fetchData = () =>
      tab === "bahan"
        ? BDPProduksiApi.getBahan({
            ...fetchParams,
            ...dataFilter?.bahan,
            q: searchConfig?.bahan,
          })
        : tab === "upah"
        ? BDPProduksiApi.getUpah({
            ...fetchParams,
            ...dataFilter?.upah,
            q: searchConfig?.upah,
          })
        : tab === "alat_mesin"
        ? BDPProduksiApi.getAlatMesin({
            ...fetchParams,
            ...dataFilter?.alat_mesin,
            q: searchConfig?.alat_mesin,
          })
        : tab === "subkon"
        ? BDPProduksiApi.getSubkon({
            ...fetchParams,
            ...dataFilter?.subkon,
            q: searchConfig?.subkon,
          })
        : tab === "overhead"
        ? BDPProduksiApi.getOverhead({
            ...fetchParams,
            ...dataFilter?.overhead,
            q: searchConfig?.overhead,
          })
        : new Promise((_res, rej) => rej("bukan type buaso"));

    fetchData()
      .then((res) => {
        setData(mapDataHandler(res?.data?.data ?? [], tab));
        setPagination({
          ...pagination,
          dataCount: res?.data?.data_count,
          totalPage: res?.data?.total_page,
        });
        setFetchingStatus({
          loading: false,
          error: false,
        });
      })
      .catch(() => {
        setData([]);
        setFetchingStatus({
          loading: false,
          error: true,
        });
      });
  };

  // const searchHandler = (e) => {
  //   setSearch(e.target.value);
  // };

  useEffect(() => {
    setNavbarTitle("BDP Produksi");
    setAlert({
      show: !!history?.location?.state?.alert?.show,
      variant: history?.location?.state?.alert?.variant,
      text: history?.location?.state?.alert?.text,
    });
  }, []);

  useEffect(() => {
    getInitialData();
  }, [
    tab,
    searchConfig.bahan,
    searchConfig.upah,
    searchConfig.alat_mesin,
    searchConfig.subkon,
    searchConfig.overhead,
    dataFilter?.bahan?.tgl_realisasi_produksi_mulai,
    dataFilter?.bahan?.tgl_realisasi_produksi_selesai,
    dataFilter?.bahan?.tgl_bdp_bahan_mulai,
    dataFilter?.bahan?.tgl_bdp_bahan_selesai,
    dataFilter?.bahan?.id_item,
    dataFilter?.upah?.tgl_realisasi_produksi_mulai,
    dataFilter?.upah?.tgl_realisasi_produksi_selesai,
    dataFilter?.upah?.tgl_bdp_upah_mulai,
    dataFilter?.upah?.tgl_bdp_upah_selesai,
    dataFilter?.upah?.id_item,
    dataFilter?.alat_mesin?.tgl_realisasi_produksi_mulai,
    dataFilter?.alat_mesin?.tgl_realisasi_produksi_selesai,
    dataFilter?.alat_mesin?.tgl_bdp_alat_masin_mulai,
    dataFilter?.alat_mesin?.tgl_bdp_alat_mesin_selesai,
    dataFilter?.alat_mesin?.id_item,
    dataFilter?.subkon?.tgl_realisasi_produksi_mulai,
    dataFilter?.subkon?.tgl_realisasi_produksi_selesai,
    dataFilter?.subkon?.tgl_bdp_subkon_mulai,
    dataFilter?.subkon?.tgl_bdp_subkon_selesai,
    dataFilter?.subkon?.id_item,
    dataFilter?.overhead?.tgl_realisasi_produksi_mulai,
    dataFilter?.overhead?.tgl_realisasi_produksi_selesai,
    dataFilter?.overhead?.tgl_bdp_overhead_mulai,
    dataFilter?.overhead?.tgl_bdp_overhead_selesai,
    dataFilter?.overhead?.id_item,
    pagination.page,
    pagination.dataLength,
  ]);

  return (
    <CRUDLayout>
      {/* Head */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            {/* <InputSearch onChange={debounce(searchHandler, 800)} /> */}
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/transaksi/bdp/realisasi", {
                pagination: {
                  ...paging,
                  pre: pagination,
                },
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert show={alert.show} variant={alert.variant} text={alert.text} />

      {/* Content */}
      <>
        <div className="my-1">
          <Tabs
            defaultActiveKey={tab}
            transition={false}
            onSelect={(e) => {
              setTab(e);
              setPagination({
                ...pagination,
                page: 1,
              });
            }}
          >
            <Tab title="Bahan" eventKey="bahan">
              <TableBDPProduksiList
                data={data ?? []}
                fetchingStatus={fetchingStatus}
                pagination={pagination}
                setPagination={setPagination}
                type={tab}
                searchConfig={searchConfig}
                setSearchConfig={setSearchConfig}
                searchTime={searchTime}
                setSearchTime={setSearchTime}
                setDataFilter={setDataFilter}
                dataFilter={dataFilter}
              />
            </Tab>
            <Tab title="Upah" eventKey="upah">
              <TableBDPProduksiList
                data={data ?? []}
                fetchingStatus={fetchingStatus}
                pagination={pagination}
                setPagination={setPagination}
                type={tab}
                searchConfig={searchConfig}
                setSearchConfig={setSearchConfig}
                searchTime={searchTime}
                setSearchTime={setSearchTime}
                setDataFilter={setDataFilter}
                dataFilter={dataFilter}
              />
            </Tab>
            <Tab title="Alat & Mesin" eventKey="alat_mesin">
              <TableBDPProduksiList
                data={data ?? []}
                fetchingStatus={fetchingStatus}
                pagination={pagination}
                setPagination={setPagination}
                type={tab}
                searchConfig={searchConfig}
                setSearchConfig={setSearchConfig}
                searchTime={searchTime}
                setSearchTime={setSearchTime}
                setDataFilter={setDataFilter}
                dataFilter={dataFilter}
              />
            </Tab>
            <Tab title="Subkon" eventKey="subkon">
              <TableBDPProduksiList
                data={data ?? []}
                fetchingStatus={fetchingStatus}
                pagination={pagination}
                setPagination={setPagination}
                type={tab}
                searchConfig={searchConfig}
                setSearchConfig={setSearchConfig}
                searchTime={searchTime}
                setSearchTime={setSearchTime}
                setDataFilter={setDataFilter}
                dataFilter={dataFilter}
              />
            </Tab>
            <Tab title="Overhead" eventKey="overhead">
              <TableBDPProduksiList
                data={data ?? []}
                fetchingStatus={fetchingStatus}
                pagination={pagination}
                setPagination={setPagination}
                type={tab}
                searchConfig={searchConfig}
                setSearchConfig={setSearchConfig}
                searchTime={searchTime}
                setSearchTime={setSearchTime}
                setDataFilter={setDataFilter}
                dataFilter={dataFilter}
              />
            </Tab>
          </Tabs>
        </div>
      </>
    </CRUDLayout>
  );
};
