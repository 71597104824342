import { useContext } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import {
  DatePicker,
  InfoItemVertical,
  Input,
  SelectSearch,
  TextArea,
  CRUDLayout,
  THead,
  TBody,
  Td,
  Th,
  Tr,
  ThFixed,
  TdFixed,
  DataStatus,
  ActionButton,
  InputCurrency,
} from 'components';
import { DateConvert, DecimalConvert, RupiahConvert } from 'utilities';
import { ModalHeadSection } from './ModalHeadSection';
import {
  PostingRealisasiContext,
  DropdownContext,
  withDropdownProvider,
} from '../context';
import { calcTotalSparepart, getTotal } from '../utils';
import { PostingRealisasiWorkOrderApi } from 'api';

export const ModalFormUpahSubkon = withDropdownProvider(
  ({ buaso, show, isCreate, onHide, onSubmitSuccess, onSubmitFailed }) => {
    const { modalFormPosting } = useContext(PostingRealisasiContext);
    const { data } = modalFormPosting;

    const spareparts = data?.sparepart ?? data?.[`sparepart_${buaso}`] ?? [];

    const totalSparepart = calcTotalSparepart(spareparts, buaso);

    const jumlah =
      totalSparepart +
      (parseFloat(data?.harga_satuan ?? 0) * data?.[`qty_realisasi_${buaso}`] ??
        0);

    const initialValues = {
      ...data,
      [`tgl_posting_realisasi_work_order_${buaso}`]:
        data?.[`tgl_posting_realisasi_work_order_${buaso}`] ?? '',
      [`no_posting_realisasi_work_order_${buaso}`]:
        data?.[`no_posting_realisasi_work_order_${buaso}`] ?? '',
      [`harga_satuan_${buaso}`]: jumlah,
      nomor_akun_debet: data?.nomor_akun_debet ?? '',
      nomor_akun_kredit: data?.nomor_akun_kredit ?? '',
      keterangan_jurnal: data?.keterangan_jurnal ?? '',
      totalSparepart,
    };

    const validationSchema = yup.object().shape({
      [`tgl_posting_realisasi_work_order_${buaso}`]: yup
        .string()
        .required('Tanggal harus diisi'),
      nomor_akun_debet: yup.string().required('Nomor Akun Debet harus diisi'),
      nomor_akun_kredit: yup.string().required('Nomor Akun Kredit harus diisi'),
      keterangan_jurnal: yup.string().required('Keterangan Jurnal harus diisi'),
    });

    const onSubmit = (values) => {
      const finalData = {
        [buaso]: values,
      };

      PostingRealisasiWorkOrderApi.save(finalData)
        .then(() => {
          onSubmitSuccess();
          onHide();
        })
        .catch(() => onSubmitFailed());
    };

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit }) => {
          return (
            <Modal scrollable show={show} onHide={onHide} size="lg">
              <Modal.Header closeButton>
                {isCreate ? 'Tambah ' : 'Detail '} Data Posting Realisasi Work
                Order
              </Modal.Header>

              <Modal.Body>
                <ModalHeadSection buaso={buaso} data={values} />

                <TableRealisasiSparePart
                  buaso={buaso}
                  data={spareparts}
                  totalSparepart={totalSparepart}
                />

                {isCreate ? (
                  <FormBody buaso={buaso} />
                ) : (
                  <DetailBody buaso={buaso} />
                )}
              </Modal.Body>

              <Modal.Footer>
                <Row>
                  <ActionButton variant="light" onClick={onHide}>
                    Kembali
                  </ActionButton>

                  {isCreate && (
                    <ActionButton className="ml-2" onClick={handleSubmit}>
                      Simpan
                    </ActionButton>
                  )}
                </Row>
              </Modal.Footer>
            </Modal>
          );
        }}
      </Formik>
    );
  },
);

export const FormBody = ({ buaso }) => {
  const { dropdownCOA } = useContext(DropdownContext);
  const { values, setFieldValue, errors, touched } = useFormikContext();

  const getNomorHandler = (tanggal) => {
    PostingRealisasiWorkOrderApi.getNoBaru({
      tanggal,
      buaso,
    })
      .then((res) => {
        setFieldValue(
          `no_posting_realisasi_work_order_${buaso}`,
          res.data.data,
        );
      })
      .catch(() => {
        window.alert('Gagal memuat nomor baru!');
      });
  };

  return (
    <>
      <Row>
        <Col md={4}>
          <DatePicker
            label="Tgl. Posting Realisasi Work Order"
            selected={
              values?.[`tgl_posting_realisasi_work_order_${buaso}`] &&
              new Date(values?.[`tgl_posting_realisasi_work_order_${buaso}`])
            }
            onChange={(date) => {
              const parseDate = DateConvert(date).default;

              setFieldValue(
                `tgl_posting_realisasi_work_order_${buaso}`,
                parseDate,
              );
              getNomorHandler(parseDate);
            }}
            error={Boolean(
              errors?.[`tgl_posting_realisasi_work_order_${buaso}`] &&
                touched?.[`tgl_posting_realisasi_work_order_${buaso}`],
            )}
            errorText={errors?.[`tgl_posting_realisasi_work_order_${buaso}`]}
          />
        </Col>

        <Col md={4}>
          <Input
            readOnly
            label="No. Posting Realisasi Work Order"
            placeholder={
              !values[`no_posting_realisasi_work_order_${buaso}`] &&
              'Pilih Tangga Terlebih Dahulu'
            }
            value={values[`no_posting_realisasi_work_order_${buaso}`]}
          />
        </Col>

        <Col>
          <InputCurrency
            readOnly
            label="Jumlah"
            className="text-right"
            value={values?.[`harga_satuan_${buaso}`]}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <SelectSearch
            label="COA Debet"
            placeholder="Pilih COA Debet"
            option={dropdownCOA?.data ?? []}
            onChange={(e) => {
              setFieldValue(`nomor_akun_debet`, e.value);
            }}
            error={errors.nomor_akun_debet && touched.nomor_akun_debet}
            errorText={errors.nomor_akun_debet}
          />
        </Col>

        <Col md={6}>
          <SelectSearch
            label="COA Kredit"
            placeholder="Pilih COA Kredit"
            option={dropdownCOA?.data ?? []}
            onChange={(e) => {
              setFieldValue(`nomor_akun_kredit`, e.value);
            }}
            error={errors.nomor_akun_kredit && touched.nomor_akun_kredit}
            errorText={errors.nomor_akun_kredit}
          />
        </Col>
      </Row>

      <TextArea
        label="Keterangan"
        onChange={(e) => {
          setFieldValue(`keterangan_jurnal`, e.target.value);
        }}
        error={errors.keterangan_jurnal && touched.keterangan_jurnal}
        errorText={errors.keterangan_jurnal}
      />
    </>
  );
};

export const DetailBody = ({ buaso }) => {
  const { values } = useFormikContext();

  return (
    <>
      <Row>
        <Col>
          <InfoItemVertical
            label="Tgl. Posting Realisasi Work Order"
            text={
              values[`tgl_posting_realisasi_work_order_${buaso}`]
                ? DateConvert(
                    new Date(
                      values[`tgl_posting_realisasi_work_order_${buaso}`],
                    ),
                  ).detail
                : '-'
            }
          />
        </Col>

        <Col>
          <InfoItemVertical
            label="No. Posting Realisasi Work Order"
            text={values?.[`no_posting_realisasi_work_order_${buaso}`]}
          />
        </Col>

        <Col>
          <InfoItemVertical
            label="Jumlah"
            text={
              RupiahConvert(
                String(
                  parseFloat(values?.totalSparepart) +
                    parseFloat(values?.harga_satuan ?? 0),
                ),
              ).getWithComa
            }
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <InfoItemVertical
            label="COA Debet"
            text={`${values.nomor_akun_debet} - ${values.nama_akun_debet}`}
          />
        </Col>

        <Col>
          <InfoItemVertical
            label="COA Kredit"
            text={`${values.nomor_akun_kredit} - ${values.nama_akun_kredit}`}
          />
        </Col>
      </Row>

      <InfoItemVertical label="Keterangan" text={values.keterangan_jurnal} />
    </>
  );
};

export const TableRealisasiSparePart = ({
  buaso,
  data = [],
  totalSparepart,
}) => {
  return (
    <CRUDLayout.Table>
      <THead>
        <Tr>
          <ThFixed>No.</ThFixed>
          <Th>Item Sparepart</Th>
          <Th width={100}>Qty.</Th>
          <Th width={100}>Satuan</Th>
          <Th width={100}>Harga Satuan</Th>
          <Th width={100}>Jumlah</Th>
        </Tr>
      </THead>
      <TBody>
        {!!data?.length ? (
          data?.map((val, index) => (
            <Tr key={index}>
              <TdFixed textCenter>{index + 1} </TdFixed>

              <Td>{val.nama_item ?? '-'}</Td>

              <Td>
                {DecimalConvert(val[`qty_realisasi_sparepart_${buaso}`])
                  .getWithComa ?? '-'}
              </Td>

              <Td>{val.nama_satuan ?? '-'}</Td>

              <Td className="text-right">
                {
                  RupiahConvert(
                    parseFloat(
                      val[`harga_satuan_realisasi_sparepart_${buaso}`] ?? 0,
                    ).toString(),
                  ).getWithComa
                }
              </Td>

              <Td className="text-right">
                {
                  RupiahConvert(
                    String(
                      getTotal({
                        qty: val[`qty_realisasi_sparepart_${buaso}`],
                        harga: val[`harga_satuan_realisasi_sparepart_${buaso}`],
                      }),
                    ),
                  ).getWithComa
                }
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={6}>
              <DataStatus text="Tidak ada data" />
            </Td>
          </Tr>
        )}

        {!!data?.length && (
          <Tr className="font-weight-bold">
            <Td textRight colSpan={5}>
              Total
            </Td>

            <Td textRight>
              {RupiahConvert(String(totalSparepart)).getWithComa}
            </Td>
          </Tr>
        )}
      </TBody>
    </CRUDLayout.Table>
  );
};
