import Services from "services";

class TransferProduksiOverheadApi {
  page(value) {
    return Services.get("/transfer_produksi_overhead/page", { params: {...value} });
  }

  single(value) {
    return Services.get("/transfer_produksi_overhead/single", { params: {...value} });
  }

  transfer(value) {
    return Services.get("/transfer_produksi_overhead/transfer", { params: {...value} });
  }

  no_baru(value) {
    return Services.get("/transfer_produksi_overhead/no_baru", { params: {...value} });
  }

  create(value) {
    return Services.post("/transfer_produksi_overhead", value);
  }

  delete(value) {
    return Services.post("/transfer_produksi_overhead/delete", value);
  }
}

export default new TransferProduksiOverheadApi();
