// React
import React, { useState, useEffect } from "react";

// Router
import { useHistory, useLocation } from "react-router-dom";

// Components
import { ButtonGroup } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ReadButton,
  UpdateButton,
  Table,
  InputSearch,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ApprovalStatusButton,
  Switch,
  DeleteModal,
  FilterButton,
  CreateButton,
} from "components";
import { PageNumber as TableNumber, DateConvert } from "utilities";

// API
import { AnalisaBarangJadiApi } from "api";

// View Components
import { ModalFilter } from "./components";
import { getStatusApprovalLabel } from "./functions";

const RegistrasiAnalisaBarangJadi = ({ setNavbarTitle }) => {
  // Hooks
  const history = useHistory();
  const location = useLocation();

  // Variables
  const filter = location?.state?.pagination;
  const title = "Analisa Barang Jadi";

  // States
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.analisa?.filter?.active,
      jenis_barang: location?.state?.analisa?.filter?.jenis_barang,
      status_approval: location?.state?.analisa?.filter?.status_approval,
    },
    pagination: {
      page: location?.state?.analisa?.filter?.page ?? "1",
      dataLength: location?.state?.analisa?.filter?.dataLength ?? "10",
      totalPage: location?.state?.analisa?.filter?.totalPage ?? "1",
      dataCount: location?.state?.analisa?.filter?.dataCount ?? "0",
    },
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [deleteData, setDeleteData] = useState({
    show: false,
    data: {
      id_item_buaso: "",
    },
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  const getInitialData = () => {
    setIsPageLoading(true);

    AnalisaBarangJadiApi.page({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      jenis_barang: dataFilter?.filter?.jenis_barang,
      status_approval: dataFilter?.filter?.status_approval,
    })
      .then((res) => {
        setData(res?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: res?.data?.data_count,
            totalPage: res?.data?.total_page,
          },
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };
  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };
  const dismissAlert = () => {
    let state = { ...history.location.state };
    delete state.alert;
    history.replace({ ...history.location, state });

    setAlertConfig({
      ...alertConfig,
      show: false,
    });
  };

  useEffect(() => {
    setNavbarTitle(title);
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, [setNavbarTitle]);

  useEffect(() => {
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, [
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.jenis_barang,
    dataFilter?.filter?.status_approval,
  ]);

  const HapusModal = () => {
    const [btnLoading, setBtnLoading] = useState(false);

    const deleteDataHandler = () => {
      setBtnLoading(true);

      AnalisaBarangJadiApi.delete({
        id_item_buaso: deleteData.data.id_item_buaso,
      })
        .then(() =>
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Hapus data berhasil!",
          })
        )
        .catch(() =>
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Hapus data gagal!",
          })
        )
        .finally(() => {
          setDeleteData({ ...deleteData, show: false });
          getInitialData();
        });
    };

    return (
      <DeleteModal
        show={deleteData.show}
        onHide={() => setDeleteData({ ...deleteData, show: false })}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title="Hapus Barang Jadi"
      >
        <div>Kode Item : {deleteData.data.kode_item}</div>
        <div>Nama Barang Jadi : {deleteData.data.nama_item}</div>
      </DeleteModal>
    );
  };
  const changeDataStatus = (status, id_analisa_barang_jadi) => {
    setIsPageLoading(true);
    const value = { id_analisa_barang_jadi };

    const onLoadedSuccess = () => {
      setAlertConfig({
        show: true,
        variant: "primary",
        text: "Ubah status data berhasil",
      });
    };

    const onLoadedFailed = () => {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Ubah status data gagal",
      });
    };

    status === true
      ? AnalisaBarangJadiApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getInitialData())
      : AnalisaBarangJadiApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getInitialData());
  };
  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        });
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            page: "1",
            dataLength: "10",
            totalPage: "1",
            dataCount: "0",
          },
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Kode Barang Jadi</ThFixed>
            <Th style={{ minWidth: "250px" }}>Nama Barang Jadi</Th>
            <Th style={{ width: "150px" }}>Varian</Th>
            <Th style={{ width: "150px" }}>Kelompok Barang</Th>
            <Th style={{ width: "150px" }}>Jenis Barang</Th>
            <ThFixed>Status Approval</ThFixed>
            <ThFixed>Update Terakhir</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(
                  dataFilter?.pagination?.page,
                  dataFilter?.pagination?.dataLength,
                  index
                )}
              </TdFixed>
              <Td className="align-middle">
                <ButtonGroup>
                  {getStatusApprovalLabel(val.status_approval).level >= 1 && (
                    <ReadButton
                      size="sm"
                      onClick={() =>
                        history.push(
                          `/master/analisa-barang-jadi/detail/${val.id_analisa_barang_jadi}`,
                          { ...location?.state, analisa: dataFilter }
                        )
                      }
                    />
                  )}
                  {getStatusApprovalLabel(val.status_approval).level >= 2 && (
                    <UpdateButton
                      size="sm"
                      onClick={() =>
                        history.push(
                          `/master/analisa-barang-jadi/ubah/${val.id_analisa_barang_jadi}`,
                          { ...location?.state, analisa: dataFilter }
                        )
                      }
                    />
                  )}
                  {/* {getStatusApprovalLabel(val.status_approval).level >= 3 && (
                    <DeleteButton
                      size="sm"
                      onClick={() => setDeleteData({ show: true, data: val })}
                    />
                  )} */}

                  {getStatusApprovalLabel(val.status_approval).level >= 4 && (
                    <Switch
                      id={toString(index + 1)}
                      checked={val.is_hidden === false ? true : false}
                      onChange={() =>
                        changeDataStatus(
                          val.is_hidden,
                          val.id_analisa_barang_jadi
                        )
                      }
                    />
                  )}
                </ButtonGroup>
              </Td>
              <TdFixed>{val.kode_item ?? "-"}</TdFixed>
              <Td>{val.nama_item ?? "-"}</Td>
              <Td>{val.nama_varian ?? "-"}</Td>
              <Td>{val.nama_kelompok ?? "-"}</Td>
              <Td>{val.nama_jenis ?? "-"}</Td>
              <Td>
                <ApprovalStatusButton
                  variant={getStatusApprovalLabel(val.status_approval).variant}
                >
                  {getStatusApprovalLabel(val.status_approval).label}
                </ApprovalStatusButton>
              </Td>
              <TdFixed>
                {val.tgl_analisa_barang_jadi
                  ? DateConvert(new Date(val.tgl_analisa_barang_jadi))
                      .defaultDMY
                  : "-"}
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </Table>
    );
    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }
    return (
      <>
        <b>{`List Data ${title}`}</b>
        <DataTable />
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength -
            dataFilter?.pagination?.dataLength +
            1
          }
          dataPage={
            dataFilter?.pagination?.dataCount <
            dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              },
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              // value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/master/analisa-barang-jadi/list-job-mix-design", {
                ...location?.state,
                analisa: dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <div style={{ minHeight: "500px" }}>
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => dismissAlert()}
        />

        {isPageLoading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : (
          <PageContent />
        )}

        {deleteData.show && <HapusModal />}
        {modalFilter && (
          <ModalFilter
            show={modalFilter}
            setShow={setModalFilter}
            data={dataFilter}
            setData={setDataFilter}
          />
        )}
      </div>
    </CRUDLayout>
  );
};

export default RegistrasiAnalisaBarangJadi;
