import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import {
  CRUDLayout,
  Th,
  Td,
  Table,
  Alert,
  DataStatus,
  ReadButton,
  InputSearch,
  Pagination,
  CreateButton,
  FilterButton,
} from "components";
import { DateConvert, RupiahConvert, TableNumber } from "utilities";
import { HPPApi } from "api";
import { ModalFilterHPP } from "./components";
import { saveAs } from "file-saver";

const ListHPP = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const SESSION_KEY = {
    FILTER: `${location.pathname}_filter`,
    PAGINATION: `${location.pathname}_pagination`,
  };
  const filter = sessionStorage.getItem(SESSION_KEY.FILTER)
    ? JSON.parse(sessionStorage.getItem(SESSION_KEY.FILTER))
    : {};
  const pagination = sessionStorage.getItem(SESSION_KEY.PAGINATION)
    ? JSON.parse(sessionStorage.getItem(SESSION_KEY.PAGINATION))
    : {};

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: filter?.active,
      tgl_hpp_mulai: filter?.tgl_hpp_mulai,
      tgl_hpp_selesai: filter?.tgl_hpp_selesai,
      tgl_produksi_mulai: filter?.tgl_produksi_mulai,
      tgl_produksi_selesai: filter?.tgl_produksi_selesai,
      tgl_realisasi_produksi_mulai: filter?.tgl_realisasi_produksi_mulai,
      tgl_realisasi_produksi_selesai: filter?.tgl_realisasi_produksi_selesai,
      tgl_sales_order_mulai: filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: filter?.tgl_sales_order_selesai,
      item_produksi: filter?.item_produksi,
    },
    pagination: {
      page: pagination?.page ?? "1",
      dataLength: pagination?.dataLength ?? "10",
      totalPage: pagination?.totalPage ?? "1",
      dataCount: pagination?.dataCount ?? "0",
    },
  });
  const [data, setData] = useState([]);
  const [dropdown, setDropdown] = useState({
    item_produksi: [],
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [searchTime, setSearchTime] = useState(null);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "detail",
    data: {},
  });
  const [loadingExport, setLoadingExport] = useState(false);

  const getInitialData = () => {
    setIsPageLoading(true);

    HPPApi.page({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      ...dataFilter?.filter,
    })
      .then(({ data }) => {
        setData(data.data);
        setDataFilter((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            dataCount: data.data_count,
            totalPage: data.total_page,
          },
        }));
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const dismissAlert = () => {
    const state = location.state;
    delete state.alert;

    history.replace(history.location, state);
    setAlertConfig({ ...alertConfig, show: false });
  };

  const onInputSearchChange = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: text.target.value,
        });
        setDataFilter((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            page: "1",
            dataLength: "10",
            dataCount: "0",
            totalPage: "1",
          },
        }));
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    );
  };

  // EXPORT DATA
  const exportData = (file) => {
    setLoadingExport(true);

    HPPApi.export({
      tipe: file,
      ...dataFilter?.filter,
    })
      .then((res) => {
        const link = res?.data?.data;
        const parse =
          "https://" +
          link
            .split("/")
            .filter((_val, idx) => idx !== 0)
            .filter((_val, idx) => idx !== 0)
            .join("/");
        saveAs(parse, "HPP");
      })
      .catch(() => {
        alert("Export Data Gagal!!");
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setNavbarTitle("Harga Pokok Produksi");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter,
  ]);

  // fetch dropdown filter
  useEffect(() => {
    HPPApi.dropdown({ tipe: "item_produksi" }).then((res) => {
      setDropdown({
        item_produksi: res?.data?.data?.map((item) => ({
          value: item.id_item_buaso,
          label: item.nama_item,
        })),
      });
    });

    return () => setDropdown({ item_produksi: [] });
  }, []);

  const PageContent = () => {
    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <div className="font-weight-bold mb-1 mt-2">
          List Data Harga Pokok Produksi
        </div>
        <Table>
          <thead>
            <tr>
              <Th style={{ width: 10 }}>No.</Th>
              <Th style={{ width: 50 }}>Aksi</Th>
              <Th style={{ width: 50 }}>Informasi Harga Pokok Produksi</Th>
              <Th style={{ width: 50 }}>Informasi Produksi</Th>
              <Th style={{ width: 50 }}>Informasi Realisasi Produksi</Th>
              <Th style={{ width: 50 }}>Informasi Sales Order</Th>
              <Th>Item Produksi</Th>
              <Th style={{ width: 50 }}>Total Qty. Telah Direalisasi</Th>
              <Th style={{ width: 50 }}>Jumlah</Th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">
                    {TableNumber(
                      dataFilter.pagination.page,
                      dataFilter.pagination.dataLength,
                      index
                    )}
                  </Td>
                  <Td className="text-center text-nowrap">
                    <ReadButton
                      size="sm"
                      onClick={() =>
                        history.push(`/transaksi/hpp/detail/${val.id_hpp}`)
                      }
                    />
                  </Td>
                  <Td>
                    <div>
                      {val.tgl_hpp
                        ? DateConvert(new Date(val.tgl_hpp)).defaultDMY
                        : "-"}
                    </div>
                    <div>{val.no_hpp ?? "-"}</div>
                  </Td>
                  <Td>
                    <div>
                      {val.tgl_jobmix
                        ? DateConvert(new Date(val.tgl_jobmix)).defaultDMY
                        : "-"}
                    </div>
                    <div>{val.no_jobmix ?? "-"}</div>
                  </Td>
                  <Td>
                    <div>
                      {val.tgl_realisasi_produksi
                        ? DateConvert(new Date(val.tgl_realisasi_produksi))
                            .defaultDMY
                        : "-"}
                    </div>
                    <div>{val.no_realisasi_produksi ?? "-"}</div>
                  </Td>
                  <Td>
                    <div>
                      {val.tgl_sales_order
                        ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY
                        : "-"}
                    </div>
                    <div>{val.no_sales_order ?? "-"}</div>
                  </Td>
                  <Td>{val.item_produksi ?? "-"}</Td>
                  <Td>
                    {val.qty_realisasi_produksi
                      ? `${parseFloat(val.qty_realisasi_produksi)} ${
                          val.kode_satuan ?? ""
                        } `
                      : "-"}
                  </Td>
                  <Td className="text-right">
                    {val.jumlah
                      ? RupiahConvert(parseFloat(val.jumlah).toString())
                          .getWithComa
                      : 0}
                  </Td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter((prev) => ({
                ...prev,
                pagination: { ...prev.pagination, page: selected + 1 },
              }))
            }
            onDataLengthChange={(e) =>
              setDataFilter((prev) => ({
                ...prev,
                pagination: { page: 1, dataLength: e.target.value },
              }))
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={6}>
              <InputSearch onChange={onInputSearchChange} />
            </Col>
            <Col className="pl-0" md={6}>
              <div className="d-flex">
                <FilterButton
                  active={dataFilter?.filter?.active}
                  onClick={() => setModalConfig({ show: true, type: "filter" })}
                />
                <DropdownButton
                  className="ml-3"
                  variant="warning text-white"
                  title={<span className="text-white mr-3">EXPORT</span>}
                >
                  <Dropdown.Item onClick={() => exportData("excel")}>
                    Excel (.xlsx)
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => {
              //  STORE STATE TO SESSION
              sessionStorage.setItem(
                SESSION_KEY.FILTER,
                JSON.stringify(dataFilter?.filter)
              );
              sessionStorage.setItem(
                SESSION_KEY.PAGINATION,
                JSON.stringify(dataFilter?.pagination)
              );

              history.push("/transaksi/hpp/list-produksi");
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => dismissAlert()}
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {/* Modal Filter */}
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilterHPP
          SESSION_KEY={SESSION_KEY}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
          dropdown={dropdown}
        />
      )}
    </CRUDLayout>
  );
};

export default ListHPP;
