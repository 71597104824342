import React from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { TableKomponenProduksi } from "./TableKomponenProduksi";

export const TabKomponenProduksiAset = ({ action, section, setCurrentTab }) => {
  return (
    <div>
      <Card className="mt-3">
        <Tab.Container defaultActiveKey="bahan">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link
                  eventKey="bahan"
                  onClick={() => setCurrentTab("bahan")}
                >
                  Bahan
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="upah" onClick={() => setCurrentTab("upah")}>
                  Upah
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="alatMesin"
                  onClick={() => setCurrentTab("alatMesin")}
                >
                  Alat Mesin
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="subkon"
                  onClick={() => setCurrentTab("subkon")}
                >
                  Subkon
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="overhead"
                  onClick={() => setCurrentTab("overhead")}
                >
                  Overhead
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Tab.Content>
            <Tab.Pane eventKey="bahan">
              <div className="m-2">
                <TableKomponenProduksi
                  tab="bahan"
                  action={action}
                  section={section}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="upah">
              <div className="m-2">
                <TableKomponenProduksi
                  tab="upah"
                  action={action}
                  section={section}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="alatMesin">
              <div className="m-2">
                <TableKomponenProduksi
                  tab="alatMesin"
                  action={action}
                  section={section}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="subkon">
              <div className="m-2">
                <TableKomponenProduksi
                  tab="subkon"
                  action={action}
                  section={section}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="overhead">
              <div className="m-2">
                <TableKomponenProduksi
                  tab="overhead"
                  action={action}
                  section={section}
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>
    </div>
  );
};
